import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import { IProfile } from "../../../domain/models/profiles";
import { IProfileMappingResponse } from "../../../domain/responses/profiles/ProfileMappingResponse";
import request, { setFilter } from "../../../util/request";

export const PROFILES_DETAIL_REQUEST = "@@profiles/DETAIL_REQUEST";
export const PROFILES_DETAIL_SUCCESS = "@@profiles/DETAIL_SUCCESS";
export const PROFILES_DETAIL_FAILURE = "@@profiles/DETAIL_FAILURE";

export interface IProfileDetailsRequest extends Action {
  type: typeof PROFILES_DETAIL_REQUEST;
}
export interface IProfileDetailsSuccess extends Action {
  type: typeof PROFILES_DETAIL_SUCCESS;
  payload: {
    profile: IProfile;
  };
}

export interface IProfileDetailsFailure extends Action {
  type: typeof PROFILES_DETAIL_FAILURE;
}

export type ProfileDetailsActions =
  | IProfileDetailsRequest
  | IProfileDetailsSuccess
  | IProfileDetailsFailure;

export const fetchProfileDetails: ActionCreator<
  ThunkAction<void, IRootReducer, void, ProfileDetailsActions>
> = (profileId: string) => async (dispatch, getState) => {
  dispatch(requestProfileDetails());
  try {
    const response = await request(dispatch, getState).get(
      `/GlansjeUsers/me/profileMappings${setFilter({
        limit: 1,
        include: ["profileUser"],
        where: { profileUserId: profileId }
      })}`
    );
    if (response.body.length === 1) {
      const pu = response.body[0] as IProfileMappingResponse;
      let score = 0;
      pu.profileUser.Profile.Levels.forEach(l => {
        score += l.Stars;
      });
      const profileUser: IProfile = {
        id: pu.profileUserId,
        reference: pu.reference,
        resetProfile: pu.profileUser.resetProfile,
        created: new Date(pu.profileUser.created),
        modified: new Date(pu.profileUser.modified),
        shortId: pu.profileUser.shortId,
        username: pu.profileUser.username,
        score
      };
      dispatch(successProfileDetails(profileUser));
    } else {
      dispatch(failureProfileDetails());
    }
  } catch (err) {
    dispatch(failureProfileDetails());
  }
};

const requestProfileDetails: ActionCreator<IProfileDetailsRequest> = (
  id: string
) => ({
  type: PROFILES_DETAIL_REQUEST
});

const successProfileDetails: ActionCreator<IProfileDetailsSuccess> = (
  profile: IProfile
) => ({
  type: PROFILES_DETAIL_SUCCESS,
  payload: {
    profile
  }
});

const failureProfileDetails: ActionCreator<IProfileDetailsFailure> = () => ({
  type: PROFILES_DETAIL_FAILURE
});
