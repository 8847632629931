import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";

import CreateUser from "./CreateUser";
import TotalProfiles from "./TotalProfiles";
import UserWidget from "./UserWidget";

const style = createStyles({
  gridColumn: {
    justifyContent: "stretch"
  }
});

const AdminWidgets = ({ ...props }: WithStyles<typeof style>) => (
  <>
    {/* <GridItem xs={12} sm={6} md={4} lg={3} className={props.classes.gridColumn}> */}
    <TotalProfiles />
    <UserWidget />
    {/* </GridItem> */}
    <CreateUser />
  </>
);
export default withStyles(style)(AdminWidgets);
