import * as React from "react";

import { withStyles, WithStyles } from "@material-ui/core";
import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import GridContainer from "../../components/Grid/GridContainer";
import AdminWidgets from "../../components/Widget/AdminWidgets";
import MyTotalProfiles from "../../components/Widget/MyTotalProfiles";
import NewProfile from "../../components/Widget/NewProfile";

class Dashboard extends React.Component<WithStyles<typeof dashboardStyle>> {
  public render() {
    return (
      <>
        <GridContainer>
          <AdminWidgets />
          <MyTotalProfiles />
          <NewProfile />
        </GridContainer>
      </>
    );
  }
}

export default withStyles(dashboardStyle)(Dashboard);
