import { CallHistoryMethodAction, push } from "connected-react-router";
import * as React from "react";
// Icons
// import ProfileIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Widget from ".";
import { IRole } from "../../domain/models/user";
import { IRootReducer } from "../../store";
import {
  CountTotalProfilesActions,
  fetchTotalProfilesCount
} from "../../store/profiles/actions/totalProfilesCount";
import Button from "../CustomButtons/Button";
import WidgetCounter from "../Typography/WidgetCounter";

interface IState {
  isFetching: boolean;
  hasError: boolean;
  count: number;
  roles: IRole[];
}

interface IDispatch {
  fetchTotal: () => void;
  navigate: () => void;
}

class TotalProfiles extends React.Component<IState & IDispatch> {
  public componentWillMount() {
    this.props.fetchTotal();
  }
  public render() {
    return (
      <Widget
        allowedRoles={["admin"]}
        title="Totaal profielen"
        footer={
          <Button onClick={this.props.navigate} color="orange" simple={true}>
            Alle profielen &gt;
          </Button>
        }
        headerColor="orange"
        xs={12}
        sm={6}
        md={4}
        lg={3}
      >
        <WidgetCounter color="orange" count={this.props.count} />
      </Widget>
    );
  }
}

const mapStateToProps = (state: IRootReducer): IState => ({
  isFetching: state.profiles.totalIsFetching,
  hasError: state.profiles.totalHasError,
  count: state.profiles.totalProfilesCount,
  roles: state.user.roles
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    IRootReducer,
    void,
    CountTotalProfilesActions | CallHistoryMethodAction<[string, any?]>
  >
): IDispatch => ({
  fetchTotal: () => dispatch(fetchTotalProfilesCount()),
  navigate: () => dispatch(push("/admin/profiles"))
});

export default connect<IState, IDispatch, {}, IRootReducer>(
  mapStateToProps,
  mapDispatchToProps
)(TotalProfiles);
