import { INotification } from "domain/models/notifications";
import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import request from "../../../util/request";

/// Constants
export const NOTIFICATIONS_COUNT_REQUEST = "@@notifications/COUNT_REQUEST";
export const NOTIFICATIONS_COUNT_SUCCESS = "@@notifications/COUNT_SUCCESS";
export const NOTIFICATIONS_COUNT_FAILURE = "@@notifications/COUNT_FAILURE";

/// Action Declarations
export interface INotificationCountRequestAction extends Action {
  type: typeof NOTIFICATIONS_COUNT_REQUEST;
}

export interface INotificationCountSuccessAction extends Action {
  type: typeof NOTIFICATIONS_COUNT_SUCCESS;
  payload: {
    count: number;
  };
}

export interface INotificationCountFailureAction extends Action {
  type: typeof NOTIFICATIONS_COUNT_FAILURE;
}

export type NotificationCountActions =
  | INotificationCountRequestAction
  | INotificationCountSuccessAction
  | INotificationCountFailureAction;

/// Action Definitions
export const countNotifications: ActionCreator<
  ThunkAction<void, IRootReducer, void, NotificationCountActions>
> = () => async (dispatch, getState) => {
  dispatch(countNotificationsRequest());
  try {
    const response = await request(dispatch, getState).get(
      "/GlansjeUsers/me/notifications/count"
    );

    dispatch(countNotificationsSuccess(response.body.count));
  } catch (error) {
    dispatch(countNotificationsFailure());
  }
};

export const countNotificationsRequest: ActionCreator<
  INotificationCountRequestAction
> = () => ({
  type: NOTIFICATIONS_COUNT_REQUEST
});

export const countNotificationsSuccess: ActionCreator<
  INotificationCountSuccessAction
> = (count: number) => ({
  type: NOTIFICATIONS_COUNT_SUCCESS,
  payload: {
    count
  }
});

export const countNotificationsFailure: ActionCreator<
  INotificationCountFailureAction
> = () => ({
  type: NOTIFICATIONS_COUNT_FAILURE
});
