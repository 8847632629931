import React, { Component, ReactNode } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import Button from "../CustomButtons/Button";

interface IProps {
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  title: string;
  content: ReactNode;
}
export default class PromptDialog extends Component<IProps> {
  private handleSubmit = () => {
    this.props.onSubmit();
  };

  private handleClose = () => {
    this.props.onClose();
  };

  public render() {
    const { open, title, content } = this.props;
    return (
      <Dialog
        onClose={this.handleClose}
        open={open}
        aria-labelledby="unlink-user-prompt"
        aria-describedby="unlink-user-prompt-content"
      >
        <DialogTitle id="unlink-user-prompt">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="unlink-profile-prompt-content">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="danger">
            Nee
          </Button>
          <Button onClick={this.handleSubmit} color="success">
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
