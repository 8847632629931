import * as React from "react";

import {
  FormHelperText,
  // MenuItem,
  WithStyles,
  withStyles
} from "@material-ui/core";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import { FormLabelProps } from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select, { SelectProps } from "@material-ui/core/Select";
import classNames from "classnames";

// import { SelectInputProps } from "@material-ui/core/Select/SelectInput";
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/components/customSelectStyle";

export interface IMenuItem {
  value: string | number;
  content: string;
}

interface IProps extends WithStyles<typeof customSelectStyle> {
  labelText?: string;
  labelProps?: FormLabelProps;
  id: string;
  selectProps: SelectProps;
  formControlProps?: FormControlProps;
  selectRootCustomClasses?: string;
  error?: boolean;
  success?: boolean;
  helpText?: React.ReactNode;
  options: IMenuItem[];
}

class CustomSelect extends React.Component<IProps> {
  public render() {
    const {
      classes,
      formControlProps,
      labelText,
      labelProps,
      id,
      selectProps,
      error,
      success,
      helpText,
      options
    } = this.props;

    const labelClasses = classNames({
      [classes.selectLabel]: true,
      [classes.selectLabelError]: !!error,
      [classes.selectLabelSuccess]: !!success && !error
    });

    const helpTextClasses = classNames({
      [classes.selectLabelError]: !!error,
      [classes.selectLabelSuccess]: !!success && !error
    });

    return (
      <FormControl {...formControlProps} className={classes.selectFormControl}>
        {labelText && (
          <InputLabel className={labelClasses} htmlFor={id} {...labelProps}>
            {labelText}
          </InputLabel>
        )}
        <Select
          classes={{
            select: classes.select,
            disabled: classes.disabled
          }}
          MenuProps={{
            className: classes.selectMenu
          }}
          id={id}
          {...selectProps}
          value={this.props.selectProps.value}
        >
          <option />
          {options.map((o, idx) => {
            return (
              <option key={idx} value={o.value}>
                {o.content}
              </option>
            );
          })}
        </Select>
        {helpText && (
          <FormHelperText id={`${id}-text`} className={helpTextClasses}>
            {helpText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default withStyles(customSelectStyle)(CustomSelect);
