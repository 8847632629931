import jsPDF from "jspdf-yworks";
import moment from "moment";
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as svg2pdf from "svg2pdf.js";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
  VictoryLegend,
  VictoryLine,
  VictoryTheme,
  VictoryTooltip
} from "victory";
import {
  blackColor,
  dangerColor,
  infoColor,
  orangeColor,
  primaryColor,
  roseColor,
  successColor
} from "../../assets/jss/material-dashboard-pro-react";
import { IProfile, IRiskScore } from "../../domain/models/profiles";
// import { IRootReducer } from "../../store";
// interface IState {
//   profileReference: string;
// }

interface IProps {
  labels: string[];
  riskScores: IRiskScore[];
  selectedProfile: IProfile;
}

interface ICiRecord {
  date: string;
  score: number;
  label: string;
}

export default class CariesIndexChart extends React.Component<IProps> {
  private chart = React.createRef<VictoryContainer>();

  public render() {
    const { riskScores, labels } = this.props;

    if (riskScores.length === 0) {
      return <p>Er zijn geen resultaten gevonden</p>;
    }

    const data = this.formatCiData();

    return (
      <VictoryChart
        containerComponent={<VictoryContainer ref={this.chart} />}
        domainPadding={60}
        padding={{
          top: 85,
          bottom: 40,
          left: 35,
          right: 25
        }}
        width={800}
        height={400}
        domain={{ y: [0, 4] }}
        theme={VictoryTheme.material}
      >
        <VictoryAxis tickValues={labels} />
        <VictoryAxis
          dependentAxis={true}
          domain={[0, 4]}
          tickFormat={this.intYAxis}
        />

        <VictoryGroup
          offset={20}
          colorScale={[
            primaryColor[0],
            orangeColor[0],
            successColor[0],
            roseColor[0],
            infoColor[0],
            dangerColor[0]
          ]}
          domain={{ y: [0, 4] }}
        >
          <VictoryBar
            data={data.ci1}
            x="date"
            y="score"
            labelComponent={<VictoryTooltip />}
          />
          <VictoryBar
            data={data.ci2}
            x="date"
            y="score"
            labelComponent={<VictoryTooltip />}
          />
          <VictoryBar
            data={data.ci3}
            x="date"
            y="score"
            labelComponent={<VictoryTooltip />}
          />
          <VictoryBar
            data={data.ci4}
            x="date"
            y="score"
            labelComponent={<VictoryTooltip />}
          />
          <VictoryBar
            data={data.ci5}
            x="date"
            y="score"
            labelComponent={<VictoryTooltip />}
          />
          <VictoryBar
            data={data.ci6}
            x="date"
            y="score"
            labelComponent={<VictoryTooltip />}
          />
        </VictoryGroup>
        <VictoryLine
          interpolation="natural"
          data={data.cri}
          x="date"
          y="score"
          domain={{ y: [0, 4] }}
          labelComponent={<VictoryTooltip />}
          style={{
            data: {
              stroke: blackColor,
              filter: "drop-shadow(3px 3px 2px rgba(0, 0, 0, .7))"
            }
          }}
        />
        <VictoryLegend
          title="Legenda"
          centerTitle={false}
          gutter={20}
          padding={{ left: 25, right: 25 }}
          style={{
            labels: { fontSize: 14 },
            // title: { fontSize: 16 }
          }}
          itemsPerRow={2}
          colorScale={[
            primaryColor[0],
            orangeColor[0],
            successColor[0],
            roseColor[0],
            infoColor[0],
            dangerColor[0],
            blackColor
          ]}
          data={[
            { name: "Boven / Links" },
            { name: "Boven / Voor" },
            { name: "Boven / Rechts" },
            { name: "Onder / Links" },
            { name: "Onder / Voor" },
            { name: "Onder / Rechts" },
            { name: "Cariësindex" }
          ]}
        />
      </VictoryChart>
    );
  }

  public renderPdf = () => {
    if (this.chart.current) {
      const chartNode = ReactDOM.findDOMNode(this.chart.current);
      if (chartNode && chartNode.firstChild) {
        const pdf = new jsPDF("l", "pt", "a4");
        svg2pdf(chartNode.firstChild, pdf, {
          xOffset: 0,
          yOffset: 0,
          scale: 5
        });

        pdf.save(
          `Cariesindex_${moment().format("DD-MM-YYYY")}_${
          this.props.selectedProfile.reference
          }.pdf`
        );
      }
    }
  };

  private intYAxis = (x: number) => {
    return x.toFixed();
  };
  private formatCiData = () => {
    const { riskScores } = this.props;
    const getDescription = (score: number): string => {
      switch (score) {
        case 0:
          return "Cariësvrij of adequaat gerestaureerd";
        case 1:
          return "niet-actieve cariës of besloten niet te restaureren";
        case 2:
          return "actieve, niet gecaviteerde laesies";
        case 3:
          return "gecaviteerde laesies";
        case 4:
          return "actieve, niet gecaviteerde en gecaviteerde laesies";
        default:
          throw Error("Undefined score");
      }
    };
    const ci: {
      ci1: ICiRecord[];
      ci2: ICiRecord[];
      ci3: ICiRecord[];
      ci4: ICiRecord[];
      ci5: ICiRecord[];
      ci6: ICiRecord[];
      cri: ICiRecord[];
    } = {
      ci1: [],
      ci2: [],
      ci3: [],
      ci4: [],
      ci5: [],
      ci6: [],
      cri: []
    };

    riskScores.forEach((el, idx) => {
      const date = moment(el.created).format("DD-MM-YYYY");
      if (ci.ci1.length > 0 && ci.ci1[ci.ci1.length - 1].date === date) {
        ci.ci1.splice(ci.ci1.length - 1, 1);
        ci.ci2.splice(ci.ci2.length - 1, 1);
        ci.ci3.splice(ci.ci3.length - 1, 1);
        ci.ci4.splice(ci.ci4.length - 1, 1);
        ci.ci5.splice(ci.ci5.length - 1, 1);
        ci.ci6.splice(ci.ci6.length - 1, 1);
        ci.cri.splice(ci.cri.length - 1, 1);
      }
      ci.ci1.push({
        date,
        score: el.ci1,
        label: `Boven / Links: ${getDescription(el.ci1)}`
      });
      ci.ci2.push({
        date,
        score: el.ci2,
        label: `Boven / Voor: ${getDescription(el.ci2)}`
      });
      ci.ci3.push({
        date,
        score: el.ci3,
        label: `Boven / Rechts: ${getDescription(el.ci3)}`
      });
      ci.ci4.push({
        date,
        score: el.ci4,
        label: `Onder / Links: ${getDescription(el.ci4)}`
      });
      ci.ci5.push({
        date,
        score: el.ci5,
        label: `Onder / Voor: ${getDescription(el.ci5)}`
      });
      ci.ci6.push({
        date,
        score: el.ci6,
        label: `Onder / Rechts: ${getDescription(el.ci6)}`
      });
      ci.cri.push({
        date,
        score: parseInt(el.cri, 10),
        label: `Cariësindex: ${parseInt(el.cri, 10)}`
      });
    });

    return ci;
  };
}
