import { WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";

import headingStyle from "../../assets/jss/material-dashboard-pro-react/components/headingStyle";

interface IProps {
  textAlign?: "right" | "left" | "center";
  title?: React.ReactNode;
  category?: React.ReactNode;
}

function Heading({ ...props }: IProps & WithStyles<typeof headingStyle>) {
  const { textAlign, title, category, classes } = props;
  let headingClasses = `${classes.heading}`;
  switch (textAlign) {
    case "left":
      headingClasses += ` ${classes.leftTextAlign}`;
      break;
    case "right":
      headingClasses += ` ${classes.rightTextAlign}`;
      break;
    case "center":
      headingClasses += ` ${classes.centerTextAlign}`;
      break;
  }
  if (!title) {
    return null;
  }

  return (
    <div className={headingClasses}>
      <h3 className={classes.title}>{title}</h3>
      {category && <p className={classes.category}>{category}</p>}
    </div>
  );
}

export default withStyles(headingStyle)(Heading);
