import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import { INotification } from "../../../domain/models/notifications";
import request from "../../../util/request";

/// Constants
export const NOTIFICATIONS_FETCH_REQUEST = "@@notifications/FETCH_REQUEST";
export const NOTIFICATIONS_FETCH_SUCCESS = "@@notifications/FETCH_SUCCESS";
export const NOTIFICATIONS_FETCH_FAILURE = "@@notifications/FETCH_FAILURE";

/// Action Declarations
export interface INotificationFetchRequestAction extends Action {
  type: typeof NOTIFICATIONS_FETCH_REQUEST;
}

export interface INotificationFetchSuccessAction extends Action {
  type: typeof NOTIFICATIONS_FETCH_SUCCESS;
  payload: {
    notifications: INotification[];
  };
}

export interface INotificationFetchFailureAction extends Action {
  type: typeof NOTIFICATIONS_FETCH_FAILURE;
}

export type NotificationFetchActions =
  | INotificationFetchRequestAction
  | INotificationFetchSuccessAction
  | INotificationFetchFailureAction;

/// Action Definitions
export const fetchNotifications: ActionCreator<
  ThunkAction<void, IRootReducer, void, NotificationFetchActions>
> = () => async (dispatch, getState) => {
  dispatch(fetchNotificationsRequest());
  try {
    const response = await request(dispatch, getState).get(
      "/GlansjeUsers/me/notifications"
    );
    const notifications: INotification[] = response.body.map((n: INotification) => ({
      ...n,
      from: {
        ...n.from,
        created: new Date(n.from.created),
        modified: new Date(n.from.modified)
      }
    }));
    dispatch(fetchNotificationsSuccess(notifications));
  } catch (error) {
    dispatch(fetchNotificationsFailure());
  }
};

export const fetchNotificationsRequest: ActionCreator<
  INotificationFetchRequestAction
> = () => ({
  type: NOTIFICATIONS_FETCH_REQUEST
});

export const fetchNotificationsSuccess: ActionCreator<
  INotificationFetchSuccessAction
> = (notifications: INotification[]) => ({
  type: NOTIFICATIONS_FETCH_SUCCESS,
  payload: {
    notifications
  }
});

export const fetchNotificationsFailure: ActionCreator<
  INotificationFetchFailureAction
> = () => ({
  type: NOTIFICATIONS_FETCH_FAILURE
});
