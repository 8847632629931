import { ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import { IRiskScore } from "../../../domain/models/profiles";
import request from "../../../util/request";
import { Action } from "redux";

/// Constants
export const PROFILE_FETCH_RISKS_REQUEST = "@@profiles/FETCH_RISKS_REQUEST";
export const PROFILE_FETCH_RISKS_SUCCESS = "@@profiles/FETCH_RISKS_SUCCESS";
export const PROFILE_FETCH_RISKS_FAILURE = "@@profiles/FETCH_RISKS_FAILURE";

// Action interfaces
export interface IProfileRiskScoreFetchRequest extends Action {
  type: typeof PROFILE_FETCH_RISKS_REQUEST;
}

export interface IProfileRiskScoreFetchSuccess extends Action {
  type: typeof PROFILE_FETCH_RISKS_SUCCESS;
  payload: {
    riskScores: IRiskScore[];
  };
}

export interface IProfileRiskScoreFetchFailure extends Action {
  type: typeof PROFILE_FETCH_RISKS_FAILURE;
}

export type FetchRiskScoresActions =
  | IProfileRiskScoreFetchRequest
  | IProfileRiskScoreFetchSuccess
  | IProfileRiskScoreFetchFailure;

export type RiskScoresActions = FetchRiskScoresActions;

/// Actions
export const fetchRiskScores: ActionCreator<
  ThunkAction<void, IRootReducer, void, FetchRiskScoresActions>
> = (profileId: string) => async (dispatch, getState) => {
  dispatch(requestFetchRiskScores());
  try {
    const response = await request(dispatch, getState).get(
      `/ProfileUsers/${profileId}/riskIndices`
    );
    const scoreRes = response.body as IRiskScore[];

    dispatch(
      successFetchRiskScores(
        scoreRes.map(
          (score): IRiskScore => {
            return {
              ...score,
              created: new Date(score.created)
            };
          }
        )
      )
    );
  } catch (err) {
    dispatch(failureFetchRiskScores());
  }
};

const requestFetchRiskScores: ActionCreator<
  IProfileRiskScoreFetchRequest
> = () => ({
  type: PROFILE_FETCH_RISKS_REQUEST
});

const successFetchRiskScores: ActionCreator<IProfileRiskScoreFetchSuccess> = (
  riskScores: IRiskScore[]
) => ({
  type: PROFILE_FETCH_RISKS_SUCCESS,
  payload: {
    riskScores
  }
});

const failureFetchRiskScores: ActionCreator<
  IProfileRiskScoreFetchFailure
> = () => ({
  type: PROFILE_FETCH_RISKS_FAILURE
});
