import { WithStyles, withStyles } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";

import cardFooterStyle from "../../assets/jss/material-dashboard-pro-react/components/cardFooterStyle";
import { CardFooterType } from "./types";

interface IProps extends WithStyles<typeof cardFooterStyle> {
  children: React.ReactNode;
  className?: string;
  type?: CardFooterType;
}

function CardFooter({ ...props }: IProps) {
  const { classes, children, className, type, ...rest } = props;

  const cardFooterClasses = classNames({
    [classes.cardFooter]: true,
    [classes.cardFooterPlain]: type === CardFooterType.PLAIN,
    [classes.cardFooterProfile]:
      type === CardFooterType.PROFILE || CardFooterType.TESTIMONIAL,
    [classes.cardFooterPricing]: type === CardFooterType.PRICING,
    [classes.cardFooterTestimonial]: type === CardFooterType.TESTIMONIAL,
    [classes.cardFooterStats]: type === CardFooterType.STATS,
    [classes.cardFooterChart]:
      type === CardFooterType.CHART || CardFooterType.PRODUCT
  });

  return (
    <div className={`${cardFooterClasses} ${className}`} {...rest}>
      {children}
    </div>
  );
}

export default withStyles(cardFooterStyle)(CardFooter);
