import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import CardIcon from "../../components/Card/CardIcon";
import GridContainer from "../../components/Grid/GridContainer";
import Heading from "../../components/Heading/Heading";
import Table from "../../components/Table";
import Widget from "../../components/Widget";

import TrashIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import { push, RouterAction, RouterState } from "connected-react-router";
import { cardTitle } from "../../assets/jss/material-dashboard-pro-react";
import Button from "../../components/CustomButtons/Button";
import PromptDialog from "../../components/Dialogs/PromptDialog";
import { IRow } from "../../components/Table/Row";
import { IProfile } from "../../domain/models/profiles";
import { IRootReducer } from "../../store";
import {
  fetchProfiles,
  FetchProfilesActions
} from "../../store/profiles/actions/myProfiles";
import { fetchMyProfilesCount } from "../../store/profiles/actions/myProfilesCount";
import {
  fetchProfileDetails,
  ProfileDetailsActions
} from "../../store/profiles/actions/profileDetails";
import {
  ProfileUnlinkActions,
  unlinkProfile
} from "../../store/profiles/actions/unlinkProfile";

const style = createStyles({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    textAlign: "left"
  },
  customActionsHead: {
    width: "15%"
  }
});

interface IState {
  profiles: IProfile[];
  totalProfiles: number;
  isFetching: boolean;
  hasError: boolean;
}

interface IDispatch {
  fetchProfiles: (page: number) => void;
  fetchProfilesCount: () => void;
  navigate: (path: string) => void;
  selectProfile: (id: string) => void;
  deleteProfile: (id: string) => void;
}

interface IMyState {
  page: number;
  deleteProfileId: string | null;
  deleteReference: string | null;
  deleteOpen: boolean;
}

export class ClientsOverview extends React.Component<
  IState & IDispatch & WithStyles<typeof style> & RouterState,
  IMyState
  > {
  public state = {
    page: 0,
    deleteProfileId: null,
    deleteReference: null,
    deleteOpen: false
  };

  public componentWillMount() {
    this.props.fetchProfilesCount();
    this.props.fetchProfiles(this.state.page);
  }

  public render() {
    return (
      <>
        <Heading textAlign="center" title="Mijn cliënten" />
        <GridContainer>
          <Widget
            allowedRoles={["admin", "dentist"]}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            title={
              <>
                <CardIcon color="success">
                  <PersonIcon />
                </CardIcon>
                <h4 className={this.props.classes.cardIconTitle}>Overzicht</h4>
              </>
            }
            icon={true}
            headerColor="rose"
          >
            <Table
              striped={true}
              hover={false}
              tableHeaderColor="orange"
              tableHead={[
                { header: "Naam" },
                { header: "Code" },
                { header: "Referentie" },
                {
                  header: "Acties",
                  customClass: this.props.classes.customActionsHead
                }
              ]}
              tableData={this.convertProfilesToTableData()}
              paginate={true}
              page={this.state.page}
              totalRows={this.props.totalProfiles}
              totalCols={4}
              onChangePage={this.handleChangePage}
              isFetching={this.props.isFetching}
            />
          </Widget>
        </GridContainer>
        <PromptDialog
          open={this.state.deleteOpen}
          onClose={this.handleDeleteClose}
          onSubmit={this.handleDeleteProfile}
          title={`Profiel met referentie: ${this.state.deleteReference}`}
          content={
            <span>
              Weet u zeker dat u profiel: {this.state.deleteReference}, wil
              ontkoppelen?
            </span>
          }
        />
      </>
    );
  }

  private convertProfilesToTableData = (): IRow[] => {
    return this.props.profiles.map(
      (profile, key): IRow => {
        return {
          rowIndex: key,
          cellData: [
            { content: profile.username.substr(32) },
            { content: profile.shortId },
            { content: profile.reference },
            {
              content: (
                <>
                  <Button
                    color="primary"
                    justIcon={true}
                    onClick={this.editProfile(profile.id)}
                  >
                    <EditIcon />
                  </Button>
                  <Button
                    color="danger"
                    justIcon={true}
                    onClick={this.deleteProfile(profile.id, profile.reference)}
                  >
                    <TrashIcon />
                  </Button>
                </>
              )
            }
          ]
        };
      }
    );
  };

  private handleChangePage = (_: any, page: number) => {
    this.setState({ page });
    this.props.fetchProfiles(page);
  };

  private editProfile = (id: string) => (_: any) => {
    this.props.selectProfile(id);
    this.props.navigate(`/admin/client/edit/${id}`);
  };

  private handleDeleteProfile = () => {
    if (this.state.deleteProfileId !== null) {
      this.props.deleteProfile(this.state.deleteProfileId!);
    }
    this.handleDeleteClose();
  };

  private handleDeleteClose = () => {
    this.setState({
      deleteOpen: false,
      deleteProfileId: null,
      deleteReference: null
    });
  };

  private deleteProfile = (id: string, reference: string) => (_: any) => {
    this.setState({
      deleteOpen: true,
      deleteProfileId: id,
      deleteReference: reference
    });
  };
}

const mapStateToProps = (state: IRootReducer): IState => ({
  isFetching: state.profiles.isFetching,
  hasError: state.profiles.hasError,
  profiles: state.profiles.myProfiles,
  totalProfiles: state.profiles.myProfilesCount
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    IRootReducer,
    void,
    | FetchProfilesActions
    | RouterAction
    | ProfileDetailsActions
    | ProfileUnlinkActions
  >
): IDispatch => ({
  fetchProfiles: (page = 0) => dispatch(fetchProfiles(page)),
  fetchProfilesCount: () => dispatch(fetchMyProfilesCount()),
  navigate: (path: string) => dispatch(push(path)),
  selectProfile: (id: string) => dispatch(fetchProfileDetails(id)),
  deleteProfile: (id: string) => dispatch(unlinkProfile(id))
});

export default connect<IState, IDispatch, {}, IRootReducer>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ClientsOverview));
