import 'proxy-polyfill/src/proxy';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import jss from "jss";
import preset from "jss-preset-default";
import * as moment from "moment";
import "moment/locale/nl";
import * as React from "react";
import { hydrate, render } from "react-dom";
import { Application } from "./App";

jss.setup(preset());

moment.locale("nl");

const root = document.querySelector("#root");

if (root && root.hasChildNodes() === true) {
  hydrate(<Application />, root);
} else {
  render(<Application />, root);
}
