import { WithStyles, withStyles } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";

import cardBodyStyle from "../../assets/jss/material-dashboard-pro-react/components/cardBodyStyle";
import { CardBodyType } from "./types";

interface IProps extends WithStyles<typeof cardBodyStyle> {
  children: React.ReactNode;
  className?: string;
  background?: boolean;
  color?: boolean;
  formHorizontal?: boolean;
  type?: CardBodyType;
}

function CardBody({ ...props }: IProps) {
  const {
    children,
    classes,
    type,
    className,
    formHorizontal,
    color,
    background,
    ...rest
  } = props;

  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.cardBodyBackground]: !!background,
    [classes.cardBodyPlain]: type === CardBodyType.PLAIN,
    [classes.cardBodyFormHorizontal]: !!formHorizontal,
    [classes.cardPricing]: type === CardBodyType.PRICING,
    [classes.cardSignup]: type === CardBodyType.SIGNUP,
    [classes.cardBodyColor]: !!color,
    [classes.cardBodyProfile]: type === CardBodyType.PROFILE,
    [classes.cardBodyCalendar]: type === CardBodyType.CALENDAR
  });

  return (
    <div className={`${cardBodyClasses} ${className}`} {...rest}>
      {children}
    </div>
  );
}

export default withStyles(cardBodyStyle)(CardBody);
