import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import { IRole } from "../../../domain/models/user/Role";
import request from "../../../util/request";

/// Constants
export const USER_FETCH_ROLES_REQUEST = "@@user/FETCH_ROLES_REQUEST";
export const USER_FETCH_ROLES_SUCCESS = "@@user/FETCH_ROLES_SUCCESS";
export const USER_FETCH_ROLES_FAILURE = "@@user/FETCH_ROLES_FAILURE";

/// Action Interfaces
export interface IUserFetchRolesRequest extends Action {
  type: typeof USER_FETCH_ROLES_REQUEST;
}

export interface IUserFetchRolesSuccess extends Action {
  type: typeof USER_FETCH_ROLES_SUCCESS;
  payload: IRole[];
}

export interface IUserFetchRolesFailure extends Action {
  type: typeof USER_FETCH_ROLES_FAILURE;
}

export type UserRolesActions =
  | IUserFetchRolesRequest
  | IUserFetchRolesSuccess
  | IUserFetchRolesFailure;

/// Actions
export const fetchRoles: ActionCreator<
  ThunkAction<void, IRootReducer, void, UserRolesActions>
> = () => async (dispatch, getState) => {
  dispatch(fetchRolesRequest());
  try {
    const res = await request(dispatch, getState).get("/GlansjeUsers/me/roles");
    const roles: IRole[] = res.body.map((r: IRole) => ({
      ...r,
      created: new Date(r.created),
      modified: new Date(r.modified)
    }));
    dispatch(fetchRolesSuccess(roles));
  } catch (error) {
    dispatch(fetchRolesFailure());
  }
};

export const fetchRolesRequest: ActionCreator<IUserFetchRolesRequest> = () => ({
  type: USER_FETCH_ROLES_REQUEST
});

export const fetchRolesSuccess: ActionCreator<IUserFetchRolesSuccess> = (
  roles: IRole[]
) => ({
  type: USER_FETCH_ROLES_SUCCESS,
  payload: roles
});

export const fetchRolesFailure: ActionCreator<IUserFetchRolesFailure> = () => ({
  type: USER_FETCH_ROLES_FAILURE
});
