import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import request from "../../../util/request";

export const PROFILE_APP_RESET_REQUEST = "@@profiles/APP_RESET_REQUEST";
export const PROFILE_APP_RESET_SUCCESS = "@@profiles/APP_RESET_SUCCESS";
export const PROFILE_APP_RESET_FAILURE = "@@profiles/APP_RESET_FAILURE";

export interface IProfileAppResetRequest extends Action {
  type: typeof PROFILE_APP_RESET_REQUEST;
}

export interface IProfileAppResetSuccess extends Action {
  type: typeof PROFILE_APP_RESET_SUCCESS;
}

export interface IProfileAppResetFailure extends Action {
  type: typeof PROFILE_APP_RESET_FAILURE;
}

export type AppResetActions =
  | IProfileAppResetRequest
  | IProfileAppResetSuccess
  | IProfileAppResetFailure;

export const appReset: ActionCreator<
  ThunkAction<void, IRootReducer, void, AppResetActions>
> = (profileId: string) => async (dispatch, getState) => {
  dispatch(requestAppReset());
  try {
    await request(dispatch, getState)
      .patch(`/ProfileUsers/${profileId}`)
      .send({ resetProfile: true });
    dispatch(successAppReset());
  } catch (err) {
    dispatch(failureAppReset());
  }
};

const requestAppReset: ActionCreator<IProfileAppResetRequest> = () => ({
  type: PROFILE_APP_RESET_REQUEST
});

const successAppReset: ActionCreator<IProfileAppResetSuccess> = () => ({
  type: PROFILE_APP_RESET_SUCCESS
});

const failureAppReset: ActionCreator<IProfileAppResetFailure> = () => ({
  type: PROFILE_APP_RESET_FAILURE
});
