import { WithStyles, withStyles } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import appStyle from "../assets/jss/material-dashboard-pro-react/layouts/adminStyle";
import Footer from "../components/Footer/Footer";
import Sidebar from "../components/Sidebar/Sidebar";
import routes from "../routes";
import { mobileOpen } from "../store/ui/actions";
import { IUIState } from "../store/ui/types";
import { getRoutes } from "../util/route_helpers";

import { Switch } from "react-router";
import logo from "../assets/logo.png";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import { IRootReducer } from "../store";

interface IProps extends WithStyles<typeof appStyle> {}
interface IState {
  ui: IUIState;
}

interface IDispatch {
  mobileOpen: (open: boolean) => void;
}

// let ps: PerfectScrollbar | undefined;

class Admin extends React.Component<IProps & IState & IDispatch> {
  // private mainPanel: HTMLElement | null;

  public componentDidMount() {
    window.addEventListener("resize", this.resizeHandler);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.resizeHandler);
  }

  public render() {
    const { classes, ui } = this.props;
    const mainPanelClasses = classNames({
      [classes.mainPanel]: true,
      [classes.mainPanelSidebarMini]: ui.miniActive,
      [classes.mainPanelWithPerfectScrollbar]: true
    });

    return (
      <div className={classes.wrapper}>
        <Sidebar
          logoText="Dashboard"
          logo={logo}
          image={ui.image}
          bgColor={ui.bgColor}
          color="orange"
        />
        <PerfectScrollbar className={mainPanelClasses}>
          {/* <div className={}> */}
          <AdminNavbar />
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>{getRoutes("/admin", routes.adminRoutes)}</Switch>
            </div>
          </div>
          <Footer fluid={true} />
          {/* </div> */}
        </PerfectScrollbar>
      </div>
    );
  }

  // private setMainPanelRef = (element: HTMLElement | null) => {
  //   this.mainPanel = element;
  // };

  private resizeHandler = () => {
    if (window.innerWidth >= 960) {
      this.props.mobileOpen(false);
    }
  };
}

const mapStateToProps = (state: IRootReducer): IState => ({
  ui: state.ui
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatch => ({
  mobileOpen: (open: boolean) => dispatch(mobileOpen(open))
});

export default connect<IState, IDispatch, IProps, IRootReducer>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(appStyle)(Admin));
