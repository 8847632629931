import { ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import request from "../../../util/request";

/// Constants
export const PROFILES_FETCH_TOTAL_COUNT_REQUEST =
  "@@profiles/FETCH_TOTAL_COUNT_REQUEST";
export const PROFILES_FETCH_TOTAL_COUNT_SUCCESS =
  "@@profiles/FETCH_TOTAL_COUNT_SUCCESS";
export const PROFILES_FETCH_TOTAL_COUNT_FAILURE =
  "@@profiles/FETCH_TOTAL_COUNT_FAILURE";

/// Action definitions
export interface ITotalProfilesCountRequest {
  type: typeof PROFILES_FETCH_TOTAL_COUNT_REQUEST;
}

export interface ITotalProfilesCountSuccess {
  type: typeof PROFILES_FETCH_TOTAL_COUNT_SUCCESS;
  payload: {
    count: number;
  };
}

export interface ITotalProfilesCountFailure {
  type: typeof PROFILES_FETCH_TOTAL_COUNT_FAILURE;
}

export type CountTotalProfilesActions =
  | ITotalProfilesCountRequest
  | ITotalProfilesCountSuccess
  | ITotalProfilesCountFailure;

/// Actions
export const fetchTotalProfilesCount: ActionCreator<
  ThunkAction<void, IRootReducer, void, CountTotalProfilesActions>
> = () => async (dispatch, getState) => {
  dispatch(requestTotalProfilesCount());
  try {
    const res = await request(dispatch, getState).get("/ProfileUsers/count");
    dispatch(successTotalProfilesCount(res.body.count));
  } catch (error) {
    // alert(error);
    dispatch(failureTotalProfilesCount());
  }
};

const requestTotalProfilesCount: ActionCreator<
  ITotalProfilesCountRequest
> = () => ({
  type: PROFILES_FETCH_TOTAL_COUNT_REQUEST
});

const successTotalProfilesCount: ActionCreator<ITotalProfilesCountSuccess> = (
  count: number
) => ({
  type: PROFILES_FETCH_TOTAL_COUNT_SUCCESS,
  payload: {
    count
  }
});

const failureTotalProfilesCount: ActionCreator<
  ITotalProfilesCountFailure
> = () => ({
  type: PROFILES_FETCH_TOTAL_COUNT_FAILURE
});
