import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import { IRiskScore } from "../../../domain/models/profiles";
import request from "../../../util/request";
import { fetchRiskScores } from "./riskScores";

export const RISK_SCORE_ADD_REQUEST = "@@profile/RISK_SCORE_ADD_REQUEST";
export const RISK_SCORE_ADD_SUCCESS = "@@profile/RISK_SCORE_ADD_SUCCESS";
export const RISK_SCORE_ADD_FAILURE = "@@profile/RISK_SCORE_ADD_FAILURE";

export interface IProfileAddRiskScoreRequest extends Action {
  type: typeof RISK_SCORE_ADD_REQUEST;
}

export interface IProfileAddRiskScoreSuccess extends Action {
  type: typeof RISK_SCORE_ADD_SUCCESS;
  payload: {
    riskScore: IRiskScore;
  };
}

export interface IProfileAddRiskScoreFailure extends Action {
  type: typeof RISK_SCORE_ADD_FAILURE;
}

export type RiskScoreAddActions =
  | IProfileAddRiskScoreFailure
  | IProfileAddRiskScoreRequest
  | IProfileAddRiskScoreSuccess;

export const addRiskScore: ActionCreator<
  ThunkAction<void, IRootReducer, void, RiskScoreAddActions>
> = (profileId: string, riskScore: IRiskScore) => async (
  dispatch,
  getState
) => {
  dispatch(requestAddRiskScore());
  try {
    const res = await request(dispatch, getState)
      .post(`/ProfileUsers/${profileId}/riskIndices`)
      .send({
        ri1: riskScore.ri1,
        ri2: riskScore.ri2,
        ri3: riskScore.ri3,
        ri4: riskScore.ri4,
        ci1: riskScore.ci1,
        ci2: riskScore.ci2,
        ci3: riskScore.ci3,
        ci4: riskScore.ci4,
        ci5: riskScore.ci5,
        ci6: riskScore.ci6,
        cri: Math.round(
          (riskScore.ci1 +
            riskScore.ci2 +
            riskScore.ci3 +
            riskScore.ci4 +
            riskScore.ci5 +
            riskScore.ci6) /
            6
        ),
        message: riskScore.message
      });
    const result: IRiskScore = res.body;
    result.created = new Date(res.body.created);
    dispatch(successAddRiskScore(result));
    dispatch(fetchRiskScores(profileId));
  } catch (err) {
    dispatch(failureAddRiskScore());
  }
};

const requestAddRiskScore: ActionCreator<IProfileAddRiskScoreRequest> = () => ({
  type: RISK_SCORE_ADD_REQUEST
});

const successAddRiskScore: ActionCreator<IProfileAddRiskScoreSuccess> = (
  riskScore: IRiskScore
) => ({
  type: RISK_SCORE_ADD_SUCCESS,
  payload: { riskScore }
});

const failureAddRiskScore: ActionCreator<IProfileAddRiskScoreFailure> = () => ({
  type: RISK_SCORE_ADD_FAILURE
});
