import { ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import request from "../../../util/request";

/// Constants
export const PROFILES_COUNT_REQUEST = "@@profiles/COUNT_REQUEST";
export const PROFILES_COUNT_SUCCESS = "@@profiles/COUNT_SUCCESS";
export const PROFILES_COUNT_FAILURE = "@@profiles/COUNT_FAILURE";

/// Action interfaces
export interface IMyProfilesCountRequest {
  type: typeof PROFILES_COUNT_REQUEST;
}

export interface IMyProfilesCountSuccess {
  type: typeof PROFILES_COUNT_SUCCESS;
  payload: {
    count: number;
  };
}

export interface IMyProfilesCountFailure {
  type: typeof PROFILES_COUNT_FAILURE;
}

export type MyProfilesCountActions =
  | IMyProfilesCountRequest
  | IMyProfilesCountSuccess
  | IMyProfilesCountFailure;

/// Actions
export const fetchMyProfilesCount: ActionCreator<
  ThunkAction<void, IRootReducer, void, MyProfilesCountActions>
> = () => async (dispatch, getState) => {
  dispatch(requestMyProfilesCount());
  try {
    const res = await request(dispatch, getState).get(
      "/GlansjeUsers/me/profileMappings/count"
    );
    dispatch(successMyProfilesCount(res.body.count));
  } catch (error) {
    dispatch(failureMyProfilesCount());
  }
};

const requestMyProfilesCount: ActionCreator<IMyProfilesCountRequest> = () => ({
  type: PROFILES_COUNT_REQUEST
});

const successMyProfilesCount: ActionCreator<IMyProfilesCountSuccess> = (
  count: number
) => ({
  type: PROFILES_COUNT_SUCCESS,
  payload: {
    count
  }
});

const failureMyProfilesCount: ActionCreator<IMyProfilesCountFailure> = () => ({
  type: PROFILES_COUNT_FAILURE
});
