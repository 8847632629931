import { InjectedFormikProps } from "formik";
import * as React from "react";
import CustomInput from "../CustomInput/CustomInput";

export interface IFormProps {
  createUserWidgetName?: string;
  createUserWidgetEmail?: string;
  createUserWidgetPassword?: string;
  isUpdating?: boolean;
}

export interface IFormValues {
  createUserWidgetName: string;
  createUserWidgetEmail: string;
  createUserWidgetPassword: string;
}

export const CreateUserWidgetForm: React.SFC<
  InjectedFormikProps<IFormProps, IFormValues>
> = props => (
  <>
    <CustomInput
      labelText="Naam"
      success={
        props.touched.createUserWidgetName && !props.errors.createUserWidgetName
      }
      error={
        props.touched.createUserWidgetName &&
        !!props.errors.createUserWidgetName
      }
      helpText={
        props.touched.createUserWidgetName && props.errors.createUserWidgetName
      }
      id="createUserWidgetName"
      formControlProps={{
        fullWidth: true,
        required: true,
        disabled: props.isUpdating
      }}
      inputProps={{
        onChange: props.handleChange,
        onBlur: props.handleBlur,
        type: "text",
        required: true
      }}
    />
    <CustomInput
      labelText="E-mailadres"
      success={
        props.touched.createUserWidgetEmail &&
        !props.errors.createUserWidgetEmail
      }
      error={
        props.touched.createUserWidgetEmail &&
        !!props.errors.createUserWidgetEmail
      }
      helpText={
        props.touched.createUserWidgetEmail &&
        props.errors.createUserWidgetEmail
      }
      id="createUserWidgetEmail"
      formControlProps={{
        fullWidth: true,
        required: true,
        disabled: props.isUpdating
      }}
      inputProps={{
        onChange: props.handleChange,
        onBlur: props.handleBlur,
        type: "text",
        required: true
      }}
    />
    <CustomInput
      labelText="Password"
      success={
        props.touched.createUserWidgetPassword &&
        !props.errors.createUserWidgetPassword
      }
      error={
        props.touched.createUserWidgetPassword &&
        !!props.errors.createUserWidgetPassword
      }
      helpText={
        props.touched.createUserWidgetPassword &&
        props.errors.createUserWidgetPassword
      }
      id="createUserWidgetPassword"
      formControlProps={{
        fullWidth: true,
        required: true,
        disabled: props.isUpdating
      }}
      inputProps={{
        onChange: props.handleChange,
        onBlur: props.handleBlur,
        type: "email",
        required: true
      }}
    />
  </>
);
export default CreateUserWidgetForm;
