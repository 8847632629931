import * as image from "../../assets/img/glansje_bg.jpg";
import {
  IUIState,
  UI_MOBILE_OPEN,
  UI_OPEN_AUTH_NAVBAR,
  UI_SET_MINI_ACTIVE,
  UI_SET_NOTIFICATIONS_OPEN,
  UI_SIDEBAR_MINIMIZE,
  UI_TOGGLE_AVATAR_OPEN,
  UIActions,
  UIPageActions,
  UISidebarActions
} from "./types";

export const initialState: IUIState = {
  miniActive: false,
  image,
  color: "blue",
  bgColor: "black",
  hasImage: true,
  mobileOpen: false,
  fixedClasses: "dropdown",
  authNavbarOpen: false,
  avatarOpen: false,
  notificationsOpen: false
};

const uiReducer = (
  state: IUIState = initialState,
  action: UIActions | UIPageActions | UISidebarActions
) => {
  switch (action.type) {
    case UI_SIDEBAR_MINIMIZE:
      return { ...state, miniActive: !state.miniActive };
    case UI_MOBILE_OPEN:
      return { ...state, mobileOpen: action.payload.open };
    case UI_OPEN_AUTH_NAVBAR:
      return { ...state, authNavbarOpen: action.payload.open };
    case UI_TOGGLE_AVATAR_OPEN:
      return { ...state, avatarOpen: !state.avatarOpen };
    case UI_SET_MINI_ACTIVE:
      return { ...state, miniActive: action.payload.active };
    case UI_SET_NOTIFICATIONS_OPEN:
      return { ...state, notificationsOpen: action.payload.open };
    default:
      return state;
  }
};

export default uiReducer;
