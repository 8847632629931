import * as React from "react";

import AuthenticatedRoute from "../hoc/AuthenticatedRoute";
import UnauthenticatedRoute from "../hoc/UnauthenticatedRoute";
import { IDashboardRoute, RouteType } from "../routes";

export function getActiveRoute(
  url: string,
  routes?: IDashboardRoute[]
): string {
  const activeRoute = "Glansje Dashboard";

  if (routes) {
    for (const route of routes) {
      if (route.collapse && route.views) {
        const collapseActiveRoute = getActiveRoute(url, route.views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (url.indexOf(route.layout + route.path) !== -1) {
          return route.name;
        }
      }
    }
  }
  return activeRoute;
}

export function getRoutes(
  mainPath: string,
  routes: IDashboardRoute[]
): React.ReactNode[] {
  return routes.map((route, key) => {
    if (route.collapse && route.views) {
      return getRoutes(mainPath, route.views);
    }

    if (route.layout === mainPath) {
      const routeProps = {
        path: route.layout + route.path,
        component: route.component,
        exact: true,
        key
      };

      switch (route.routeType) {
        case RouteType.UNAUTHENTICATED:
          return <UnauthenticatedRoute {...routeProps} />;
        case RouteType.AUTHENTICATED: {
          return <AuthenticatedRoute {...routeProps} />;
        }
        default:
          return <UnauthenticatedRoute {...routeProps} />;
      }
    } else {
      return null;
    }
  });
}
