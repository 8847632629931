import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import request from "../../../util/request";

/// Constants
export const DASHBOARD_USERS_COUNT_REQUEST = "@@dashboardUsers/COUNT_REQUEST";
export const DASHBOARD_USERS_COUNT_SUCCESS = "@@dashboardUsers/COUNT_SUCCESS";
export const DASHBOARD_USERS_COUNT_FAILURE = "@@dashboardUsers/COUNT_FAILURE";

/// Action Declarations
export interface IDashboardUsersCountRequest extends Action {
  type: typeof DASHBOARD_USERS_COUNT_REQUEST;
}

export interface IDashboardUsersCountSuccess extends Action {
  type: typeof DASHBOARD_USERS_COUNT_SUCCESS;
  payload: {
    count: number;
  };
}

export interface IDashboardUsersCountFailure extends Action {
  type: typeof DASHBOARD_USERS_COUNT_FAILURE;
}

export type DashboardUsersCountActions =
  | IDashboardUsersCountRequest
  | IDashboardUsersCountSuccess
  | IDashboardUsersCountFailure;

/// Action Definitions
export const fetchCount: ActionCreator<
  ThunkAction<void, IRootReducer, void, DashboardUsersCountActions>
> = () => async (dispatch, getState) => {
  dispatch(countRequest());
  try {
    const response = await request(dispatch, getState).get(
      "/GlansjeUsers/count"
    );
    dispatch(countSuccess(response.body.count));
  } catch (error) {
    dispatch(countFailure());
  }
};

export const countRequest: ActionCreator<IDashboardUsersCountRequest> = () => ({
  type: DASHBOARD_USERS_COUNT_REQUEST
});

export const countSuccess: ActionCreator<IDashboardUsersCountSuccess> = (
  count: number
) => ({
  type: DASHBOARD_USERS_COUNT_SUCCESS,
  payload: {
    count
  }
});

export const countFailure: ActionCreator<IDashboardUsersCountFailure> = () => ({
  type: DASHBOARD_USERS_COUNT_FAILURE
});
