import { createStyles, Grid, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";

const style = createStyles({
  grid: {
    padding: "0 15px !important"
  }
});

interface IProps extends WithStyles<typeof style> {
  children: React.ReactNode;
  className?: string;
  xs?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  sm?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  md?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  lg?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

function GridItem({ ...props }: IProps) {
  const { classes, children, className, ...rest } = props;

  return (
    <Grid item={true} {...rest} className={`${classes.grid} ${className}`}>
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridItem);
