import Button, { ButtonProps } from "@material-ui/core/Button";
import withStyles, {
  ClassNameMap,
  WithStyles
} from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import * as React from "react";

import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";

// nodejs library that concatenates classes
// material-ui components
interface IProps extends WithStyles<typeof buttonStyle> {
  color:
    | "primary"
    | "info"
    | "success"
    | "warning"
    | "danger"
    | "rose"
    | "orange"
    | "white"
    | "twitter"
    | "facebook"
    | "google"
    | "linkedin"
    | "pinterest"
    | "youtube"
    | "tumblr"
    | "github"
    | "behance"
    | "dribbble"
    | "reddit"
    | "transparent";
  size?: "sm" | "lg";
  simple?: boolean;
  round?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  block?: boolean;
  link?: boolean;
  justIcon?: boolean;
  className?: string;
  muiClasses?: Partial<ClassNameMap<string>>;
  children: JSX.Element | JSX.Element[] | React.ReactNode;
  component?: React.ReactType<ButtonProps>;
  disableFocusRipple?: boolean;
  disableRipple?: boolean;
  href?: string;
  mini?: boolean;
  type?: "button" | "reset" | undefined;
  variant?: "text" | "outlined" | "contained";
  onClick?: (e?: React.SyntheticEvent) => void;
  buttonRef?: (ref: HTMLElement) => void;
}

function RegularButton({ ...props }: IProps) {
  const {
    classes,
    color,
    round,
    children,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    muiClasses,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.button]: true,
    [classes.lg]: size === "lg",
    [classes.sm]: size === "sm",
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon
  });
  return (
    <Button
      {...rest}
      classes={muiClasses}
      className={`${btnClasses} ${className}`}
    >
      {props.children}
    </Button>
  );
}

export default withStyles(buttonStyle)(RegularButton);
