import { Hidden } from "@material-ui/core";
import * as React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

interface IProps {
  className?: string;
  user: JSX.Element;
  headerLinks?: JSX.Element;
  links: JSX.Element;
}

class SidebarWrapper extends React.Component<IProps> {
  public render() {
    const { className, user, headerLinks, links } = this.props;

    return (
      <PerfectScrollbar>
        <div className={className}>
          {user}
          <Hidden smDown={true} implementation="css">
            {headerLinks}
          </Hidden>
          {links}
        </div>
      </PerfectScrollbar>
    );
  }
}

export default SidebarWrapper;
