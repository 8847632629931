import { WithStyles, withStyles } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";

import cardIconStyle from "../../assets/jss/material-dashboard-pro-react/components/cardIconStyle";
import { CardColor } from "./types";

interface IProps extends WithStyles<typeof cardIconStyle> {
  children: JSX.Element | JSX.Element[];
  color?: CardColor;
  className?: string;
}

function CardIcon({ ...props }: IProps) {
  const { classes, className, children, color, ...rest } = props;
  const cardIconClasses = classNames({
    [classes.cardIcon]: true,
    [classes.warningCardHeader]: color === "warning",
    [classes.successCardHeader]: color === "success",
    [classes.dangerCardHeader]: color === "danger",
    [classes.infoCardHeader]: color === "info",
    [classes.primaryCardHeader]: color === "primary",
    [classes.roseCardHeader]: color === "rose",
    [classes.orangeCardHeader]: color === "orange"
  });

  return (
    <div className={`${cardIconClasses} ${className}`} {...rest}>
      {children}
    </div>
  );
}

export default withStyles(cardIconStyle)(CardIcon);
