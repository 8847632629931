import {
  Collapse,
  createStyles,
  LinearProgress,
  WithStyles,
  withStyles
} from "@material-ui/core";
import PlusIcon from "@material-ui/icons/Add";
import GraphIcon from "@material-ui/icons/BarChart";
import PersonIcon from "@material-ui/icons/Person";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import RefreshIcon from "@material-ui/icons/Refresh";
import TrashIcon from "@material-ui/icons/Remove";
import { RouterState } from "connected-react-router";
import moment from "moment";
import * as React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import {
  Redirect,
  RouteComponentProps,
  StaticContext,
  withRouter
} from "react-router";
import { ThunkDispatch } from "redux-thunk";
import { cardTitle } from "../../assets/jss/material-dashboard-pro-react";
import chartsStyle from "../../assets/jss/material-dashboard-pro-react/views/chartsStyle";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import { CardHeaderType, CardType } from "../../components/Card/types";
import CariesIndexChart from "../../components/Chart/CariesIndexChart";
import RiskScoreChart from "../../components/Chart/RiskScoreChart";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Heading from "../../components/Heading/Heading";
import { IProfile, IRiskScore } from "../../domain/models/profiles";
import { IRootReducer } from "../../store";
import {
  appReset,
  AppResetActions
} from "../../store/profiles/actions/appReset";
import { fetchProfileDetails } from "../../store/profiles/actions/profileDetails";
import {
  fetchRiskScores,
  FetchRiskScoresActions
} from "../../store/profiles/actions/riskScores";
import AddRiskScore from "./AddRiskScore";

const style = createStyles({
  ...chartsStyle,
  cardIconTitleMain: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    textAlign: "left"
  }
});

interface IState {
  profile: IProfile;
  riskScores: IRiskScore[];
  isFetching: boolean;
  isFetchingRiskScores: boolean;
  resetProfile: boolean;
}

interface IStateInternal {
  showForms: boolean;
}

interface IDispatch {
  fetchRiskScores: (profileId: string) => void;
  fetchProfileDetails: (profileId: string) => void;
  resetApp: (profileId: string) => void;
}

class EditProfile extends React.Component<
  IState &
  IDispatch &
  RouterState &
  WithStyles<typeof style> &
  RouteComponentProps<any, StaticContext, any>,
  IStateInternal
  > {
  public state: IStateInternal = {
    showForms: false
  };

  private ciChart = React.createRef<CariesIndexChart>();
  private riChart = React.createRef<RiskScoreChart>();

  public componentWillMount() {
    this.props.fetchProfileDetails(this.props.match.params.id);
    this.props.fetchRiskScores(this.props.match.params.id);
  }

  public render() {
    const { profile, classes, resetProfile } = this.props;
    if (this.props.isFetching || this.props.isFetchingRiskScores) {
      return <LinearProgress />;
    }
    if (!profile) {
      return <Redirect to="/admin/clients" />;
    } else {
      const xLabels = this.parseXAxisLabels();
      return (
        <>
          <Heading
            textAlign="center"
            title={`Profiel van ${profile.username.substr(32)}`}
          />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader color="success" icon={true}>
                  <CardIcon color="success">
                    <PersonIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitleMain}>Overzicht</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4} lg={3}>
                      <p>
                        <strong>Naam: </strong>
                        {profile.username.substr(32)}
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4} lg={3}>
                      <p>
                        <strong>Referentie: </strong>
                        {profile.reference}
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4} lg={3}>
                      <p>
                        <strong>Code: </strong>
                        {profile.shortId}
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4} lg={3}>
                      <p>
                        <strong>Profiel aangemaakt: </strong>{" "}
                        <Moment date={profile.created} format="DD-MM-YYYY" />
                      </p>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <p>
                        <strong>Score: {profile.score} / 150</strong>
                      </p>
                      <LinearProgress
                        variant="determinate"
                        value={Math.min((profile.score / 150) * 100, 100)}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button
                    color="success"
                    simple={true}
                    onClick={this.showForms}
                    disabled={!!this.state.showForms}
                  >
                    <PlusIcon /> NOCTP Score toevoegen
                  </Button>
                  <Button
                    color="warning"
                    disabled={resetProfile}
                    simple={true}
                    onClick={this.handleAppReset}
                  >
                    <RefreshIcon /> App opnieuw instellen
                  </Button>
                  <Button color="danger" simple={true}>
                    <TrashIcon /> Cliënt verwijderen
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            {this.renderForms()}
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <Card type={CardType.CHART}>
                <CardHeader icon={true} type={CardHeaderType.PLAIN}>
                  <CardIcon color="primary">
                    <GraphIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitleMain}>
                    Verloop NOCTP Score
                  </h4>
                </CardHeader>
                <CardBody>
                  <div style={{ width: "100%" }}>
                    <RiskScoreChart
                      riskScores={this.props.riskScores}
                      ref={this.riChart}
                      selectedProfile={profile}
                      labels={xLabels}
                    />
                  </div>
                </CardBody>
                <CardFooter>
                  <Button
                    simple={true}
                    color="orange"
                    onClick={this.generateRiPdf}
                  >
                    <PdfIcon /> Download PDF
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <Card type={CardType.CHART}>
                <CardHeader icon={true} type={CardHeaderType.PLAIN}>
                  <CardIcon color="orange">
                    <GraphIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitleMain}>Cariësindex</h4>
                </CardHeader>
                <CardBody>
                  <div style={{ width: "100%" }}>
                    <CariesIndexChart
                      labels={xLabels}
                      ref={this.ciChart}
                      riskScores={this.props.riskScores}
                      selectedProfile={this.props.profile}
                    />
                  </div>
                </CardBody>
                <CardFooter>
                  <Button
                    simple={true}
                    color="orange"
                    onClick={this.generateCiPdf}
                  >
                    <PdfIcon /> Download PDF
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </>
      );
    }
  }

  private showForms = () => {
    this.setState({ showForms: true });
  };

  private renderForms = () => {
    // if (this.state.showForms === false) {
    //   return null;
    // }
    return (
      <Collapse // mountOnEnter={true}
        // unmountOnExit={true}
        in={this.state.showForms}
      >
        <AddRiskScore cancel={this.cancelForm} />
      </Collapse>
    );
  };

  private cancelForm = () => {
    this.setState({
      showForms: false
    });
  };

  private generateCiPdf = () => {
    if (this.ciChart.current) {
      this.ciChart.current.renderPdf();
    }
  };
  private generateRiPdf = () => {
    if (this.riChart.current) {
      this.riChart.current.renderPdf();
    }
  };
  /// Event handlers
  private handleAppReset = () => {
    this.props.resetApp(this.props.profile.id);
  };

  private parseXAxisLabels = () => {
    const { riskScores } = this.props;
    return riskScores.map(el => moment(el.created).format("DD-MM-YYYY"));
  };
}

const mapStateToProps = (state: IRootReducer): IState => ({
  profile: state.profiles.selectedProfile!,
  riskScores: state.profiles.riskScores || [],
  isFetching: state.profiles.isFetching,
  isFetchingRiskScores: state.profiles.isFetchingRiskScores,
  resetProfile: state.profiles.selectedProfile
    ? state.profiles.selectedProfile.resetProfile
    : false
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    IRootReducer,
    void,
    FetchRiskScoresActions | AppResetActions
  >
): IDispatch => ({
  fetchRiskScores: (profileId: string) => dispatch(fetchRiskScores(profileId)),
  fetchProfileDetails: (profileId: string) =>
    dispatch(fetchProfileDetails(profileId)),
  resetApp: (profileId: string) => dispatch(appReset(profileId))
});

export default connect<IState, IDispatch, {}, IRootReducer>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(withRouter(EditProfile)));
