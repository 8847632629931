import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import { IDashboardUser } from "../../../domain/models/user";
import { IDashboardUserInputData } from "../../../domain/requests/DashboarUserInputData";
import request from "../../../util/request";

/* #region  Constants */
export const DASHBOARD_USERS_CREATE_REQUEST = "@@dashboardUsers/CREATE_REQUEST";
export const DASHBOARD_USERS_CREATE_SUCCESS = "@@dashboardUsers/CREATE_SUCCESS";
export const DASHBOARD_USERS_CREATE_FAILURE = "@@dashboardUsers/CREATE_FAILURE";
/* #endregion */

/* #region  Action Types */
export interface IDashboardUsersCreateRequest extends Action {
  type: typeof DASHBOARD_USERS_CREATE_REQUEST;
}

export interface IDashboardUsersCreateSuccess extends Action {
  type: typeof DASHBOARD_USERS_CREATE_SUCCESS;
  payload: {
    createdUser: IDashboardUser;
  };
}

export interface IDashboardUsersCreateFailure extends Action {
  type: typeof DASHBOARD_USERS_CREATE_FAILURE;
}

export type DashboardUsersCreateActions =
  | IDashboardUsersCreateRequest
  | IDashboardUsersCreateSuccess
  | IDashboardUsersCreateFailure;

/* #endregion */

/* #region  ActionCreators */
export const create: ActionCreator<
  ThunkAction<void, IRootReducer, void, DashboardUsersCreateActions>
> = (user: IDashboardUserInputData) => async (dispatch, getState) => {
  dispatch(createRequest());

  try {
    const response = await request(dispatch, getState)
      .post("/GlansjeUsers")
      .send(user);
    const newUser: IDashboardUser = {
      id: response.body.id,
      name: response.body.name,
      username: response.body.username,
      email: response.body.email,
      created: new Date(response.body.created),
      modified: new Date(response.body.modified),
      emailVerified: response.body.emailVerified,
      shortId: response.body.shortId
    };
    dispatch(createSuccess(newUser));
  } catch (error) {
    dispatch(createFailure());
  }
};

export const createRequest: ActionCreator<
  IDashboardUsersCreateRequest
> = () => ({
  type: DASHBOARD_USERS_CREATE_REQUEST
});

export const createSuccess: ActionCreator<IDashboardUsersCreateSuccess> = (
  createdUser: IDashboardUser
) => ({
  type: DASHBOARD_USERS_CREATE_SUCCESS,
  payload: {
    createdUser
  }
});

export const createFailure: ActionCreator<
  IDashboardUsersCreateFailure
> = () => ({
  type: DASHBOARD_USERS_CREATE_FAILURE
});
/* #endregion */
