import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import request from "../../../util/request";

/// Constants
/* #region  Constants */
export const DASHBOARD_USERS_DELETE_REQUEST = "@@dashboardUsers/DELETE_REQUEST";
export const DASHBOARD_USERS_DELETE_SUCCESS = "@@dashboardUsers/DELETE_SUCCESS";
export const DASHBOARD_USERS_DELETE_FAILURE = "@@dashboardUsers/DELETE_FAILURE";
/* #endregion */

/// Action Declarations
/* #region  Action Interfaces */
export interface IDashboardUsersDeleteRequest extends Action {
  type: typeof DASHBOARD_USERS_DELETE_REQUEST;
}

export interface IDashboardUsersDeleteSuccess extends Action {
  type: typeof DASHBOARD_USERS_DELETE_SUCCESS;
}

export interface IDashboardUsersDeleteFailure extends Action {
  type: typeof DASHBOARD_USERS_DELETE_FAILURE;
}

export type DashboardUsersDeleteActions =
  | IDashboardUsersDeleteRequest
  | IDashboardUsersDeleteSuccess
  | IDashboardUsersDeleteFailure;
/* #endregion */

/// Action Definitions
/* #region  ActionCreators */
export const deleteUser: ActionCreator<
  ThunkAction<void, IRootReducer, void, DashboardUsersDeleteActions>
> = (id: string) => async (dispatch, getState) => {
  dispatch(deleteRequest());

  try {
    await request(dispatch, getState).delete(`/GlansjeUsers/${id}`);
    dispatch(deleteSuccess());
  } catch (error) {
    dispatch(deleteFailure());
  }
};

export const deleteRequest: ActionCreator<
  IDashboardUsersDeleteRequest
> = () => ({
  type: DASHBOARD_USERS_DELETE_REQUEST
});

export const deleteSuccess: ActionCreator<
  IDashboardUsersDeleteSuccess
> = () => ({
  type: DASHBOARD_USERS_DELETE_SUCCESS
});

export const deleteFailure: ActionCreator<
  IDashboardUsersDeleteFailure
> = () => ({
  type: DASHBOARD_USERS_DELETE_FAILURE
});
/* #endregion */
