import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import { IUserLoginCredentials } from "../../../domain/requests/LoginCredentials";
import { ILoginResponse } from "../../../domain/responses/user/LoginResponse";
import request from "../../../util/request";
import { fetchRoles } from "./fetchRoles";

/// Constants
export const USER_LOGIN_REQUEST = "@@user/LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "@@user/LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "@@user/LOGIN_FAILURE";

/// Action Interfaces
export interface IUserLoginRequestAction extends Action {
  type: typeof USER_LOGIN_REQUEST;
}

export interface IUserLoginSuccessAction extends Action {
  type: typeof USER_LOGIN_SUCCESS;
  payload: ILoginResponse;
}

export interface IUserLoginFailureAction extends Action {
  type: typeof USER_LOGIN_FAILURE;
  payload: {
    error: string;
  };
}

export type UserLoginActions =
  | IUserLoginRequestAction
  | IUserLoginSuccessAction
  | IUserLoginFailureAction;

/// Actions
/**
 * Credentials login
 * @param credentials
 */
export const login: ActionCreator<
  ThunkAction<void, IRootReducer, void, UserLoginActions>
> = (credentials: IUserLoginCredentials) => async (dispatch, getState) => {
  dispatch(loginRequest());
  try {
    const res = await request(dispatch, getState)
      .post(`/GlansjeUsers/login?include=user`)
      .send(credentials);
    const loginResponse: ILoginResponse = res.body;
    dispatch(loginSuccess(loginResponse));
  } catch (error) {
    dispatch(loginFailure(error.toString()));
  }
};

/**
 * Types login request
 */
export const loginRequest: ActionCreator<IUserLoginRequestAction> = () => ({
  type: USER_LOGIN_REQUEST
});

/**
 * Datas login success
 * @param data
 */
export const loginSuccess: ActionCreator<IUserLoginSuccessAction> = (
  data: ILoginResponse
) => ({
  type: USER_LOGIN_SUCCESS,
  payload: {
    ...data
  }
});

/**
 * Types login failure
 */
export const loginFailure: ActionCreator<IUserLoginFailureAction> = (error: string = "Er is iets mis gegaan bij het aanmelden. Probeer het nogmaals.") => ({
  type: USER_LOGIN_FAILURE,
  payload: {
    error
  }
});
