import {
  Hidden,
  WithStyles,
  withStyles,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  Paper,
  CircularProgress,
  MenuItem
} from "@material-ui/core";
import * as React from "react";
import adminNavbarLinksStyle from "../../assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle";
import Button from "../CustomButtons/Button";
import CustomInput from "../CustomInput/CustomInput";

// Material UI icons
import Dashboard from "@material-ui/icons/Dashboard";
// import Person from "@material-ui/icons/Person";
import Search from "@material-ui/icons/Search";
import AdminNavbarNotifications from "./AdminNavbarNotifications";
import { IProfile } from "../../domain/models/profiles";
import { IRootReducer } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import {
  ProfilesSearchActions,
  searchProfiles
} from "../../store/profiles/actions/searchProfiles";
import { connect } from "react-redux";
import { push, CallHistoryMethodAction } from "connected-react-router";

interface IState {
  isSearching: boolean;
  hasSearchError: boolean;
  searchProfiles: IProfile[];
}

interface IDispatch {
  search: (search: string) => void;
  navigateToDetail: (profileId: string) => void;
}

interface IInternalState {
  searchOpen: boolean;
  searchText: string;
}
class HeaderLinks extends React.Component<
  IState & IDispatch & WithStyles<typeof adminNavbarLinksStyle>,
  IInternalState
> {
  public state: IInternalState = {
    searchOpen: false,
    searchText: ""
  };
  private searchRef: HTMLDivElement | null = null;

  public render() {
    const { classes } = this.props;

    const searchButtonClasses = `${classes.top} ${classes.searchButton}`;

    return (
      <>
        <div
          ref={ref => {
            this.searchRef = ref;
          }}
        >
          <CustomInput
            id="headerSearch"
            formControlProps={{
              className: `${classes.top} ${classes.search}`
            }}
            inputProps={{
              placeholder: "Zoeken...",
              inputProps: {
                placeholder: "Zoeken...",
                className: classes.searchInput,
                onChange: (e: React.SyntheticEvent<HTMLInputElement>) => {
                  this.setState({ searchText: e.currentTarget.value });
                }
              }
            }}
          />
          <Button
            color="white"
            aria-label="Zoeken"
            justIcon={true}
            round={true}
            className={searchButtonClasses}
            onClick={this.handleSearch}
            // buttonRef={ref => {
            //   this.searchRef = ref;
            // }}
          >
            <Search
              className={`${classes.headerLinksSvg} ${classes.searchIcon}`}
            />
          </Button>
          <Button
            color="transparent"
            simple={true}
            aria-label="Dashboard"
            justIcon={true}
            className={classes.buttonLink}
          >
            <Dashboard
              className={`${classes.headerLinksSvg} ${classes.links}`}
            />
            <Hidden mdUp={true}>
              <span className={classes.linkText}>Dashboard</span>
            </Hidden>
          </Button>
          <AdminNavbarNotifications />
          {/* <Button
          color="transparent"
          aria-label="Persoon"
          justIcon={true}
          className={classes.buttonLink}
        >
          <Person className={`${classes.headerLinksSvg} ${classes.links}`} />
          <Hidden mdUp={true} implementation="css">
            <span className={classes.linkText}>Profiel</span>
          </Hidden>
        </Button> */}
        </div>
        <Popper
          open={this.state.searchOpen}
          anchorEl={this.searchRef}
          transition={true}
          disablePortal={true}
        >
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
              <Paper>
                <ClickAwayListener onClickAway={this.handleCloseSearch}>
                  {this.props.isSearching ? (
                    <CircularProgress />
                  ) : (
                    <MenuList>
                      {this.props.hasSearchError ? (
                        <MenuItem>Er is iets misgegaan...</MenuItem>
                      ) : (
                        this.props.searchProfiles.map((pro, idx) => {
                          return (
                            <MenuItem
                              key={idx}
                              onClick={this.navigateToProfile(pro.id)}
                            >
                              <strong>{pro.reference}</strong>
                            </MenuItem>
                          );
                        })
                      )}
                    </MenuList>
                  )}
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  }

  private handleSearch = () => {
    this.props.search(this.state.searchText);
    this.setState({ searchOpen: true });
  };
  private handleCloseSearch = () => {
    this.setState({ searchOpen: false });
  };

  private navigateToProfile = (profileId: string) => () => {
    this.props.navigateToDetail(profileId);
  };
}

const mapStateToProps = (state: IRootReducer): IState => ({
  hasSearchError: state.profiles.hasSearchError,
  isSearching: state.profiles.isSearching,
  searchProfiles: state.profiles.searchProfiles
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    IRootReducer,
    void,
    ProfilesSearchActions | CallHistoryMethodAction<[string, any?]>
  >
): IDispatch => ({
  search: (search: string) => dispatch(searchProfiles(search)),
  navigateToDetail: profileId =>
    dispatch(push(`/admin/client/edit/${profileId}`))
});

export default connect<IState, IDispatch, {}, IRootReducer>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(adminNavbarLinksStyle)(HeaderLinks));
