import {
  AppBar,
  Hidden,
  Toolbar,
  WithStyles,
  withStyles
} from "@material-ui/core";
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import adminNavbarStyle from "../../assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import { adminRoutes } from "../../routes";
import { IRootReducer } from "../../store";
import { mobileOpen, sidebarMinize } from "../../store/ui/actions";
import { getActiveRoute } from "../../util/route_helpers";
import Button from "../CustomButtons/Button";
import AdminNavbarLinks from "./AdminNavbarLinks";

interface IProps {
  color?: "primary" | "info" | "success" | "warning" | "danger";
}

interface IState {
  miniActive: boolean;
  mobileOpen: boolean;
}

interface IDispatch {
  sidebarMinimize: () => void;
  setMobileOpen: (open: boolean) => void;
}

function AdminNavbar({
  ...props
}: IProps & IState & IDispatch & WithStyles<typeof adminNavbarStyle>) {
  const { classes, color } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes.primary]: color === "primary",
    [classes.info]: color === "info",
    [classes.success]: color === "success",
    [classes.warning]: color === "warning",
    [classes.danger]: color === "danger"
  });

  const sidebarMinimize = classNames({
    [classes.sidebarMinimize]: true
  });

  const brandName = getActiveRoute(window.location.href, adminRoutes);

  const handleDrawerToggle = () => {
    props.setMobileOpen(!props.mobileOpen);
  };

  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown={true} implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                justIcon={true}
                round={true}
                color="white"
                onClick={props.sidebarMinimize}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button
                justIcon={true}
                round={true}
                color="white"
                onClick={props.sidebarMinimize}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>
        <div className={classes.flex}>
          <Button
            href="/admin/dashboard"
            className={classes.title}
            color="transparent"
          >
            {brandName}
          </Button>
        </div>
        <Hidden smDown={true} implementation="css">
          <AdminNavbarLinks />
        </Hidden>
        <Hidden mdUp={true} implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon={true}
            aria-label="Open menu"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

const mapStateToProps = (state: IRootReducer): IState => ({
  miniActive: state.ui.miniActive,
  mobileOpen: state.ui.mobileOpen
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatch => ({
  sidebarMinimize: () => dispatch(sidebarMinize()),
  setMobileOpen: (open: boolean) => dispatch(mobileOpen(open))
});

export default connect<IState, IDispatch, IProps, IRootReducer>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(adminNavbarStyle)(AdminNavbar));
