import { jsonDateParser } from "json-date-parser";
import { ThunkDispatch } from "redux-thunk";
import superagent from "superagent";
import prefix from 'superagent-prefix';

import * as config from "../config";
import { IToken } from "../domain/models/user";
import { IRootReducer } from "../store";
import { logoutSuccess } from "../store/user/actions/logout";
import { refreshToken } from "../store/user/actions/refreshToken";

export enum TokenStatus {
  VALID,
  INVALID,
  NEEDS_REFRESH
}

const request = (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => IRootReducer
) => {
  const agent = superagent.agent();
  agent.use(prefix(config.API_URL)); 
  agent.parse["application/json"] = (text: string) =>
    JSON.parse(text, jsonDateParser);


  // const token = getToken();
  const {
    user: { token }
  } = getState();
  if (token && token.token) {
    const tokenStatus = checkTokenStatus(token);
    if (tokenStatus === TokenStatus.VALID) {
      agent.set(config.AUTH_HEADER, token.token);
    } else if (tokenStatus === TokenStatus.NEEDS_REFRESH) {
      dispatch(refreshToken());
    } else {
      dispatch(logoutSuccess());
    }
  }

  return agent;
};

/**
 * Check the status of the current token in use.
 * @param token
 * @returns status
 */
export function checkTokenStatus(token: IToken): TokenStatus {
  const tokenTTL = new Date(token.created).getTime() + token.ttl * 1000;
  const now = Date.now();
  const diff = (now - tokenTTL) / 60000;
  if (diff < -10) {
    return TokenStatus.VALID;
  } else if (diff >= -10 && diff < 0) {
    return TokenStatus.NEEDS_REFRESH;
  } else {
    return TokenStatus.INVALID;
  }
}

export interface IRequestFilter {
  include?: string | string[];
  limit?: number;
  skip?: number;
  order?: any;
  where?: any;
}

export function setFilter(filter: IRequestFilter): string {
  return `?filter=${JSON.stringify(filter)}`;
}

export default request;
