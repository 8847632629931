export enum CardType {
    PLAIN,
    PROFILE,
    BLOG,
    PRICING,
    TESTIMONIAL,
    PRODUCT,
    CHART,
    LOGIN
}

export enum CardAvatarType {
    PLAIN,
    PROFILE,
    TESTIMONIAL,
}

export enum CardBodyType {
    PLAIN,
    PRICING,
    SIGNUP,
    PROFILE,
    CALENDAR,
}

export enum CardFooterType {
    PLAIN,
    PROFILE,
    PRICING,
    TESTIMONIAL,
    STATS,
    CHART,
    PRODUCT,
}

export enum CardHeaderType {
    PLAIN,
    CONTACT,
    SIGNUP,
    STATS,
}


export type CardColor = 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'rose' | 'orange';