import {
  Icon,
  InputAdornment,
  WithStyles,
  withStyles
} from "@material-ui/core";
import Email from "@material-ui/icons/Email";
import * as React from "react";
import { ChangeEvent } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { ThunkDispatch } from "redux-thunk";
import loginPageStyle from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import CardHeader from "../../components/Card/CardHeader";
import { CardType } from "../../components/Card/types";
import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Danger from "../../components/Typography/Danger";
import { IUserLoginCredentials } from "../../domain/requests/LoginCredentials";
import { IRootReducer } from "../../store";
import {
  login as loginAction,
  UserLoginActions
} from "../../store/user/actions/login";

interface IProps extends WithStyles<typeof loginPageStyle> {
  isLoggingIn: boolean;
  hasError: boolean;
  isLoggedIn: boolean;
  loginErrors: string;
  login: (credentials: IUserLoginCredentials) => void;
}

class LoginPage extends React.Component<IProps> {
  public state = {
    cardAnimation: "cardHidden",
    username: "",
    password: ""
  };

  private timeoutFunction = setTimeout(() => {
    this.setState({
      cardAnimation: ""
    });
  }, 700);

  public componentWillUnmount() {
    clearTimeout(this.timeoutFunction);
  }

  public render() {
    const { classes, isLoggedIn, isLoggingIn, hasError, loginErrors } = this.props;
    if (isLoggedIn) {
      return <Redirect to="/admin/dashboard" />;
    }
  
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={this.handleFormSubmit}>
              <Card
                type={CardType.LOGIN}
                className={this.state.cardAnimation && classes.cardHidden}
              >
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="orange"
                >
                  <h4 className={classes.cardTitle}>Login</h4>
                </CardHeader>
                <CardBody>
                  {hasError && <Danger>{loginErrors}</Danger>}

                  <CustomInput
                    labelText="E-mailadres..."
                    id="username"
                    error={hasError}
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      disabled: isLoggingIn,
                      required: true,
                      type: "email",
                      onChange: this.handleEmailChange,
                      value: this.state.username,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    labelText="Wachtwoord..."
                    id="password"
                    error={hasError}
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      disabled: isLoggingIn,
                      required: true,
                      type: "password",
                      onChange: this.handlePasswordChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      )
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button
                    disabled={isLoggingIn}
                    color="orange"
                    simple={true}
                    size="lg"
                    block={true}
                    onClick={this.handleFormSubmit as any}
                  >
                    Login
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  private handleEmailChange = ({
    currentTarget
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      username: currentTarget.value
    });
  };

  private handlePasswordChange = ({
    currentTarget
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      password: currentTarget.value
    });
  };

  private handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.login({
      email: this.state.username,
      password: this.state.password
    });
  };
}

const mapStateToProps = (state: IRootReducer) => ({
  isLoggingIn: state.user.isLoggingIn,
  hasError: state.user.hasError,
  loginErrors: state.user.loginError,
  isLoggedIn: !!state.user.token
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootReducer, void, UserLoginActions>
) => ({
  login: (credentials: IUserLoginCredentials) =>
    dispatch(loginAction(credentials))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(loginPageStyle)(LoginPage));
