import { InjectedFormikProps, withFormik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import * as Yup from "yup";
import Widget from ".";
import { IDashboardUserInputData } from "../../domain/requests/DashboarUserInputData";
import { IRootReducer } from "../../store";
import {
  create,
  DashboardUsersCreateActions
} from "../../store/dashboardUsers/actions";
import Button from "../CustomButtons/Button";
import CreateUserWidgetForm, {
  IFormProps,
  IFormValues
} from "../forms/CreateUserWidgetForm";

interface IState {
  isUpdating: boolean;
  hasError: boolean;
}

interface IDispatch {
  submit: (values: IDashboardUserInputData) => void;
}

const CreateUser = ({
  ...props
}: InjectedFormikProps<IFormProps, IFormValues> & IState & IDispatch) => {
  const { isUpdating, hasError, submit, ...rest } = props;
  return (
    <Widget
      headerColor="success"
      allowedRoles={["admin"]}
      xs={12}
      sm={6}
      md={4}
      lg={3}
      title="Nieuwe gebruiker"
      footer={
        <>
          <Button
            color="danger"
            type="reset"
            disabled={!props.dirty || props.isUpdating}
          >
            Opnieuw
          </Button>
          <Button
            color="success"
            type="button"
            disabled={
              !props.dirty ||
              props.isUpdating ||
              props.hasError ||
              !!Object.keys(props.errors).length
            }
          >
            Opslaan
          </Button>
        </>
      }
    >
      <CreateUserWidgetForm {...rest} />
    </Widget>
  );
};

const EnhancedCreateUser = withFormik<
  IFormProps & IState & IDispatch,
  IFormValues
>({
  mapPropsToValues: () => ({
    createUserWidgetName: "",
    createUserWidgetEmail: "",
    createUserWidgetPassword: ""
  }),
  validationSchema: Yup.object().shape({
    createUserWidgetName: Yup.string()
      .min(2, "Minimaal 2 tekens")
      .max(255, "Max 255 tekens")
      .required("Voer een naam in"),
    createUserWidgetEmail: Yup.string()
      .email("Ongeldig e-mailadres")
      .required("Een e-mailadres is verplicht."),
    createUserWidgetPassword: Yup.string()
      .min(6, "Het wachtwoord moet minimaal 6 tekens bevatten.")
      .required("Het wachtwoord is verplicht")
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.submit({
      name: values.createUserWidgetName,
      email: values.createUserWidgetEmail,
      password: values.createUserWidgetPassword
    });
    setSubmitting(false);
  },
  displayName: "CreateUserWidgetForm"
})(CreateUser);

const mapStateToProps = (state: IRootReducer): IState => ({
  isUpdating: state.dashboardUsers.isUpdating,
  hasError: state.dashboardUsers.hasError
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootReducer, void, DashboardUsersCreateActions>
): IDispatch => ({
  submit: (values: IDashboardUserInputData) => dispatch(create(values))
});

export default connect<IState, IDispatch, {}, IRootReducer>(
  mapStateToProps,
  mapDispatchToProps
)(EnhancedCreateUser);
