import { INotification } from "../../domain/models/notifications";
import {
  NotificationCountActions,
  NOTIFICATIONS_COUNT_FAILURE,
  NOTIFICATIONS_COUNT_REQUEST,
  NOTIFICATIONS_COUNT_SUCCESS
} from "./actions/count";
import {
  NotificationFetchActions,
  NOTIFICATIONS_FETCH_FAILURE,
  NOTIFICATIONS_FETCH_REQUEST,
  NOTIFICATIONS_FETCH_SUCCESS
} from "./actions/fetch";

export interface INotificationState {
  readonly count: number;
  readonly isFetchingCount: boolean;
  readonly isFetching: boolean;
  readonly hasError: boolean;
  readonly notifications: INotification[];
}

export const initialState: INotificationState = {
  notifications: [],
  count: 0,
  hasError: false,
  isFetching: false,
  isFetchingCount: false
};

const notificationsReducer = (
  state: INotificationState = initialState,
  action: NotificationCountActions | NotificationFetchActions
) => {
  switch (action.type) {
    case NOTIFICATIONS_COUNT_REQUEST:
      return { ...state, isFetchingCount: true, hasError: false };
    case NOTIFICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        isFetchingCount: false,
        hasError: false,
        count: action.payload.count
      };
    case NOTIFICATIONS_COUNT_FAILURE:
      return { ...state, hasError: true, isFetchingCount: false, count: 0 };
    case NOTIFICATIONS_FETCH_REQUEST:
      return { ...state, isFetching: true };
    case NOTIFICATIONS_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        notifications: action.payload.notifications
      };
    case NOTIFICATIONS_FETCH_FAILURE:
      return { ...state, isFetching: false, hasError: true, notifications: [] };
    default:
      return state;
  }
};

export default notificationsReducer;
