import { routerActions } from "connected-react-router";
import * as queryString from "query-string";
import { connect } from "react-redux";
import { Route } from "react-router";
import { IRootReducer } from "../store";
import { IExtendedRouteProps } from "./types";

class UnauthenticatedRoute extends Route<IExtendedRouteProps> {
  public componentDidMount() {
    if (this.props.isAuthenticated) {
      if (this.props.location) {
        const query = queryString.parse(this.props.location.search);

        if (query.redirect && typeof query.redirect === "string") {
          routerActions.push(query.redirect.toString());
          return; // exit early
        }
      }
      routerActions.push("/admin/dashboard");
    }
  }
}

const mapStateToProps = (state: IRootReducer) => ({
  isAuthenticated: !!state.user.token,
  router: state.router
});

export default connect(
  mapStateToProps,
  null
)(UnauthenticatedRoute);
