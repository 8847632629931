import { IRole } from "../domain/models/user";

export type AllowedRole = "admin" | "dentist";

export function checkRolePermission(
  allowedRole: AllowedRole | AllowedRole[],
  roles: IRole[]
): boolean {
  if (typeof allowedRole === "string") {
    for (const role of roles) {
      if (role.name === allowedRole) {
        return true;
      }
    }
  } else {
    for (const aRole of allowedRole) {
      for (const role of roles) {
        if (aRole === role.name) {
          return true;
        }
      }
    }
  }

  return false;
}
