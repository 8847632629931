import { WithStyles, withStyles } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";

import cardHeaderStyle from "../../assets/jss/material-dashboard-pro-react/components/cardHeaderStyle";
import { CardColor, CardHeaderType } from "./types";

interface IProps {
  children: React.ReactNode;
  className?: string;
  type?: CardHeaderType;
  color?: CardColor;
  image?: boolean;
  icon?: boolean;
  text?: boolean;
}

function CardHeader({ ...props }: IProps & WithStyles<typeof cardHeaderStyle>) {
  const {
    classes,
    className,
    children,
    color,
    image,
    icon,
    text,
    type,
    ...rest
  } = props;

  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes.primaryCardHeader]: color === "primary",
    [classes.infoCardHeader]: color === "info",
    [classes.dangerCardHeader]: color === "danger",
    [classes.successCardHeader]: color === "success",
    [classes.warningCardHeader]: color === "warning",
    [classes.roseCardHeader]: color === "rose",
    [classes.orangeCardHeader]: color === "orange",
    [classes.cardHeaderImage]: image,
    [classes.cardHeaderIcon]: icon,
    [classes.cardHeaderText]: text,
    [classes.cardHeaderPlain]: type === CardHeaderType.PLAIN,
    [classes.cardHeaderContact]: type === CardHeaderType.CONTACT,
    [classes.cardHeaderSignup]: type === CardHeaderType.SIGNUP,
    [classes.cardHeaderStats]: type === CardHeaderType.STATS
  });
  return (
    <div className={`${cardHeaderClasses} ${className}`} {...rest}>
      {children}
    </div>
  );
}

export default withStyles(cardHeaderStyle)(CardHeader);
