import { WithStyles, withStyles } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";

import cardStyle from "../../assets/jss/material-dashboard-pro-react/components/cardStyle";
import { CardColor, CardType } from "./types";

interface IProps {
  className?: string;
  children?: React.ReactNode;
  type?: CardType;
  raised?: boolean;
  background?: boolean;
  color?: CardColor;
}

function Card({ ...props }: IProps & WithStyles<typeof cardStyle>) {
  const {
    classes,
    children,
    className,
    type,
    raised,
    background,
    color
  } = props;

  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: type === CardType.PLAIN,
    [classes.cardProfile]: type === CardType.PROFILE || CardType.TESTIMONIAL,
    [classes.cardBlog]: type === CardType.BLOG,
    [classes.cardRaised]: !!raised,
    [classes.cardBackground]: !!background,
    [classes.cardPricingColor]:
      (CardType.PRICING && !!color) || (CardType.PRICING && !!background),
    [classes.primary]: color === "primary",
    [classes.info]: color === "info",
    [classes.success]: color === "success",
    [classes.warning]: color === "warning",
    [classes.danger]: color === "danger",
    [classes.rose]: color === "rose",
    [classes.cardPricing]: CardType.PRICING,
    [classes.cardProduct]: CardType.PRODUCT,
    [classes.cardChart]: CardType.CHART,
    [classes.cardLogin]: CardType.LOGIN
  });

  return <div className={`${cardClasses} ${className}`}>{children}</div>;
}

export default withStyles(cardStyle)(Card);
