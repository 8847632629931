import { CallHistoryMethodAction, push } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Widget from ".";
import { IRootReducer } from "../../store";
import {
  DashboardUsersCountActions,
  fetchCount
} from "../../store/dashboardUsers/actions";
import Button from "../CustomButtons/Button";
import WidgetCounter from "../Typography/WidgetCounter";

interface IState {
  count: number;
}

interface IDispatch {
  fetchCount: () => void;
  navigate: () => void;
}

class UserWidget extends React.Component<IState & IDispatch> {
  public componentWillMount() {
    this.props.fetchCount();
  }

  public render() {
    return (
      <Widget
        xs={12}
        sm={6}
        md={4}
        lg={3}
        headerColor="rose"
        title="Totaal gebruikers"
        allowedRoles={["admin"]}
        footer={
          <Button onClick={this.props.navigate} simple={true} color="rose">
            Alle gebruikers
          </Button>
        }
      >
        <WidgetCounter color="rose" count={this.props.count} />
      </Widget>
    );
  }
}
const mapStateToProps = (state: IRootReducer): IState => ({
  count: state.dashboardUsers.count
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    IRootReducer,
    void,
    DashboardUsersCountActions | CallHistoryMethodAction<[string, any?]>
  >
): IDispatch => ({
  fetchCount: () => dispatch(fetchCount()),
  navigate: () => dispatch(push("/admin/users"))
});

export default connect<IState, IDispatch, {}, IRootReducer>(
  mapStateToProps,
  mapDispatchToProps
)(UserWidget);
