import { CallHistoryMethodAction, push } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Widget from ".";
import { IRootReducer } from "../../store";
import { fetchMyProfilesCount } from "../../store/profiles/actions/myProfilesCount";
import { MyProfilesCountActions } from "../../store/profiles/actions/myProfilesCount";
import Button from "../CustomButtons/Button";
import WidgetCounter from "../Typography/WidgetCounter";

interface IState {
  count: number;
  isFetching: boolean;
  hasError: boolean;
}

interface IDispatch {
  fetchProfileCount: () => void;
  navigate: () => void;
}

class MyTotalProfiles extends React.Component<IState & IDispatch> {
  public componentWillMount() {
    this.props.fetchProfileCount();
  }

  public render() {
    return (
      <Widget
        allowedRoles={["admin", "dentist"]}
        headerColor="primary"
        xs={12}
        sm={6}
        md={4}
        lg={3}
        title="Mijn Cli&euml;nten"
        footer={
          <Button onClick={this.props.navigate} color="primary" simple={true}>
            Mijn cli&euml;nten &gt;
          </Button>
        }
      >
        <WidgetCounter color="primary" count={this.props.count} />
      </Widget>
    );
  }

  // private navigate = () => {
  //   push("/admin/navigate");
  // };
}

const mapStateToProps = (state: IRootReducer): IState => ({
  count: state.profiles.myProfilesCount,
  isFetching: state.profiles.isFetching,
  hasError: state.profiles.hasError
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    IRootReducer,
    void,
    MyProfilesCountActions | CallHistoryMethodAction<[string, any?]>
  >
): IDispatch => ({
  fetchProfileCount: () => dispatch(fetchMyProfilesCount()),
  navigate: () => dispatch(push("/admin/clients"))
});

export default connect<IState, IDispatch, {}, IRootReducer>(
  mapStateToProps,
  mapDispatchToProps
)(MyTotalProfiles);
