import { IRoleResponse } from "domain/responses/user/RoleResponse";
import { IRole } from "../../domain/models/user/Role";
import { IToken } from "../../domain/models/user/Token";
import { IUser } from "../../domain/models/user/User";
import actions from "./actions";
import {
  USER_FETCH_ROLES_FAILURE,
  USER_FETCH_ROLES_REQUEST,
  USER_FETCH_ROLES_SUCCESS,
  UserRolesActions
} from "./actions/fetchRoles";
import {
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  UserLoginActions
} from "./actions/login";
import {
  USER_LOGOUT_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  UserLogoutActions
} from "./actions/logout";
import {
  USER_REFRESH_TOKEN_SUCCESS,
  UserRefreshTokenActions
} from "./actions/refreshToken";

export interface IUserState {
  readonly token: IToken | null;
  readonly user: IUser | null;
  readonly roles: IRole[];
  readonly isLoggingIn: boolean;
  readonly hasError: boolean;
  readonly hasRoleError: boolean;
  readonly loginError: string;
}

type UserActions =
  | UserLoginActions
  | UserRolesActions
  | UserLogoutActions
  | UserRefreshTokenActions;

export const initialState: IUserState = {
  token: null,
  user: null,
  roles: [],
  isLoggingIn: false,
  hasError: false,
  hasRoleError: false,
  loginError: ''
};

const userReducer = (
  state: IUserState = initialState,
  action: UserActions
): IUserState => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { ...state, isLoggingIn: true, hasError: false };
    case USER_LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        hasError: true,
        user: null,
        token: null,
        roles: [],
        loginError: action.payload.error
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        token: {
          token: action.payload.id,
          ttl: action.payload.ttl,
          created: new Date(action.payload.created)
        },
        isLoggingIn: false,
        hasError: false,
        user: {
          id: action.payload.user.id,
          shortId: action.payload.user.shortId,
          created: new Date(action.payload.user.created),
          modified: new Date(action.payload.user.modified),
          email: action.payload.user.email,
          emailVerified: action.payload.user.emailVerified,
          name: action.payload.user.name
        },
        roles: action.payload.user.roles.map((r: IRoleResponse) => ({
          ...r,
          created: new Date(r.created),
          modified: new Date(r.modified)
        }))
      };
    case USER_FETCH_ROLES_REQUEST:
      return { ...state, isLoggingIn: true };
    case USER_FETCH_ROLES_SUCCESS:
      return { ...state, isLoggingIn: false, roles: action.payload };
    case USER_FETCH_ROLES_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        hasError: true,
        hasRoleError: true,
        user: null,
        token: null,
        roles: []
      };
    case USER_LOGOUT_REQUEST:
      return state;
    case USER_LOGOUT_SUCCESS:
      return { ...state, token: null, user: null, roles: [] };
    case USER_LOGOUT_FAILURE:
      return state;
    case USER_REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: {
          token: action.payload.token.id,
          ttl: action.payload.token.ttl,
          created: new Date(action.payload.token.created)
        }
      };
    default:
      return state;
  }
};

export default userReducer;
