import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import request from "../../../util/request";

/// Constants
export const USER_LOGOUT_REQUEST = "@@user/LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "@@user/LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILURE = "@@user/LOGOUT_FAILURE";

/// Action Interfaces
export interface IUserLogoutRequest extends Action {
  type: typeof USER_LOGOUT_REQUEST;
}

export interface IUserLogoutSuccess extends Action {
  type: typeof USER_LOGOUT_SUCCESS;
}

export interface IUserLogoutFailure extends Action {
  type: typeof USER_LOGOUT_FAILURE;
}

export type UserLogoutActions =
  | IUserLogoutRequest
  | IUserLogoutSuccess
  | IUserLogoutFailure;

/// Actions
export const logout: ActionCreator<
  ThunkAction<void, IRootReducer, void, UserLogoutActions>
> = () => async (dispatch, getState) => {
  dispatch(logoutRequest());
  try {
    await request(dispatch, getState)
      .post("/GlansjeUsers/logout")
      .send();
    dispatch(logoutSuccess());
  } catch (error) {
    if (error.status !== 401) {
      dispatch(logoutFailure());
    } else {
      dispatch(logoutSuccess());
    }
  }
};

/**
 * Types logout request
 */
export const logoutRequest: ActionCreator<IUserLogoutRequest> = () => ({
  type: USER_LOGOUT_REQUEST
});

/**
 * Types logout success
 */
export const logoutSuccess: ActionCreator<IUserLogoutSuccess> = () => ({
  type: USER_LOGOUT_SUCCESS
});

/**
 * Errors logout failure
 * @param error
 */
export const logoutFailure: ActionCreator<IUserLogoutFailure> = () => ({
  type: USER_LOGOUT_FAILURE
});
