import { InjectedFormikProps } from "formik";
import * as React from "react";
import CustomInput from "../CustomInput/CustomInput";

export interface IFormProps {
  linkProfileWidgetShortCode?: string;
  linkProfileWidgetClientReference?: string;
  isSearching?: boolean;
}

export interface IFormValues {
  linkProfileWidgetShortCode: string;
  linkProfileWidgetClientReference: string;
}

export const LinkProfileWidgetForm: React.SFC<
  InjectedFormikProps<IFormProps, IFormValues>
> = props => (
  <>
    <CustomInput
      labelText="Code"
      success={
        props.touched.linkProfileWidgetShortCode &&
        !props.errors.linkProfileWidgetShortCode
      }
      error={
        props.touched.linkProfileWidgetShortCode &&
        !!props.errors.linkProfileWidgetShortCode
      }
      helpText={
        props.touched.linkProfileWidgetShortCode &&
        props.errors.linkProfileWidgetShortCode
      }
      id="linkProfileWidgetShortCode"
      formControlProps={{
        fullWidth: true,
        required: true,
        disabled: props.isSubmitting
      }}
      inputProps={{
        onChange: props.handleChange,
        onBlur: props.handleBlur,
        type: "text",
        required: true
      }}
    />
    <CustomInput
      labelText="Referentie"
      success={
        props.touched.linkProfileWidgetClientReference &&
        !props.errors.linkProfileWidgetClientReference
      }
      error={
        props.touched.linkProfileWidgetClientReference &&
        !!props.errors.linkProfileWidgetClientReference
      }
      helpText={
        props.touched.linkProfileWidgetClientReference &&
        props.errors.linkProfileWidgetClientReference
      }
      id="linkProfileWidgetClientReference"
      formControlProps={{
        fullWidth: true,
        required: true,
        disabled: props.isSubmitting
      }}
      inputProps={{
        onChange: props.handleChange,
        onBlur: props.handleBlur,
        type: "text",
        required: true
      }}
    />
  </>
);

export default LinkProfileWidgetForm;
