import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  WithStyles,
  withStyles
} from "@material-ui/core";
import * as React from "react";

import tableStyle from "../../assets/jss/material-dashboard-pro-react/components/tableStyle";
import Head, { ITableHeadCell, TableHeaderColor } from "./Head";
import Row, { IRow } from "./Row";

export interface ITableData {
  rows: IRow[];
}

interface IProps {
  tableHead?: ITableHeadCell[];
  tableHeaderColor: TableHeaderColor;
  tableData?: IRow[];
  striped?: boolean;
  hover?: boolean;
  paginate?: boolean;
  totalRows?: number;
  totalCols: number;
  page?: number;
  isFetching?: boolean;
  onChangePage?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  onChangeRowsPerPage?: (rows: number) => void;
}

const CustomTable = ({ ...props }: IProps & WithStyles<typeof tableStyle>) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    nextPage: number
  ) => {
    if (props.onChangePage) {
      props.onChangePage(event, nextPage);
    }
  };

  const handleChangeRowsPerPage = ({
    currentTarget
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (props.onChangeRowsPerPage) {
      props.onChangeRowsPerPage(parseInt(currentTarget.value, 10));
    }
  };

  const {
    tableHead,
    tableData,
    tableHeaderColor,
    classes,
    paginate,
    totalRows,
    totalCols,
    isFetching,
    page
  } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead && (
          <Head tableHead={tableHead} tableHeaderColor={tableHeaderColor} />
        )}
        <TableBody>
          {tableData && !isFetching ? (
            tableData.map((row, key) => {
              return (
                <Row
                  key={key}
                  rowIndex={key}
                  cellData={row.cellData}
                  hover={props.hover}
                  striped={props.striped}
                />
              );
            })
          ) : (
              <TableRow hover={props.hover}>
                <TableCell
                  colSpan={totalCols}
                  style={{
                    height: "20em",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <CircularProgress color="primary" />
                </TableCell>
              </TableRow>
            )}
        </TableBody>
        {paginate && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPage={10}
                colSpan={totalCols}
                count={totalRows || 0}
                page={page || 0}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </div>
  );
};

export default withStyles(tableStyle)(CustomTable);
