import {
  AppBar,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  WithStyles,
  withStyles
} from "@material-ui/core";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Menu from "@material-ui/icons/Menu";
// import PersonAdd from "@material-ui/icons/PersonAdd";
import cx from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { NavLink } from "react-router-dom";
import { Dispatch } from "redux";
import authNavbarStyle from "../../assets/jss/material-dashboard-pro-react/components/authNavbarStyle";
import { IRootReducer } from "../../store";
import { authNavbarOpen } from "../../store/ui/actions";
import Button from "../CustomButtons/Button";

interface IProps {
  color?: "primary" | "info" | "success" | "warning" | "danger";
  brandText: string;
}

interface IStateProps {
  drawerOpen: boolean;
}

interface IDispatchProps {
  toggleOpen: (open: boolean) => void;
}
type AllProps = IProps &
  IStateProps &
  IDispatchProps &
  RouteComponentProps<any> &
  WithStyles<typeof authNavbarStyle>;

class AuthNavbar extends React.Component<
  IProps &
    IStateProps &
    IDispatchProps &
    RouteComponentProps<any> &
    WithStyles<typeof authNavbarStyle>
> {
  public componentWillMount() {
    this.props.toggleOpen(false);
  }

  public componentDidUpdate(props: AllProps) {
    if (
      props.location &&
      props.history.location.pathname !== props.location.pathname
    ) {
      this.props.toggleOpen(false);
    }
  }

  public render() {
    const { classes, color, brandText, drawerOpen } = this.props;
    let appBarClasses;
    if (color) {
      appBarClasses = cx({
        [" " + classes[color]]: color
      });
    }

    const list = (
      <List className={classes.list}>
        {/* <ListItem className={classes.listItem}>
          <NavLink
            to="/page/register"
            className={cx(classes.navLink, {
              [classes.navLinkActive]: this.activeRoute("/page/register")
            })}
          >
            <PersonAdd className={classes.listItemIcon} />
            <ListItemText
              primary="Registreren"
              disableTypography={true}
              className={classes.listItemText}
            />
          </NavLink>
        </ListItem> */}
        <ListItem className={classes.listItem}>
          <NavLink
            to="/page/login"
            className={cx(classes.navLink, {
              [classes.navLinkActive]: this.activeRoute("/page/login")
            })}
          >
            <Fingerprint className={classes.listItemIcon} />
            <ListItemText
              primary="Login"
              disableTypography={true}
              className={classes.listItemText}
            />
          </NavLink>
        </ListItem>
      </List>
    );

    return (
      <AppBar
        position="static"
        className={classes.appBar + " " + appBarClasses}
      >
        <Toolbar className={classes.container}>
          <Hidden smDown={true}>
            <div className={classes.flex}>
              <Button href="/" className={classes.title} color="transparent">
                {brandText}
              </Button>
            </div>
          </Hidden>
          <Hidden mdUp={true}>
            <div className={classes.flex}>
              <Button href="/" className={classes.title} color="transparent">
                Glansje Dashboard
              </Button>
            </div>
          </Hidden>
          <Hidden smDown={true}>{list}</Hidden>
          <Hidden mdUp={true}>
            <Button
              className={classes.sidebarButton}
              color="transparent"
              justIcon={true}
              aria-label="open menu"
              onClick={this.handleDrawerToggle}
            >
              <Menu />
            </Button>
          </Hidden>
          <Hidden mdUp={true}>
            <Drawer
              variant="temporary"
              anchor="right"
              open={drawerOpen}
              classes={{
                paper: classes.drawerPaper
              }}
              onClose={this.handleDrawerClose}
              ModalProps={{
                keepMounted: true
              }}
            >
              {list}
            </Drawer>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }

  private activeRoute = (routeName: string) =>
    this.props.location && this.props.location.pathname.indexOf(routeName) > -1;

  private handleDrawerToggle = () => {
    this.props.toggleOpen(!this.props.drawerOpen);
  };

  private handleDrawerClose = () => {
    this.props.toggleOpen(false);
  };
}

const mapStateToProps = (state: IRootReducer): IStateProps => ({
  drawerOpen: state.ui.authNavbarOpen
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  toggleOpen: (open: boolean) => dispatch(authNavbarOpen(open))
});

export default connect<IStateProps, IDispatchProps, IProps, IRootReducer>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(authNavbarStyle)(AuthNavbar));
