import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import { ITokenResponse } from "../../../domain/responses/user/TokenResponse";
import request from "../../../util/request";
import { IUserLogoutSuccess, logoutSuccess } from "./logout";

/// Constants
export const USER_REFRESH_TOKEN_REQUEST = "@@user/REFRESH_TOKEN_REQUEST";
export const USER_REFRESH_TOKEN_SUCCESS = "@@user/REFRESH_TOKEN_SUCCESS";
export const USER_REFRESH_TOKEN_FAILURE = "@@user/REFRESH_TOKEN_FAILURE";

/// Action Interfaces
export interface IUserRefreshTokenRequest extends Action {
  type: typeof USER_REFRESH_TOKEN_REQUEST;
}
export interface IUserRefreshTokenSuccess extends Action {
  type: typeof USER_REFRESH_TOKEN_SUCCESS;
  payload: {
    token: ITokenResponse;
  };
}
export interface IUserRefreshTokenFailure extends Action {
  type: typeof USER_REFRESH_TOKEN_FAILURE;
}

export type UserRefreshTokenActions =
  | IUserRefreshTokenRequest
  | IUserRefreshTokenSuccess
  | IUserRefreshTokenFailure;

/// Actions
/**
 * Dispatchs refresh token
 */
export const refreshToken: ActionCreator<
  ThunkAction<
    void,
    IRootReducer,
    void,
    UserRefreshTokenActions | IUserLogoutSuccess
  >
> = () => async (dispatch, getState) => {
  dispatch(refreshTokenRequest());
  try {
    const res = await request(dispatch, getState).post(
      `/GlansjeUsers/me/accessTokens`
    );
    const tokenResponse: ITokenResponse = {
      ...res.body,
      created: new Date(res.body.created)
    }
    dispatch(refreshTokenSuccess(tokenResponse));
  } catch (error) {
    dispatch(refreshTokenFailure());
    dispatch(logoutSuccess());
  }
};

/**
 * Types refresh token request
 */
export const refreshTokenRequest: ActionCreator<
  IUserRefreshTokenRequest
> = () => ({
  type: USER_REFRESH_TOKEN_REQUEST
});

/**
 * Datas refresh token success
 * @param data
 */
export const refreshTokenSuccess: ActionCreator<IUserRefreshTokenSuccess> = (
  data: ITokenResponse
) => ({
  type: USER_REFRESH_TOKEN_SUCCESS,
  payload: {
    token: data
  }
});

/**
 * Errors refresh token failure
 * @param error
 */
export const refreshTokenFailure: ActionCreator<IUserRefreshTokenFailure> = () => ({
  type: USER_REFRESH_TOKEN_FAILURE,
});
