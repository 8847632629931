import { createStyles, Grid, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";

const style = createStyles({
  grid: {
    margin: "0 -15px",
    width: "calc(100% + 30px)"
  }
});

interface IProps extends WithStyles<typeof style> {
  children: React.ReactNode;
  className?: string;
  justify?:
    | "space-around"
    | "space-between"
    | "space-evenly"
    | "center"
    | "flex-end"
    | "flex-start";
  xs?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  sm?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  md?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

function GridContainer({ ...props }: IProps) {
  const { classes, children, className, ...rest } = props;

  return (
    <Grid container={true} {...rest} className={`${classes.grid} ${className}`}>
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridContainer);
