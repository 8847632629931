import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import request from "../../../util/request";

export const PROFILES_UNLINK_REQUEST = "@@profiles/UNLINK_REQUEST";
export const PROFILES_UNLINK_SUCCESS = "@@profiles/UNLINK_SUCCESS";
export const PROFILES_UNLINK_FAILURE = "@@profiles/UNLINK_FAILURE";

export interface IProfileUnlinkRequest extends Action {
  type: typeof PROFILES_UNLINK_REQUEST;
}

export interface IProfileUnlinkSuccess extends Action {
  type: typeof PROFILES_UNLINK_SUCCESS;
  payload: {
    profileId: string;
  };
}

export interface IProfileUnlinkFailure extends Action {
  type: typeof PROFILES_UNLINK_FAILURE;
}

export type ProfileUnlinkActions =
  | IProfileUnlinkRequest
  | IProfileUnlinkSuccess
  | IProfileUnlinkFailure;

export const unlinkProfile: ActionCreator<
  ThunkAction<void, IRootReducer, void, ProfileUnlinkActions>
> = (profileId: string) => async (dispatch, getState) => {
  dispatch(requestUnlinkProfile());
  try {
    await request(dispatch, getState).delete(
      `/GlansjeUsers/me/profileUsers/rel/${profileId}`
    );
    dispatch(successUnlinkProfile(profileId));
  } catch (err) {
    dispatch(failureUnlinkProfile());
  }
};

const requestUnlinkProfile: ActionCreator<IProfileUnlinkRequest> = () => ({
  type: PROFILES_UNLINK_REQUEST
});

const successUnlinkProfile: ActionCreator<IProfileUnlinkSuccess> = (
  profileId: string
) => ({
  type: PROFILES_UNLINK_SUCCESS,
  payload: {
    profileId
  }
});

const failureUnlinkProfile: ActionCreator<IProfileUnlinkFailure> = () => ({
  type: PROFILES_UNLINK_FAILURE
});
