import { IProfile, IRiskScore } from "../../domain/models/profiles";
import {
  RISK_SCORE_ADD_FAILURE,
  RISK_SCORE_ADD_REQUEST,
  RISK_SCORE_ADD_SUCCESS,
  RiskScoreAddActions
} from "./actions/addRiskScore";
import {
  AppResetActions,
  PROFILE_APP_RESET_FAILURE,
  PROFILE_APP_RESET_REQUEST,
  PROFILE_APP_RESET_SUCCESS
} from "./actions/appReset";
import {
  PROFILES_LINK_FAILURE,
  PROFILES_LINK_REQUEST,
  PROFILES_LINK_SUCCESS
} from "./actions/linkProfile";
import {
  MyProfilesActions,
  PROFILES_FETCH_FAILURE,
  PROFILES_FETCH_REQUEST,
  PROFILES_FETCH_SUCCESS
} from "./actions/myProfiles";
import {
  MyProfilesCountActions,
  PROFILES_COUNT_FAILURE,
  PROFILES_COUNT_REQUEST,
  PROFILES_COUNT_SUCCESS
} from "./actions/myProfilesCount";
import {
  ProfileDetailsActions,
  PROFILES_DETAIL_FAILURE,
  PROFILES_DETAIL_REQUEST,
  PROFILES_DETAIL_SUCCESS
} from "./actions/profileDetails";
import {
  PROFILE_FETCH_RISKS_FAILURE,
  PROFILE_FETCH_RISKS_REQUEST,
  PROFILE_FETCH_RISKS_SUCCESS,
  RiskScoresActions
} from "./actions/riskScores";
import {
  PROFILES_SEARCH_FAILURE,
  PROFILES_SEARCH_REQUEST,
  PROFILES_SEARCH_SUCCESS,
  ProfilesSearchActions
} from "./actions/searchProfiles";
import { FetchTotalProfilesActions } from "./actions/totalProfiles";
import {
  CountTotalProfilesActions,
  PROFILES_FETCH_TOTAL_COUNT_FAILURE,
  PROFILES_FETCH_TOTAL_COUNT_REQUEST,
  PROFILES_FETCH_TOTAL_COUNT_SUCCESS
} from "./actions/totalProfilesCount";
import {
  PROFILES_UNLINK_FAILURE,
  PROFILES_UNLINK_REQUEST,
  PROFILES_UNLINK_SUCCESS,
  ProfileUnlinkActions
} from "./actions/unlinkProfile";

export const initialState: IProfilesState = {
  totalProfilesCount: 0,
  totalProfiles: [],
  totalIsFetching: false,
  totalHasError: false,
  myProfiles: [],
  myProfilesCount: 0,
  isFetching: false,
  isFetchingRiskScores: false,
  hasError: false,
  isLinking: false,
  hasLinkingError: false,
  riskScores: [],
  isAddingRiskScore: false,
  addingScoreError: false,
  isRemovingProfile: false,
  removeProfileError: false,
  isSearching: false,
  hasSearchError: false,
  searchProfiles: [],
  isResettingApp: false,
  hasErrorResettingApp: false
};

export interface IProfilesState {
  readonly totalProfiles: IProfile[];
  readonly totalIsFetching: boolean;
  readonly totalHasError: boolean;
  readonly totalProfilesCount: number;
  readonly myProfiles: IProfile[];
  readonly selectedProfile?: IProfile;
  readonly myProfilesCount: number;
  readonly isFetching: boolean;
  readonly isFetchingRiskScores: boolean;
  readonly hasError: boolean;
  readonly isLinking: boolean;
  readonly hasLinkingError: boolean;
  readonly riskScores: IRiskScore[];
  readonly isAddingRiskScore: boolean;
  readonly addingScoreError: boolean;
  readonly isRemovingProfile: boolean;
  readonly removeProfileError: boolean;
  readonly isSearching: boolean;
  readonly hasSearchError: boolean;
  readonly searchProfiles: IProfile[];
  readonly isResettingApp: boolean;
  readonly hasErrorResettingApp: boolean;
}

const profilesReducer = (
  state: IProfilesState = initialState,
  action:
    | CountTotalProfilesActions
    | FetchTotalProfilesActions
    | MyProfilesActions
    | MyProfilesCountActions
    | ProfileDetailsActions
    | RiskScoresActions
    | RiskScoreAddActions
    | ProfileUnlinkActions
    | ProfilesSearchActions
    | AppResetActions
) => {
  switch (action.type) {
    case PROFILES_FETCH_TOTAL_COUNT_REQUEST:
      return { ...state, totalIsFetching: true, totalHasError: false };
    case PROFILES_FETCH_TOTAL_COUNT_SUCCESS:
      return {
        ...state,
        totalIsFetching: false,
        totalHasError: false,
        totalProfilesCount: action.payload.count
      };
    case PROFILES_FETCH_TOTAL_COUNT_FAILURE:
      return {
        ...state,
        totalIsFetching: false,
        totalHasError: true,
        totalProfilesCount: 0
      };
    case PROFILES_COUNT_REQUEST:
      return { ...state, isFetching: true, hasError: false };
    case PROFILES_COUNT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        myProfilesCount: action.payload.count
      };
    case PROFILES_COUNT_FAILURE:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        myProfilesCount: 0
      };
    case PROFILES_LINK_REQUEST:
      return { ...state, isLinking: true, hasError: false };
    case PROFILES_LINK_SUCCESS:
      return { ...state, isLinking: false, hasError: false };
    case PROFILES_LINK_FAILURE:
      return { ...state, isLinking: false, hasError: true };
    case PROFILES_FETCH_REQUEST:
      return { ...state, isFetching: true, hasError: false };
    case PROFILES_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        myProfiles: action.payload.profiles
      };
    case PROFILES_FETCH_FAILURE:
      return { ...state, isFetching: false, hasError: true, myProfiles: [] };
    case PROFILES_DETAIL_REQUEST:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        selectedProfile: undefined
      };
    case PROFILES_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        selectedProfile: action.payload.profile
      };
    case PROFILES_DETAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        selectedProfile: undefined
      };
    case PROFILE_FETCH_RISKS_REQUEST:
      return { ...state, hasError: false, isFetchingRiskScores: true };
    case PROFILE_FETCH_RISKS_SUCCESS:
      return {
        ...state,
        isFetchingRiskScores: false,
        riskScores: action.payload.riskScores
      };
    case PROFILE_FETCH_RISKS_FAILURE:
      return { ...state, isFetchingRiskScores: false, hasError: true };

    case RISK_SCORE_ADD_REQUEST:
      return { ...state, isAddingRiskScore: true };
    case RISK_SCORE_ADD_SUCCESS: {
      const riskScores = state.riskScores;
      riskScores.push(action.payload.riskScore);

      return { ...state, riskScores, isAddingRiskScore: false };
    }
    case RISK_SCORE_ADD_FAILURE:
      return { ...state, isAddingRiskScore: false, addingScoreError: true };
    case PROFILES_UNLINK_REQUEST:
      return { ...state, isRemovingProfile: true, removeProfileError: false };
    case PROFILES_UNLINK_SUCCESS: {
      return {
        ...state,
        myProfiles: state.myProfiles.filter(
          p => p.id !== action.payload.profileId
        ),
        isRemovingProfile: false,
        removeProfileError: false
      };
    }
    case PROFILES_UNLINK_FAILURE:
      return { ...state, isRemovingProfile: false, removeProfileError: true };
    case PROFILES_SEARCH_REQUEST:
      return { ...state, isSearching: true, hasSearchError: false };
    case PROFILES_SEARCH_SUCCESS:
      return {
        ...state,
        isSearching: false,
        hasSearchError: false,
        searchProfiles: action.payload.profiles
      };
    case PROFILES_SEARCH_FAILURE:
      return {
        ...state,
        isSearching: false,
        hasSearchError: true,
        searchProfiles: []
      };
    case PROFILE_APP_RESET_REQUEST:
      return { ...state, isResettingApp: true, hasErrorResettingApp: false };
    case PROFILE_APP_RESET_SUCCESS:
      const profile = state.selectedProfile!;
      profile.resetProfile = true;

      return {
        ...state,
        selectedProfile: profile,
        isResettingApp: false,
        hasErrorResettingApp: false
      };
    case PROFILE_APP_RESET_FAILURE:
      return { ...state, isResettingApp: false, hasErrorResettingApp: true };
    default:
      return state;
  }
};

export default profilesReducer;
