import * as React from "react";

import { connect } from "react-redux";
import { IRole } from "../../domain/models/user";
import { IRootReducer } from "../../store";
import { AllowedRole, checkRolePermission } from "../../util/roles_helpers";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardFooter from "../Card/CardFooter";
import CardHeader from "../Card/CardHeader";
import { CardColor } from "../Card/types";
import GridItem from "../Grid/GridItem";

interface IProps {
  allowedRoles: AllowedRole[];
  title: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
  headerColor: CardColor;
  xs?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  sm?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  md?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  lg?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  noGridItem?: boolean;
  icon?: boolean;
}

interface IState {
  roles: IRole[];
}

class Widget extends React.Component<IProps & IState> {
  public render() {
    const { allowedRoles, roles, xs, sm, md, lg, noGridItem } = this.props;
    if (!checkRolePermission(allowedRoles, roles)) {
      return null;
    }

    if (!noGridItem) {
      return (
        <GridItem xs={xs} sm={sm} md={md} lg={lg}>
          {this.renderCard()}
        </GridItem>
      );
    } else {
      return this.renderCard();
    }
  }

  private renderCard() {
    const { title, children, footer, headerColor, icon } = this.props;

    return (
      <Card>
        <CardHeader color={headerColor} icon={icon}>
          {!icon ? <h4>{title}</h4> : title}
        </CardHeader>
        <CardBody>{children}</CardBody>
        {footer && <CardFooter>{footer}</CardFooter>}
      </Card>
    );
  }
}

const mapStateToProps = (state: IRootReducer) => ({
  roles: state.user.roles,
  count: state.profiles.myProfilesCount
});

export default connect<IState, {}, IProps, IRootReducer>(mapStateToProps)(
  Widget
);
