import { ConnectedRouter } from "connected-react-router";
import * as React from "react";
import { Provider } from "react-redux";
import { Redirect, Switch } from "react-router";

import AuthenticatedRoute from "./hoc/AuthenticatedRoute";
import UnauthenticatedRoute from "./hoc/UnauthenticatedRoute";
import Admin from "./layouts/Admin";
import Pages from "./layouts/Pages";
import createStore from "./store/store";

import "./assets/scss/material-dashboard-pro-react.scss";

const { store, history } = createStore();

export const Application = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <AuthenticatedRoute path="/admin" component={Admin} />
        <UnauthenticatedRoute path="/page" component={Pages} />
        <Redirect exact={true} from="/" to="/page/login" />
      </Switch>
    </ConnectedRouter>
  </Provider>
);
