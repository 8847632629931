import { Action } from "redux";

// Action types
export const UI_SIDEBAR_MINIMIZE = "@@UI/SIDEBAR_MINIMIZE";
export const UI_MOBILE_OPEN = "@@UI/MOBILE_OPEN";
export const UI_OPEN_AUTH_NAVBAR = "@@UI/OPEN_AUTH_NAVBAR";
export const UI_TOGGLE_AVATAR_OPEN = "@@UI/TOGGLE_AVATAR_OPEN";
export const UI_SET_MINI_ACTIVE = "@@UI/SET_MINI_ACTIVE";
export const UI_SET_NOTIFICATIONS_OPEN = "@@UI/SET_NOTIFICATIONS_OPEN";
export const UI_DRAWER_TOGGLE = "@@UI/DRAWER_TOGGLE";

export interface IUIState {
  readonly miniActive: boolean;
  readonly image: typeof import("*.jpg");
  readonly color: string;
  readonly bgColor: "white" | "black" | "blue";
  readonly hasImage: boolean;
  readonly mobileOpen: boolean;
  readonly fixedClasses: string;
  readonly authNavbarOpen: boolean;
  readonly avatarOpen: boolean;
  readonly notificationsOpen: boolean;
}

export interface IUIMobileOpenInterfaceAction extends Action {
  type: typeof UI_MOBILE_OPEN;
  payload: {
    open: boolean;
  };
}

export interface IUISidebarMinimizeAction extends Action {
  type: typeof UI_SIDEBAR_MINIMIZE;
}

export interface IUIOpenAuthNavbarAction extends Action {
  type: typeof UI_OPEN_AUTH_NAVBAR;
  payload: {
    open: boolean;
  };
}

export interface IUIToggleAvatarAction extends Action {
  type: typeof UI_TOGGLE_AVATAR_OPEN;
}

export interface IUISetMiniActive extends Action {
  type: typeof UI_SET_MINI_ACTIVE;
  payload: {
    active: boolean;
  };
}

export interface IUISetNotificationsOpen extends Action {
  type: typeof UI_SET_NOTIFICATIONS_OPEN;
  payload: {
    open: boolean;
  };
}

export type UIPageActions = IUIOpenAuthNavbarAction;

export type UISidebarActions =
  | IUIToggleAvatarAction
  | IUISetMiniActive
  | IUISetNotificationsOpen;

export type UIActions =
  | IUIMobileOpenInterfaceAction
  | IUISidebarMinimizeAction
  | UIPageActions
  | UISidebarActions;
