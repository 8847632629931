import { ISnack, ISnackAddAction, ISnackRemoveAction, SNACK_ADD_ACTION, SNACK_REMOVE_ACTION } from "./actions";

export interface ISnacksState {
    readonly snacks: ISnack[]
}

export const initialState: ISnacksState = {
    snacks: []
};

export const snacksReducer = (state: ISnacksState = initialState, action: ISnackAddAction | ISnackRemoveAction) => {
    switch (action.type) {
        case SNACK_ADD_ACTION: {
            const snacks = state.snacks;
            snacks.push(action.payload);
            return { ...state, snacks };
        }
        case SNACK_REMOVE_ACTION: {
            const snacks = state.snacks;
            snacks.splice(action.payload, 1);
            return { ...state, snacks };
        }
    }
}