import {
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemText,
  WithStyles,
  withStyles
} from "@material-ui/core";
import classNames from "classnames";
import { RouterState } from "connected-react-router";
import { IRole } from "domain/models/user";
import * as React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Dispatch } from "redux";
import { checkRolePermission } from "util/roles_helpers";
import sidebarStyle from "../../assets/jss/material-dashboard-pro-react/components/sidebarStyle";
import { adminRoutes as routes } from "../../routes";
import { IDashboardRoute } from "../../routes";
import { IRootReducer } from "../../store";
import { mobileOpen, setMiniActive } from "../../store/ui/actions";
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks";
import SidebarBrand from "./SidebarBrand";
import SidebarUser from "./SidebarUser";
import SidebarWrapper from "./SidebarWrapper";

interface IProps {
  bgColor: "white" | "black" | "blue";
  color: "white" | "red" | "orange" | "green" | "blue" | "purple" | "rose";
  logo: string;
  logoText: string;
  image?: typeof import("*.jpg");
}

interface IState {
  router: RouterState;
  miniActive: boolean;
  open: boolean;
  name: string;
  roles: IRole[];
}

interface IDispatch {
  // handleDrawerToggle: () => void;

  setMiniActive: (active: boolean) => void;
  handleDrawerToggle: (open: boolean) => void;
}

class Sidebar extends React.Component<
  IProps & IState & IDispatch & WithStyles<typeof sidebarStyle>
  > {
  public static defaultProps: Pick<IProps, "bgColor" | "color"> = {
    color: "orange",
    bgColor: "blue"
  };

  public render() {
    const { classes, miniActive, bgColor, image } = this.props;

    const drawerPaper = classNames({
      [classes.drawerPaper]: true,
      [classes.drawerPaperMini]: miniActive
    });

    const sidebarWrapper = classNames({
      [classes.sidebarWrapper]: true,
      [classes.drawerPaperMini]: miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]: true
    });
    const colorClasses = classNames({
      [classes.blackBackground]: bgColor === "black",
      [classes.blueBackground]: bgColor === "blue",
      [classes.whiteBackground]: bgColor === "white"
    });

    return (
      <div>
        <Hidden mdUp={true} implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={this.props.open}
            classes={{
              paper: drawerPaper
            }}
            onClose={this.handleDrawerToggle}
            ModalProps={{
              keepMounted: true
            }}
          >
            <SidebarBrand
              bgColor={bgColor}
              logo={this.props.logo}
              logoText={this.props.logoText}
            />
            <SidebarWrapper
              className={sidebarWrapper}
              user={<SidebarUser bgColor={bgColor} />}
              headerLinks={<AdminNavbarLinks />}
              links={
                <List className={classes.list}>{this.createLinks(routes)}</List>
              }
            />
            {image && (
              <div
                className={classes.background}
                style={{ backgroundImage: `url(${image})` }}
              />
            )}
          </Drawer>
        </Hidden>
        <Hidden smDown={true} implementation="css">
          <Drawer
            onMouseOver={this.setMiniInactive}
            onMouseOut={this.setMiniActive}
            anchor="left"
            variant="permanent"
            open={this.props.open}
            classes={{
              paper: `${drawerPaper} ${colorClasses}`
            }}
          >
            <SidebarBrand
              bgColor={bgColor}
              logo={this.props.logo}
              logoText={this.props.logoText}
            />
            <SidebarWrapper
              className={sidebarWrapper}
              user={<SidebarUser bgColor={bgColor} />}
              links={
                <List className={classes.list}>{this.createLinks(routes)}</List>
              }
            />
            {image && (
              <div
                className={classes.background}
                style={{ backgroundImage: `url(${image})` }}
              />
            )}
          </Drawer>
        </Hidden>
      </div>
    );
  }

  private activeRoute = (routeName: string) => {
    return this.props.router.location.pathname.indexOf(routeName) > -1
      ? "active"
      : "";
  };

  private createLinks = (dashRoutes: IDashboardRoute[]) => {
    const { classes, color, miniActive } = this.props;
    return dashRoutes.map((route, key) => {
      if (route.redirect || route.notInMenu) {
        return null;
      }

      if (route.allowedRoles) {
        if (!checkRolePermission(route.allowedRoles, this.props.roles)) {
          return null;
        }
      }
      // TODO support collapsing routes

      const innerNavLinkClasses = classNames({
        [classes.collapseItemLink]: true,
        [classes[route.color || color]]: this.activeRoute(route.path)
      });

      const collapseItemMini = classNames({
        [classes.collapseItemMini]: true
      });

      const navLinkClasses = classNames({
        [classes.itemLink]: true,
        [classes[route.color || color]]: this.activeRoute(route.path)
      });

      const itemText = classNames({
        [classes.itemText]: true,
        [classes.itemTextMini]: miniActive
      });

      const collapseItemText = classNames({
        [classes.collapseItemText]: true,
        [classes.collapseItemTextMini]: miniActive
      });

      const itemIcon = classes.itemIcon;

      return (
        <ListItem
          key={key}
          className={classNames({
            [classes.item]: route.icon !== undefined,
            [classes.collapseItem]: route.icon === undefined
          })}
        >
          <NavLink
            to={`${route.layout}${route.path}`}
            className={classNames({
              [navLinkClasses]: route.icon !== undefined,
              [innerNavLinkClasses]: route.icon === undefined
            })}
          >
            {route.icon ? (
              <route.icon className={itemIcon} />
            ) : (
                <span className={collapseItemMini}>{route.mini}</span>
              )}
            <ListItemText
              primary={route.name}
              disableTypography={true}
              className={classNames({
                [itemText]: route.icon !== undefined,
                [collapseItemText]: route.icon === undefined
              })}
            />
          </NavLink>
        </ListItem>
      );
    });
  };

  private handleDrawerToggle = () => {
    this.props.handleDrawerToggle(!this.props.open);
  };

  private setMiniActive = () => {
    this.props.setMiniActive(true);
  };

  private setMiniInactive = () => {
    this.props.setMiniActive(false);
  };
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatch => ({
  setMiniActive: (active: boolean) => dispatch(setMiniActive(active)),
  handleDrawerToggle: (open: boolean) => dispatch(mobileOpen(open))
});

const mapStateToProps = (state: IRootReducer): IState => ({
  name: state.user.user ? state.user.user.name : "",
  roles: state.user.roles,
  router: state.router,
  miniActive: state.ui.miniActive,
  open: state.ui.mobileOpen
});

export default connect<IState, IDispatch, IProps, IRootReducer>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(sidebarStyle)(Sidebar));
