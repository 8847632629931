import { WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { RouteComponentProps, Switch } from "react-router";

import pagesStyle from "../assets/jss/material-dashboard-pro-react/layouts/authStyle";
import Footer from "../components/Footer/Footer";
import AuthNavbar from "../components/Navbars/AuthNavbar";
import routes from "../routes";
import { getActiveRoute, getRoutes } from "../util/route_helpers";

import bg from "../assets/img/Glansje_Onsverhaal_beeld.jpg";

interface IProps extends RouteComponentProps, WithStyles<typeof pagesStyle> {}
class Pages extends React.Component<IProps> {
  public render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <AuthNavbar
          brandText={getActiveRoute(window.location.href, routes.pageRoutes)}
          {...rest}
        />
        <div className={classes.wrapper}>
          <div
            className={classes.fullPage}
            style={{
              backgroundImage: `url(${bg})`
            }}
          >
            <Switch>{getRoutes("/page", routes.pageRoutes)}</Switch>
            <Footer fluid={false} white={true} />
          </div>
        </div>
      </div>
    );
  }

  public componentDidMount() {
    // TODO seems a bit hacky
    document.body.style.overflow = "unset";
  }
}

export default withStyles(pagesStyle)(Pages);
