import PlusIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import { InjectedFormikProps, withFormik } from "formik";
import * as React from "react";
import AddRiskScoreForm, {
  IFormProps,
  IFormValues,
  validationSchema
} from "../../components/forms/AddRiskScoreForm";

import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { cardTitle } from "../../assets/jss/material-dashboard-pro-react";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import { IRiskScoreMessage } from "../../domain/requests/AddRiskScore";
import { IRootReducer } from "../../store";
import {
  addRiskScore,
  RiskScoreAddActions
} from "../../store/profiles/actions/addRiskScore";

const style = createStyles({
  cardIconTitleMain: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    textAlign: "left"
  }
});

interface IState {
  isAdding: boolean;
  profileId: string;
}

interface IDispatch {
  submitRiskScore: (profileId: string, riskScore: IRiskScoreMessage) => void;
}
class AddRiskScore extends React.Component<
  InjectedFormikProps<IFormProps, IFormValues> &
    WithStyles<typeof style> &
    IState &
    IDispatch
> {
  public render() {
    const { ...rest } = this.props;
    return (
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Card>
          <CardHeader color="success" icon={true}>
            <CardIcon color="success">
              <PlusIcon />
            </CardIcon>
            <h4 className={this.props.classes.cardIconTitleMain}>
              NOCTP-score toevoegen
            </h4>
          </CardHeader>
          <CardBody>
            <AddRiskScoreForm {...rest} />
          </CardBody>
          <CardFooter>
            <Button
              color="danger"
              onClick={this.props.cancel}
              disabled={this.props.dirty || this.props.isSubmitting}
            >
              <CancelIcon /> Annuleren
            </Button>
            <Button
              color="success"
              onClick={this.props.submitForm}
              disabled={
                !this.props.dirty ||
                this.props.isUpdating ||
                !!Object.keys(this.props.errors).length
              }
            >
              <SaveIcon /> Opslaan
            </Button>
          </CardFooter>
        </Card>
      </GridItem>
    );
  }
}
const mapStateToProps = (state: IRootReducer): IState => ({
  isAdding: state.profiles.isAddingRiskScore,
  profileId: state.profiles.selectedProfile
    ? state.profiles.selectedProfile.id
    : ""
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootReducer, void, RiskScoreAddActions>
): IDispatch => ({
  submitRiskScore: (profileId, riskScore: IRiskScoreMessage) =>
    dispatch(addRiskScore(profileId, riskScore))
});

const EnhancedAddRiskScore = withFormik<
  IFormProps & IState & IDispatch,
  IFormValues
>({
  validationSchema,
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    props.submitRiskScore(props.profileId, {
      ri1: parseInt(values.ri1!, 10),
      ri2: parseInt(values.ri2!, 10),
      ri3: parseInt(values.ri3!, 10),
      ri4: parseInt(values.ri4!, 10),
      ci1: parseInt(values.ci1!, 10),
      ci2: parseInt(values.ci2!, 10),
      ci3: parseInt(values.ci3!, 10),
      ci4: parseInt(values.ci4!, 10),
      ci5: parseInt(values.ci5!, 10),
      ci6: parseInt(values.ci6!, 10),
      message: values.message!
    });
    setSubmitting(false);
    resetForm({});
    props.cancel();
  }
})(withStyles(style)(AddRiskScore));

export default connect<IState, IDispatch, {}, IRootReducer>(
  mapStateToProps,
  mapDispatchToProps
)(EnhancedAddRiskScore);
