import { createStyles } from "@material-ui/core";
import {
  blackColor,
  dangerColor,
  grayColor,
  hexToRgb,
  orangeColor,
  primaryBoxShadow,
  successColor,
  whiteColor
} from "../../material-dashboard-pro-react";

const customSelectStyle = createStyles({
  disabled: {
    "&:before": {
      borderColor: "transparent !important"
    }
  },
  select: {
    padding: "12px 0 7px",
    fontSize: ".75rem",
    fontWeight: 400,
    lineHeight: 1.42857,
    textDecoration: "none",
    textTransform: "uppercase",
    color: grayColor[2],
    letterSpacing: 0,
    "&:focus": {
      backgroundColor: "transparent"
    },
    "&[aria-owns] + input + svg": {
      transform: "rotate(180deg)"
    },
    "& + input + svg": {
      transition: "all 300ms linear"
    }
  },
  selectFormControl: {
    margin: "7px 0 17px 0 !important",
    "& > div": {
      "&:before": {
        borderBottomWidth: "1px !important",
        borderBottomColor: `${grayColor[4]} !important`
      },
      "&:after": {
        borderBottomColor: `${orangeColor[0]} !important`
      }
    }
  },
  selectLabel: {
    fontSize: "12px",
    textTransform: "uppercase",
    color: `${grayColor[2]} !important`,
    top: "8px"
  },
  selectLabelError: {
    color: `${dangerColor[0]} !important`
  },
  selectLabelSuccess: {
    color: `${successColor[0]} !important`
  },
  selectMenu: {
    "& > div > ul": {
      border: 0,
      padding: "5px 0",
      margin: 0,
      boxShadow: "none",
      minWidth: "100%",
      borderRadius: "4px",
      boxSizing: "border-box",
      display: "block",
      fontSize: "14px",
      textAlign: "left",
      listStyle: "none",
      backgroundColor: whiteColor,
      backgroundClip: "padding-box"
    },
    "& $selectPaper $selectMenuItemSelectedMultiple": {
      backgroundColor: "inherit"
    },
    "& > div + div": {
      maxHeight: "266px !important"
    }
  },
  selectMenuItem: {
    fontSize: "13px",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "2px",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: 400,
    lineHeight: 2,
    whiteSpace: "nowrap",
    color: grayColor[7],
    paddingRight: "30px",
    "&:hover": {
      backgroundColor: orangeColor[0],
      color: whiteColor,
      ...primaryBoxShadow
    }
  },
  selectMenuItemSelected: {
    backgroundColor: `${orangeColor[0]} !important`,
    color: whiteColor
  },
  selectMenuItemSelectedMultiple: {
    backgroundColor: "transparent !important",
    "&:hover": {
      backgroundColor: `${orangeColor[0]} !important`,
      color: whiteColor,
      ...primaryBoxShadow,
      "&:after": {
        color: whiteColor
      }
    },
    "&:after": {
      top: "16px",
      right: "12px",
      width: "12px",
      height: "5px",
      borderLeft: "2px solid currentColor",
      transform: "rotate(-45deg)",
      opacity: 1,
      color: grayColor[2],
      position: "absolute",
      content: "''",
      borderBottom: "2px solid currentColor",
      transition: "opacity 90ms cubic-bezier(0,0,.2,.1)"
    }
  },
  selectPaper: {
    boxSizing: "border-box",
    borderRadius: "4px",
    padding: "0",
    minWidth: "100%",
    display: "block",
    border: 0,
    boxShadow: `0 2px 5px 0 rgba(${hexToRgb(blackColor)}, 0.26)`,
    backgroundClip: "padding-box",
    margin: "2px 0 0",
    fontSize: "14px",
    textAlign: "left",
    listStyle: "none",
    backgroundColor: "transparent",
    maxHeight: "266px"
  }
});

export default customSelectStyle;
