import { ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import { IProfile } from "../../../domain/models/profiles";
import { IProfileMappingResponse } from "../../../domain/responses/profiles/ProfileMappingResponse";
import request, { setFilter } from "../../../util/request";
import { LinkProfileActions } from "./linkProfile";
import { MyProfilesCountActions } from "./myProfilesCount";

/// Constants
export const PROFILES_FETCH_REQUEST = "@@profiles/FETCH_REQUEST";
export const PROFILES_FETCH_SUCCESS = "@@profiles/FETCH_SUCCESS";
export const PROFILES_FETCH_FAILURE = "@@profiles/FETCH_FAILURE";

/// Action definitions
export interface IProfilesFetchRequest {
  type: typeof PROFILES_FETCH_REQUEST;
}

export interface IProfilesFetchSuccess {
  type: typeof PROFILES_FETCH_SUCCESS;
  payload: {
    profiles: IProfile[];
  };
}

export interface IProfilesFetchFailure {
  type: typeof PROFILES_FETCH_FAILURE;
}

export type FetchProfilesActions =
  | IProfilesFetchRequest
  | IProfilesFetchSuccess
  | IProfilesFetchFailure;

export type MyProfilesActions =
  | FetchProfilesActions
  | LinkProfileActions
  | MyProfilesCountActions;

export const fetchProfiles: ActionCreator<
  ThunkAction<void, IRootReducer, void, FetchProfilesActions>
> = (page: number = 0) => async (dispatch, getState) => {
  dispatch(requestFetchProfiles());
  try {
    const skip = page * 25;
    const response = await request(dispatch, getState).get(
      `/GlansjeUsers/me/profileMappings${setFilter({
        skip,
        limit: 25,
        include: ["profileUser"]
      })}`
    );

    const profiles: IProfile[] = response.body.map(
      (profileMapping: IProfileMappingResponse) => ({
        created: new Date(profileMapping.profileUser.created),
        modified: new Date(profileMapping.profileUser.modified),
        username: profileMapping.profileUser.username,
        id: profileMapping.profileUser.id,
        shortId: profileMapping.profileUser.shortId,
        reference: profileMapping.reference,
        resetProfile: profileMapping.profileUser.resetProfile,
        score: profileMapping.profileUser.Profile.Levels.map(l => l.QuestionScore).reduce((a, b) => a + b),
      }));

    dispatch(successFetchProfiles(profiles));
  } catch (err) {
    dispatch(failureFetchProfiles());
  }
};

const requestFetchProfiles: ActionCreator<IProfilesFetchRequest> = () => ({
  type: PROFILES_FETCH_REQUEST
});

const successFetchProfiles: ActionCreator<IProfilesFetchSuccess> = (
  profiles: IProfile[]
) => ({
  type: PROFILES_FETCH_SUCCESS,
  payload: {
    profiles
  }
});

const failureFetchProfiles: ActionCreator<IProfilesFetchFailure> = () => ({
  type: PROFILES_FETCH_FAILURE
});
