import {
  FormHelperText,
  InputLabel,
  WithStyles,
  withStyles
} from "@material-ui/core";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import { FormLabelProps } from "@material-ui/core/FormLabel/FormLabel";
import Input, { InputProps } from "@material-ui/core/Input/Input";
import classNames from "classnames";
import * as React from "react";

import customInputStyles from "../../assets/jss/material-dashboard-pro-react/components/customInputStyle";

interface IProps extends WithStyles<typeof customInputStyles> {
  labelText?: React.ReactNode;
  labelProps?: FormLabelProps;
  id: string;
  inputProps?: InputProps;
  formControlProps?: FormControlProps;
  inputRootCustomClasses?: string;
  error?: boolean;
  success?: boolean;
  white?: boolean;
  helpText?: React.ReactNode;
}

function CustomInput({ ...props }: IProps) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helpText
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });

  const underlineClasses = classNames({
    [classes.underlineError]: !!error,
    [classes.underlineSuccess]: !!success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: !!white
  });

  const marginTop = inputRootCustomClasses;

  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: !!white
  });

  const formControlClasses = formControlProps
    ? classNames(formControlProps.className, classes.formControl)
    : classes.formControl;

  const helpTextClasses = classNames({
    [classes.labelRootError]: !!error,
    [classes.labelRootSuccess]: !!success && !error
  });

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText && (
        <InputLabel
          className={`${classes.labelRoot} ${labelClasses}`}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      )}
      <Input
        classes={{
          input: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        id={id}
        {...inputProps}
      />
      {helpText && (
        <FormHelperText id={`${id}-text`} className={helpTextClasses}>
          {helpText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default withStyles(customInputStyles)(CustomInput);
