import * as React from "react";

import {
  ClickAwayListener,
  Grow,
  Hidden,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  WithStyles,
  withStyles
} from "@material-ui/core";
import Notifications from "@material-ui/icons/Notifications";
import classNames from "classnames";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import adminNavbarLinksStyle from "../../assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle";
import { INotification } from "../../domain/models/notifications";
import { IRootReducer } from "../../store";
import {
  countNotifications,
  NotificationCountActions
} from "../../store/notifications/actions/count";
import {
  fetchNotifications,
  NotificationFetchActions
} from "../../store/notifications/actions/fetch";
import { setNotificationsOpen } from "../../store/ui/actions";
import { IUISetNotificationsOpen } from "../../store/ui/types";
import Button from "../CustomButtons/Button";

interface IState {
  open: boolean;
  count: number;
  notifications: INotification[];
  isFetchingCount: boolean;
  isFetching: boolean;
  hasError: boolean;
}
interface IDispatch {
  setOpen: (open: boolean) => void;
  fetchNotificationsCount: () => void;
  fetchNotifications: () => void;
}

class AdminNabarNotifications extends React.Component<
  IState & IDispatch & WithStyles<typeof adminNavbarLinksStyle>
> {
  private buttonRef: HTMLElement | null = null;

  public componentWillMount() {
    this.props.fetchNotificationsCount();
  }

  public render() {
    const { classes, open, isFetchingCount, hasError, count } = this.props;

    return (
      <div className={classes.managerClasses}>
        <Button
          color="transparent"
          justIcon={true}
          aria-label="Notificaties"
          aria-owns={open ? "menu-list" : null}
          aria-haspopup="true"
          onClick={this.onClick}
          buttonRef={this.setButtonRef}
        >
          <Notifications
            className={`${classes.headerLinksSvg} ${classes.links}`}
          />
          {!isFetchingCount && !hasError && (
            <span className={classes.notifications}>{count}</span>
          )}
          <Hidden mdUp={true} implementation="css">
            <span onClick={this.onClick} className={classes.linkText}>
              Notificaties
            </span>
          </Hidden>
        </Button>
        <Popper
          open={open}
          anchorEl={this.buttonRef}
          transition={true}
          disablePortal={true}
          className={classNames({
            [classes.popperClose]: !open,
            [classes.pooperResponsive]: true,
            [classes.pooperNav]: true
          })}
        >
          {this.growNotificationList()}
        </Popper>
      </div>
    );
  }

  private growNotificationList = () => {
    const { classes } = this.props;
    return (
      <Grow
        // id="menu-list"
        style={{ transformOrigin: "0 0 0" }}
      >
        <Paper className={classes.dropdown}>
          <ClickAwayListener onClickAway={this.onClose}>
            <MenuList role="menu">{this.notificationList()}</MenuList>
          </ClickAwayListener>
        </Paper>
      </Grow>
    );
  };

  private notificationList = () => {
    const { classes, notifications, isFetching, hasError } = this.props;
    const dropdownItemClasses = `${classes.dropdownItem} ${
      classes.primaryHover
    }`;

    if (isFetching) {
      return (
        <MenuItem className={dropdownItemClasses}>
          Bezig met ophalen van gegevens...
        </MenuItem>
      );
    }
    if (hasError) {
      return (
        <MenuItem className={dropdownItemClasses} onClick={this.onClose}>
          Er is iets mis gegaan...
        </MenuItem>
      );
    }

    return notifications.map((notification, index) => {
      return (
        <MenuItem
          key={index}
          onClick={this.onClose}
          className={dropdownItemClasses}
        >
          {notification.message}
        </MenuItem>
      );
    });
  };

  private setButtonRef = (ref: HTMLElement) => {
    this.buttonRef = ref;
  };

  private onClick = () => {
    this.props.setOpen(!this.props.open);
    this.props.fetchNotifications();
  };

  private onClose = () => {
    this.props.setOpen(false);
  };
}

const mapStateToProps = (state: IRootReducer): IState => ({
  open: state.ui.notificationsOpen,
  count: state.notifications.count,
  notifications: state.notifications.notifications,
  isFetchingCount: state.notifications.isFetchingCount,
  isFetching: state.notifications.isFetching,
  hasError: state.notifications.hasError
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    IRootReducer,
    void,
    | NotificationCountActions
    | NotificationFetchActions
    | IUISetNotificationsOpen
  >
): IDispatch => ({
  setOpen: (open: boolean) => dispatch(setNotificationsOpen(open)),
  fetchNotificationsCount: () => dispatch(countNotifications()),
  fetchNotifications: () => dispatch(fetchNotifications())
});

export default connect<IState, IDispatch, {}, IRootReducer>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(adminNavbarLinksStyle)(AdminNabarNotifications));
