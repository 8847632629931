import {
  TableCell,
  TableHead,
  TableRow,
  WithStyles,
  withStyles
} from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";

import tableStyle from "../../assets/jss/material-dashboard-pro-react/components/tableStyle";

export type TableHeaderColor =
  | "warning"
  | "primary"
  | "danger"
  | "success"
  | "info"
  | "rose"
  | "orange"
  | "gray";

interface IProps {
  tableHeaderColor?: TableHeaderColor;
  tableHead: ITableHeadCell[];
}

export interface ITableHeadCell {
  header: React.ReactNode;
  customClass?: string;
}

const CustomTableHead = ({
  ...props
}: IProps & WithStyles<typeof tableStyle>) => {
  const { tableHead, classes } = props;
  let tableHeaderClassName = classes.gray;
  switch (props.tableHeaderColor) {
    case "danger":
      tableHeaderClassName = classes.danger;
      break;
    case "info":
      tableHeaderClassName = classes.info;
      break;
    case "orange":
      tableHeaderClassName = classes.orange;
      break;
    case "primary":
      tableHeaderClassName = classes.primary;
      break;
    case "rose":
      tableHeaderClassName = classes.rose;
      break;
    case "success":
      tableHeaderClassName = classes.success;
      break;
    case "warning":
      tableHeaderClassName = classes.warning;
      break;
  }

  return (
    <TableHead className={tableHeaderClassName}>
      <TableRow className={classes.tableRow}>
        {tableHead.map((header, key) => {
          const tableClasses = classNames({
            [classes.tableHeadCell]: true,
            [classes.tableCell]: true,
            [classes.tableHeadFontSize]: true
          });

          return (
            <TableCell
              className={`${tableClasses} ${header.customClass}`}
              key={key}
            >
              {header.header}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default withStyles(tableStyle)(CustomTableHead);
