import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  WithStyles,
  withStyles
} from "@material-ui/core";
// import EditIcon from "@material-ui/icons/Edit";
// import UserIcon from "@material-ui/icons/Person";
import LogoutIcon from "@material-ui/icons/PowerSettingsNew";
// import SettingsIcon from "@material-ui/icons/Settings";
import cx from "classnames";
import * as React from "react";
import Initicon from "react-initicon";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import sidebarStyle from "../../assets/jss/material-dashboard-pro-react/components/sidebarStyle";
import { IUser } from "../../domain/models/user";
import { IRootReducer } from "../../store";
import { toggleAvatarOpen } from "../../store/ui/actions";
import { IUIToggleAvatarAction, UIActions } from "../../store/ui/types";
import { logout } from "../../store/user/actions/logout";

interface IProps {
  className?: string;
  avatar?: string;
  bgColor?: "white" | "black" | "blue";
}

interface IDispatch {
  logout: () => void;

  toggleAvatarOpen: () => void;
}

interface IState {
  user: IUser;
  miniActive: boolean;
  avatarOpen: boolean;
}

class SidebarUser extends React.Component<
  IState & IProps & IDispatch & WithStyles<typeof sidebarStyle>
> {
  public render() {
    const {
      classes,
      className,
      avatar,
      bgColor,
      user,
      miniActive
    } = this.props;

    // Define classes
    const userWrapperClass = `${classes.user} ${cx({
      [classes.whiteAfter]: bgColor === "white"
    })}`;
    const itemText = `${classes.itemText} ${cx({
      [classes.itemTextMini]: miniActive
    })}`;
    const collapseItemText = `${classes.collapseItemText} ${cx({
      [classes.collapseItemTextMini]: miniActive
    })}`;

    return (
      <div className={`${userWrapperClass} ${className}`}>
        <div className={classes.photo}>
          {avatar ? (
            <img
              src={avatar}
              className={classes.avatarImg}
              alt={user ? user.name : ""}
            />
          ) : (
            <Initicon
              size={34}
              seed={32}
              single={false}
              text={user ? user.name : "user"}
            />
          )}
        </div>
        <List className={classes.list}>
          <ListItem className={`${classes.item} ${classes.userItem}`}>
            <NavLink
              to={"#"}
              className={`${classes.itemLink} ${classes.userCollapseButton}`}
              onClick={this.props.toggleAvatarOpen}
            >
              <ListItemText
                primary={user ? user.name : ""}
                secondary={
                  <b className={`${classes.caret} ${classes.userCaret}`} />
                }
                disableTypography={true}
                className={`${itemText} ${classes.userItemText}`}
              />
            </NavLink>
            <Collapse in={this.props.avatarOpen} unmountOnExit={true}>
              <List className={`${classes.list} ${classes.collapseList}`}>
                {/* <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="/admin/my-profile"
                    className={`${classes.itemLink} ${
                      classes.userCollapseLinks
                    }`}
                  >
                    <span className={classes.collapseItemMini}>
                      <UserIcon />
                    </span>
                    <ListItemText
                      primary="Mijn profiel"
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="/admin/edit-profile"
                    className={`${classes.itemLink} ${
                      classes.userCollapseLinks
                    }`}
                  >
                    <span className={classes.collapseItemMini}>
                      <EditIcon />
                    </span>
                    <ListItemText
                      primary="Bewerk profiel"
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="/admin/settings"
                    className={`${classes.itemLink} ${
                      classes.userCollapseLinks
                    }`}
                  >
                    <span className={classes.collapseItemMini}>
                      <SettingsIcon />
                    </span>
                    <ListItemText
                      primary="Configuratie"
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem> */}
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={`${classes.itemLink} ${
                      classes.userCollapseLinks
                    }`}
                    onClick={this.props.logout}
                  >
                    <span className={classes.collapseItemMini}>
                      <LogoutIcon />
                    </span>
                    <ListItemText
                      primary="Uitloggen"
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    );
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootReducer, void, UIActions | IUIToggleAvatarAction>
) => ({
  toggleAvatarOpen: () => dispatch(toggleAvatarOpen()),
  logout: () => dispatch(logout())
});

const mapStateToProps = (state: IRootReducer): IState => ({
  user: state.user.user!,
  miniActive: state.ui.miniActive,
  avatarOpen: state.ui.avatarOpen
});

export default connect<IState, IDispatch, IProps, IRootReducer>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(sidebarStyle)(SidebarUser));
