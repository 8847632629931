import { CallHistoryMethodAction, push } from "connected-react-router";
import { ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import { IProfile } from "../../../domain/models/profiles";
import request, { setFilter } from "../../../util/request";

///  Constants
export const PROFILES_LINK_REQUEST = "@@profiles/LINK_REQUEST";
export const PROFILES_LINK_SUCCESS = "@@profiles/LINK_SUCCESS";
export const PROFILES_LINK_FAILURE = "@@profiles/LINK_FAILURE";

/// Action definitions
export interface ILinkProfileRequest {
  type: typeof PROFILES_LINK_REQUEST;
}

export interface ILinkProfileSuccess {
  type: typeof PROFILES_LINK_SUCCESS;
  payload: {
    profileId: string;
  };
}

export interface ILinkProfileFailure {
  type: typeof PROFILES_LINK_FAILURE;
}
export type LinkProfileActions =
  | ILinkProfileRequest
  | ILinkProfileSuccess
  | ILinkProfileFailure;

/// Actions
export const linkProfile: ActionCreator<
  ThunkAction<
    void,
    IRootReducer,
    void,
    LinkProfileActions | CallHistoryMethodAction<[string, any?]>
  >
> = (shortCode: string, clientReference: string) => async (
  dispatch,
  getState
) => {
  dispatch(requestLinkProfile());
  try {
    const searchResponse = await request(dispatch, getState).get(
      `/ProfileUsers${setFilter({
        where: { shortId: shortCode },
        limit: 1
      })}`
    );

    if (searchResponse.body.length < 1) {
      dispatch(failureLinkProfile());
    } else if (searchResponse.body.length > 1) {
      dispatch(failureLinkProfile());
    } else {
      const client = searchResponse.body[0] as IProfile;
      try {
        await request(dispatch, getState)
          .put(`/GlansjeUsers/me/profileUsers/rel/${client.id}`)
          .send({ reference: clientReference });

        dispatch(successLinkProfile(client.id));
        dispatch(push(`/admin/client/edit/${client.id}`));
      } catch (err) {
        dispatch(failureLinkProfile());
      }
    }
  } catch (err) {
    dispatch(failureLinkProfile());
  }
};

const requestLinkProfile: ActionCreator<ILinkProfileRequest> = () => ({
  type: PROFILES_LINK_REQUEST
});

const successLinkProfile: ActionCreator<ILinkProfileSuccess> = (
  profileId: string
) => ({
  type: PROFILES_LINK_SUCCESS,
  payload: {
    profileId
  }
});

const failureLinkProfile: ActionCreator<ILinkProfileFailure> = () => ({
  type: PROFILES_LINK_FAILURE
});
