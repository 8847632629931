import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import dashboardUserReducer from "./dashboardUsers/reducer";
import { IDashboardUsersState } from "./dashboardUsers/reducer";
import notificationsReducer, {
  INotificationState
} from "./notifications/reducer";
import profilesReducer, { IProfilesState } from "./profiles/reducer";
import { ISnacksState } from "./snacks/reducer";
import uiReducer from "./ui/reducer";
import { IUIState } from "./ui/types";
import userReducer, { IUserState } from "./user/reducer";

export interface IRootReducer {
  ui: IUIState;
  user: IUserState;
  notifications: INotificationState;
  profiles: IProfilesState;
  dashboardUsers: IDashboardUsersState;
  snacks: ISnacksState;
  router: RouterState;
}

export default function rootReducer(history: History) {
  return combineReducers({
    router: connectRouter(history),
    user: userReducer,
    notifications: notificationsReducer,
    ui: uiReducer,
    dashboardUsers: dashboardUserReducer,
    profiles: profilesReducer
  });
}
