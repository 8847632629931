import { ActionCreator } from "redux";

import {
  IUIMobileOpenInterfaceAction,
  IUIOpenAuthNavbarAction,
  IUISetMiniActive,
  IUISetNotificationsOpen,
  IUISidebarMinimizeAction,
  IUIToggleAvatarAction,
  UI_MOBILE_OPEN,
  UI_OPEN_AUTH_NAVBAR,
  UI_SET_MINI_ACTIVE,
  UI_SET_NOTIFICATIONS_OPEN,
  UI_SIDEBAR_MINIMIZE,
  UI_TOGGLE_AVATAR_OPEN
} from "./types";

export const sidebarMinize: ActionCreator<IUISidebarMinimizeAction> = () => ({
  type: UI_SIDEBAR_MINIMIZE
});

export const mobileOpen: ActionCreator<IUIMobileOpenInterfaceAction> = (
  open: boolean = false
) => ({
  type: UI_MOBILE_OPEN,
  payload: {
    open
  }
});

export const authNavbarOpen: ActionCreator<IUIOpenAuthNavbarAction> = (
  open: boolean
) => ({
  type: UI_OPEN_AUTH_NAVBAR,
  payload: {
    open
  }
});

export const toggleAvatarOpen: ActionCreator<IUIToggleAvatarAction> = () => ({
  type: UI_TOGGLE_AVATAR_OPEN
});

export const setMiniActive: ActionCreator<IUISetMiniActive> = (
  active: boolean
) => ({
  type: UI_SET_MINI_ACTIVE,
  payload: {
    active
  }
});

export const setNotificationsOpen: ActionCreator<IUISetNotificationsOpen> = (
  open: boolean
) => ({
  type: UI_SET_NOTIFICATIONS_OPEN,
  payload: {
    open
  }
});
