import { List, ListItem, WithStyles, withStyles } from "@material-ui/core";
import cx from "classnames";
import * as React from "react";

import footerStyle from "../../assets/jss/material-dashboard-pro-react/components/footerStyle";

interface IProps extends WithStyles<typeof footerStyle> {
  fluid?: boolean;
  white?: boolean;
}

function Footer({ ...props }: IProps) {
  const { classes, fluid, white } = props;
  const container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: !!fluid,
    [classes.whiteColor]: white
  });
  const block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white
  });

  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://glansje.nl"
                target="_blank"
                title="Ga naar: Glansje.nl"
                className={block}
                rel="noopener noreferrer"
              >
                glansje.nl
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://glansje.nl/over-glansje"
                target="_blank"
                title="Ga naar: Over Glansje"
                className={block}
                rel="noopener noreferrer"
              >
                Over Glansje
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://shop.dentalunion.nl/glansje.html"
                target="_blank"
                title="Ga naar: Bestellen"
                className={block}
                rel="noopener noreferrer"
              >
                Bestellen
              </a>
            </ListItem>
          </List>
        </div>
        <p className={classes.right}>
          Glansje dashboard v 2.0.1-beta &copy; {new Date().getFullYear()}{" "}
          Glansje B.V.
        </p>
      </div>
    </footer>
  );
}

export default withStyles(footerStyle)(Footer);
