import { ActionCreator, Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import { IProfile } from "../../../domain/models/profiles";
import { IProfileMappingResponse } from "../../../domain/responses/profiles/ProfileMappingResponse";
import request, { setFilter } from "../../../util/request";

export const PROFILES_SEARCH_REQUEST = "@@profiles/PROFILES_SEARCH_REQUEST";
export const PROFILES_SEARCH_SUCCESS = "@@profiles/PROFILES_SEARCH_SUCCESS";
export const PROFILES_SEARCH_FAILURE = "@@profiles/PROFILES_SEARCH_FAILURE";

export interface IProfilesSearchRequest extends Action {
  type: typeof PROFILES_SEARCH_REQUEST;
}

export interface IProfilesSearchSuccess extends Action {
  type: typeof PROFILES_SEARCH_SUCCESS;
  payload: {
    profiles: IProfile[];
  };
}

export interface IProfilesSearchFailure extends Action {
  type: typeof PROFILES_SEARCH_FAILURE;
}

export type ProfilesSearchActions =
  | IProfilesSearchRequest
  | IProfilesSearchSuccess
  | IProfilesSearchFailure;

export const searchProfiles: ActionCreator<
  ThunkAction<void, IRootReducer, void, ProfilesSearchActions>
> = (search: string) => async (dispatch, getState) => {
  dispatch(requestSearchProfiles());
  try {
    const res = await request(dispatch, getState).get(
      `/GlansjeUsers/me/profileMappings${setFilter({
        where: {
          reference: { like: `.*${search}.*`, options: "i" }
        }, ///.*search.*/ } },
        include: ["profileUser"],
        limit: 10
      })}`
    );
    const profileRes = res.body as IProfileMappingResponse[];
    const profiles: IProfile[] = profileRes.map(
      (profile): IProfile => {
        return {
          created: new Date(profile.profileUser.created),
          modified: new Date(profile.profileUser.modified),
          id: profile.profileUserId,
          reference: profile.reference,
          resetProfile: profile.profileUser.resetProfile,
          shortId: profile.profileUser.shortId,
          username: profile.profileUser.username.substr(32),
          score: profile.profileUser.Profile.Score
        };
      }
    );
    dispatch(successSearchProfiles(profiles));
  } catch (err) {
    dispatch(failureSearchProfiles());
  }
};

const requestSearchProfiles: ActionCreator<IProfilesSearchRequest> = () => ({
  type: PROFILES_SEARCH_REQUEST
});

const successSearchProfiles: ActionCreator<IProfilesSearchSuccess> = (
  profiles: IProfile[]
) => ({
  type: PROFILES_SEARCH_SUCCESS,
  payload: {
    profiles
  }
});

const failureSearchProfiles: ActionCreator<IProfilesSearchFailure> = () => ({
  type: PROFILES_SEARCH_FAILURE
});
