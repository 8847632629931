import { Action } from "redux";

export const SNACK_ADD_ACTION = '@@snacks/ADD';
export const SNACK_REMOVE_ACTION = '@@snacks/REMOVE';

export interface ISnack {
    message: string;
    type: 'danger' | 'success' | 'warning';
}

export interface ISnackAddAction extends Action {
    type: typeof SNACK_ADD_ACTION,
    payload: ISnack
}

export interface ISnackRemoveAction extends Action {
    type: typeof SNACK_REMOVE_ACTION,
    payload: number
}

export type SnacksActions = ISnackAddAction | ISnackRemoveAction;

export const addSnack = (message: string, type: 'danger' | 'success' | 'warning' = 'danger') => ({
    type: SNACK_ADD_ACTION,
    payload: {
        message,
        type
    }
});

export const removeSnack = (index: number) => ({
    type: SNACK_REMOVE_ACTION,
    payload: index
});