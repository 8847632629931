import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import TrashIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { cardTitle } from "../../assets/jss/material-dashboard-pro-react";
import CardIcon from "../../components/Card/CardIcon";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import Heading from "../../components/Heading/Heading";
import Table from "../../components/Table";
import { IRow } from "../../components/Table/Row";
import Widget from "../../components/Widget";
import { IDashboardUser } from "../../domain/models/user";
import { IRootReducer } from "../../store";
import {
  DashboardUsersCountActions,
  DashboardUsersFetchActions,
  fetch,
  fetchCount
} from "../../store/dashboardUsers/actions";

const style = createStyles({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    textAlign: "left"
  }
});

interface IState {
  users: IDashboardUser[];
  totalUsers: number;
  isFetching: boolean;
  currentUserId: string | null;
}

interface IDispatch {
  fetchUsers: (page: number) => void;
  countUsers: () => void;
}

interface IOwnState {
  page: number;
}

export type MyType = IState & IDispatch;

export class UsersOverview extends React.Component<
  IState & IDispatch & WithStyles<typeof style>,
  IOwnState
> {
  public state = {
    page: 0
  };

  public componentWillMount() {
    this.props.countUsers();
    this.props.fetchUsers(this.state.page);
  }

  public render() {
    return (
      <>
        <Heading textAlign="center" title="Gebruikersoverzicht" />
        <GridContainer>
          <Widget
            allowedRoles={["admin"]}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            title={
              <>
                <CardIcon color="rose">
                  <PersonIcon />
                </CardIcon>
                <h4 className={this.props.classes.cardIconTitle}>Overzicht</h4>
              </>
            }
            icon={true}
            headerColor="rose"
          >
            <Table
              striped={true}
              hover={true}
              tableHeaderColor="orange"
              tableHead={[
                { header: "Naam" },
                { header: "E-mail" },
                { header: "Rollen" },
                { header: "Acties" }
              ]}
              tableData={this.convertUsersToTableData()}
              paginate={true}
              page={this.state.page}
              totalRows={this.props.totalUsers}
              totalCols={4}
              onChangePage={this.handleChangePage}
              isFetching={this.props.isFetching}
            />
          </Widget>
        </GridContainer>
      </>
    );
  }

  private handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    this.setState({ page });
    this.props.fetchUsers(page);
  };

  private editUser = (id: string) => () => {
    alert("edit user " + id);
  };

  private deleteUser = (id: string) => () => {
    alert("Delete user " + id);
  };

  private convertUsersToTableData = (): IRow[] => {
    return this.props.users.map((user, key) => {
      return {
        key,
        rowIndex: key,
        cellData: [
          { content: user.name },
          { content: user.email },
          {
            content:
              user.roles &&
              user.roles.map((role, roleIdx) => {
                return <strong key={roleIdx}>{role.name}</strong>;
              })
          },
          {
            content: (
              <>
                <Button
                  color="primary"
                  justIcon={true}
                  onClick={this.editUser(user.id)}
                >
                  <EditIcon />
                </Button>
                <Button
                  disabled={this.props.currentUserId === user.id}
                  color="danger"
                  justIcon={true}
                  onClick={this.deleteUser(user.id)}
                >
                  <TrashIcon />
                </Button>
              </>
            )
          }
        ]
      };
    });
  };
}

const mapStateToProps = (state: IRootReducer): IState => ({
  users: state.dashboardUsers.users,
  totalUsers: state.dashboardUsers.count,
  isFetching: state.dashboardUsers.isFetching,
  currentUserId: state.user.user && state.user.user.id
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    IRootReducer,
    void,
    DashboardUsersFetchActions | DashboardUsersCountActions
  >
): IDispatch => ({
  fetchUsers: (page: number) => dispatch(fetch(page)),
  countUsers: () => dispatch(fetchCount())
});

export const StyledUsersOverview = withStyles(style)(UsersOverview);

export default connect<IState, IDispatch, {}, IRootReducer>(
  mapStateToProps,
  mapDispatchToProps
)(StyledUsersOverview);
