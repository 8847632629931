import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import { IDashboardUserInputData } from "../../../domain/requests/DashboarUserInputData";
import request from "../../../util/request";

/// Constants
export const DASHBOARD_USERS_UPDATE_REQUEST = "@@dashboardUsers/UPDATE_REQUEST";
export const DASHBOARD_USERS_UPDATE_SUCCESS = "@@dashboardUsers/UPDATE_SUCCESS";
export const DASHBOARD_USERS_UPDATE_FAILURE = "@@dashboardUsers/UPDATE_FAILURE";

/// Action Declarations
export interface IDashboardUsersUpdateRequest extends Action {
  type: typeof DASHBOARD_USERS_UPDATE_REQUEST;
}

export interface IDashboardUsersUpdateSuccess extends Action {
  type: typeof DASHBOARD_USERS_UPDATE_SUCCESS;
}

export interface IDashboardUsersUpdateFailure extends Action {
  type: typeof DASHBOARD_USERS_UPDATE_FAILURE;
}

export type DashboarUsersUpdateActions =
  | IDashboardUsersUpdateRequest
  | IDashboardUsersUpdateSuccess
  | IDashboardUsersUpdateFailure;

/// Action Definitions
export const update: ActionCreator<
  ThunkAction<void, IRootReducer, void, DashboarUsersUpdateActions>
> = (id: string, user: IDashboardUserInputData) => async (
  dispatch,
  getState
) => {
  dispatch(updateRequest());

  try {
    await request(dispatch, getState)
      .patch(`/GlansjeUsers/${id}`)
      .send(user);
    dispatch(updateSuccess());
  } catch (error) {
    dispatch(updateFailure());
  }
};

export const updateRequest: ActionCreator<
  IDashboardUsersUpdateRequest
> = () => ({
  type: DASHBOARD_USERS_UPDATE_REQUEST
});

export const updateSuccess: ActionCreator<
  IDashboardUsersUpdateSuccess
> = () => ({
  type: DASHBOARD_USERS_UPDATE_SUCCESS
});

export const updateFailure: ActionCreator<
  IDashboardUsersUpdateFailure
> = () => ({
  type: DASHBOARD_USERS_UPDATE_FAILURE
});
