// import { Collapse } from "@material-ui/core";
import { InjectedFormikProps } from "formik";
import * as React from "react";
import * as Yup from "yup";
import CustomInput from "../CustomInput/CustomInput";
import CustomSelect from "../CustomInput/CustomSelect";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Danger from "../Typography/Danger";
import Success from "../Typography/Success";
import Warning from "../Typography/Warning";

export interface IFormProps {
  ri1?: number;
  ri2?: number;
  ri3?: number;
  ri4?: number;

  ci1?: number;
  ci2?: number;
  ci3?: number;
  ci4?: number;
  ci5?: number;
  ci6?: number;
  message?: string;
  isUpdating?: boolean;
  cancel: () => void;
}

export interface IFormValues {
  ri1?: string;
  ri2?: string;
  ri3?: string;
  ri4?: string;

  ci1?: string;
  ci2?: string;
  ci3?: string;
  ci4?: string;
  ci5?: string;
  ci6?: string;

  message?: string;
}

export const validationSchema = Yup.object().shape({
  ri1: Yup.number()
    .min(1, "Minimum waarde is 1")
    .max(2, "Maximum waarde is 2")
    .required("Dit veld is verplicht."),
  ri2: Yup.number()
    .min(1, "Minimum waarde is 1")
    .max(2, "Maximum waarde is 2")
    .required("Dit veld is verplicht."),
  ri3: Yup.number()
    .min(1, "Minimum waarde is 1")
    .max(2, "Maximum waarde is 2")
    .required("Dit veld is verplicht."),
  ri4: Yup.number()
    .min(1, "Minimum waarde is 1")
    .max(2, "Maximum waarde is 2")
    .required("Dit veld is verplicht."),
  ci1: Yup.number()
    .min(0, "Minimum waarde is 0")
    .max(4, "Maximum waarde is 4")
    .required("Dit veld is verplicht."),
  ci2: Yup.number()
    .min(0, "Minimum waarde is 0")
    .max(4, "Maximum waarde is 4")
    .required("Dit veld is verplicht."),
  ci3: Yup.number()
    .min(0, "Minimum waarde is 0")
    .max(4, "Maximum waarde is 4")
    .required("Dit veld is verplicht."),
  ci4: Yup.number()
    .min(0, "Minimum waarde is 0")
    .max(4, "Maximum waarde is 4")
    .required("Dit veld is verplicht."),
  ci5: Yup.number()
    .min(0, "Minimum waarde is 0")
    .max(4, "Maximum waarde is 4")
    .required("Dit veld is verplicht."),
  ci6: Yup.number()
    .min(0, "Minimum waarde is 0")
    .max(4, "Maximum waarde is 4")
    .required("Dit veld is verplicht."),
  message: Yup.string()
    .max(255, "Het maximum aantal tekens is 255")
    .required("Dit veld is verplicht.")
});

interface IStateInternal {
  calculatedInterval: number | null;
  calculatedCariesIndex: number | null;
}

export class AddRiskScoreForm extends React.Component<
  InjectedFormikProps<IFormProps, IFormValues>,
  IStateInternal
  > {
  public state = {
    calculatedInterval: null,
    calculatedCariesIndex: null
  };

  public render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <h4>NOCTP-score</h4>
          <CustomSelect
            formControlProps={{
              fullWidth: true,
              required: true
            }}
            id="ri1"
            labelText="Medewerking ouders/kind"
            success={this.props.touched.ri1 && !this.props.errors.ri1}
            error={this.props.touched.ri1 && !!this.props.errors.ri1}
            helpText={this.props.touched.ri1 && this.props.errors.ri1}
            options={[
              { value: 1, content: "Goed" },
              { value: 2, content: "Onvoldoende" }
            ]}
            selectProps={{
              native: true,
              autoWidth: true,
              multiple: false,
              onChange: this.handleChange,
              onBlur: this.props.handleBlur
            }}
          />
          <CustomSelect
            id="ri2"
            labelText="Actieve cariës"
            success={this.props.touched.ri2 && !this.props.errors.ri2}
            error={this.props.touched.ri2 && !!this.props.errors.ri2}
            helpText={this.props.touched.ri2 && this.props.errors.ri2}
            formControlProps={{
              fullWidth: true,
              required: true
            }}
            options={[
              { value: 1, content: "Nee" },
              { value: 2, content: "Ja" }
            ]}
            selectProps={{
              native: true,
              autoWidth: true,
              multiple: false,
              onChange: this.handleChange,
              onBlur: this.props.handleBlur
            }}
          />
          <CustomSelect
            id="ri3"
            labelText="Zijn er elementen in doorbraak?"
            success={this.props.touched.ri3 && !this.props.errors.ri3}
            error={this.props.touched.ri3 && !!this.props.errors.ri3}
            helpText={this.props.touched.ri3 && this.props.errors.ri3}
            formControlProps={{
              fullWidth: true,
              required: true
            }}
            options={[
              { value: 1, content: "Nee" },
              { value: 2, content: "Ja" }
            ]}
            selectProps={{
              native: true,
              autoWidth: true,
              multiple: false,
              onChange: this.handleChange,
              onBlur: this.props.handleBlur
            }}
          />
          <CustomSelect
            id="ri4"
            success={this.props.touched.ri4 && !this.props.errors.ri4}
            error={this.props.touched.ri4 && !!this.props.errors.ri4}
            helpText={this.props.touched.ri4 && this.props.errors.ri4}
            formControlProps={{
              fullWidth: true,
              required: true
            }}
            labelText="Cariës op occlusale vlak van in doorbraak zijnde element?"
            options={[
              { value: 1, content: "Nee" },
              { value: 2, content: "Ja" }
            ]}
            selectProps={{
              native: true,
              autoWidth: true,
              multiple: false,
              onChange: this.handleChange,
              onBlur: this.props.handleBlur
            }}
          />
          {this.renderAdvice(this.state.calculatedInterval)}
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <h4>Cariësindex: {this.state.calculatedCariesIndex}</h4>
          <CustomSelect
            id="ci1"
            labelText="Linksboven"
            success={this.props.touched.ci1 && !this.props.errors.ci1}
            error={this.props.touched.ci1 && !!this.props.errors.ci1}
            helpText={this.props.touched.ci1 && this.props.errors.ci1}
            formControlProps={{
              fullWidth: true,
              required: true
            }}
            options={[
              { value: 0, content: "Cariësvrij of adequaat gerestaureerd." },
              {
                value: 1,
                content: "Niet-actieve cariës of besloten niet te restaureren."
              },
              { value: 2, content: "Actieve niet-gecaviteerde laesies." },
              { value: 3, content: "Gecaviteerde laesies." },
              {
                value: 4,
                content: "Actieve, niet-gecaviteerde en gecaviteerde laesies."
              }
            ]}
            selectProps={{
              native: true,
              autoWidth: true,
              multiple: false,
              onChange: this.handleChange,
              onBlur: this.props.handleBlur
            }}
          />
          <CustomSelect
            id="ci2"
            labelText="Voor-boven"
            success={this.props.touched.ci2 && !this.props.errors.ci2}
            error={this.props.touched.ci2 && !!this.props.errors.ci2}
            helpText={this.props.touched.ci2 && this.props.errors.ci2}
            formControlProps={{
              fullWidth: true,
              required: true
            }}
            options={[
              { value: 0, content: "Cariësvrij of adequaat gerestaureerd." },
              {
                value: 1,
                content: "Niet-actieve cariës of besloten niet te restaureren."
              },
              { value: 2, content: "Actieve niet-gecaviteerde laesies." },
              { value: 3, content: "Gecaviteerde laesies." },
              {
                value: 4,
                content: "Actieve, niet-gecaviteerde en gecaviteerde laesies."
              }
            ]}
            selectProps={{
              native: true,
              autoWidth: true,
              multiple: false,
              onChange: this.handleChange,
              onBlur: this.props.handleBlur
            }}
          />
          <CustomSelect
            id="ci3"
            labelText="Rechtsboven"
            success={this.props.touched.ci3 && !this.props.errors.ci3}
            error={this.props.touched.ci3 && !!this.props.errors.ci3}
            helpText={this.props.touched.ci3 && this.props.errors.ci3}
            formControlProps={{
              fullWidth: true,
              required: true
            }}
            options={[
              { value: 0, content: "Cariësvrij of adequaat gerestaureerd." },
              {
                value: 1,
                content: "Niet-actieve cariës of besloten niet te restaureren."
              },
              { value: 2, content: "Actieve niet-gecaviteerde laesies." },
              { value: 3, content: "Gecaviteerde laesies." },
              {
                value: 4,
                content: "Actieve, niet-gecaviteerde en gecaviteerde laesies."
              }
            ]}
            selectProps={{
              native: true,
              autoWidth: true,
              multiple: false,
              onChange: this.handleChange,
              onBlur: this.props.handleBlur
            }}
          />
          <CustomSelect
            id="ci4"
            labelText="Linksonder"
            success={this.props.touched.ci4 && !this.props.errors.ci4}
            error={this.props.touched.ci4 && !!this.props.errors.ci4}
            helpText={this.props.touched.ci4 && this.props.errors.ci4}
            formControlProps={{
              fullWidth: true,
              required: true
            }}
            options={[
              { value: 0, content: "Cariësvrij of adequaat gerestaureerd." },
              {
                value: 1,
                content: "Niet-actieve cariës of besloten niet te restaureren."
              },
              { value: 2, content: "Actieve niet-gecaviteerde laesies." },
              { value: 3, content: "Gecaviteerde laesies." },
              {
                value: 4,
                content: "Actieve, niet-gecaviteerde en gecaviteerde laesies."
              }
            ]}
            selectProps={{
              native: true,
              autoWidth: true,
              multiple: false,
              onChange: this.handleChange,
              onBlur: this.props.handleBlur
            }}
          />
          <CustomSelect
            id="ci5"
            labelText="Voor-onder"
            success={this.props.touched.ci5 && !this.props.errors.ci5}
            error={this.props.touched.ci5 && !!this.props.errors.ci5}
            helpText={this.props.touched.ci5 && this.props.errors.ci5}
            formControlProps={{
              fullWidth: true,
              required: true
            }}
            options={[
              { value: 0, content: "Cariësvrij of adequaat gerestaureerd." },
              {
                value: 1,
                content: "Niet-actieve cariës of besloten niet te restaureren."
              },
              { value: 2, content: "Actieve niet-gecaviteerde laesies." },
              { value: 3, content: "Gecaviteerde laesies." },
              {
                value: 4,
                content: "Actieve, niet-gecaviteerde en gecaviteerde laesies."
              }
            ]}
            selectProps={{
              native: true,
              autoWidth: true,
              multiple: false,
              onChange: this.handleChange,
              onBlur: this.props.handleBlur
            }}
          />
          <CustomSelect
            id="ci6"
            labelText="Rechtsonder"
            success={this.props.touched.ci6 && !this.props.errors.ci6}
            error={this.props.touched.ci6 && !!this.props.errors.ci6}
            helpText={this.props.touched.ci6 && this.props.errors.ci6}
            formControlProps={{
              fullWidth: true,
              required: true
            }}
            options={[
              { value: 0, content: "Cariësvrij of adequaat gerestaureerd." },
              {
                value: 1,
                content: "Niet-actieve cariës of besloten niet te restaureren."
              },
              { value: 2, content: "Actieve niet-gecaviteerde laesies." },
              { value: 3, content: "Gecaviteerde laesies." },
              {
                value: 4,
                content: "Actieve, niet-gecaviteerde en gecaviteerde laesies."
              }
            ]}
            selectProps={{
              native: true,
              autoWidth: true,
              multiple: false,
              onChange: this.handleChange,
              onBlur: this.props.handleBlur
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <CustomInput
            labelText="Bericht voor het kind"
            formControlProps={{
              required: true,
              fullWidth: true
            }}
            id="message"
            success={this.props.touched.message && !this.props.errors.message}
            error={this.props.touched.message && !!this.props.errors.message}
            helpText={this.props.touched.message && this.props.errors.message}
            inputProps={{
              fullWidth: true,
              onChange: this.handleChange,
              onBlur: this.props.handleBlur
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }

  private renderAdvice(score: number | null) {
    if (score === null) {
      return null;
    }
    if (score === 5) {
      return (
        <Warning>
          <h5>Plan een nieuw preventief bezoek na 4 maanden.</h5>
        </Warning>
      );
    }
    if (score === 6) {
      return (
        <Warning>
          <h5>Plan een nieuw preventief bezoek na 3 maanden.</h5>
        </Warning>
      );
    }
    if (score === 7) {
      return (
        <Danger>
          <h5>Plan een nieuw preventief bezoek na 2 maanden.</h5>
        </Danger>
      );
    }
    if (score === 8) {
      return (
        <Danger>
          <h5>Plan een nieuw preventief bezoek na 1 maand.</h5>
        </Danger>
      );
    }
    return (
      <Success>
        <h5>Plan een nieuw preventief bezoek na 6-12 maanden.</h5>
      </Success>
    );
  }

  private handleChange = async (e: any) => {
    await this.props.handleChange(e);

    const { values } = this.props;
    if (values.ri1 && values.ri2 && values.ri3 && values.ri4) {
      this.setState({
        calculatedInterval:
          parseInt(values.ri1, 10) +
          parseInt(values.ri2, 10) +
          parseInt(values.ri3, 10) +
          parseInt(values.ri4, 10)
      });
    }

    if (
      values.ci1 !== undefined &&
      values.ci2 !== undefined &&
      values.ci3 !== undefined &&
      values.ci4 !== undefined &&
      values.ci5 !== undefined &&
      values.ci6 !== undefined
    ) {
      this.setState({
        calculatedCariesIndex: Math.round(
          (parseInt(values.ci1, 10) +
            parseInt(values.ci2, 10) +
            parseInt(values.ci3, 10) +
            parseInt(values.ci4, 10) +
            parseInt(values.ci5, 10) +
            parseInt(values.ci6, 10)) /
          6
        )
      });
    }
  };
}

export default AddRiskScoreForm;
