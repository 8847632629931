import { TableCell, TableRow, WithStyles, withStyles } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";

import tableStyle from "../../assets/jss/material-dashboard-pro-react/components/tableStyle";
import { TableHeaderColor } from "./Head";

export interface ICell {
  content: React.ReactNode;
  color?: TableHeaderColor;
  customClass?: string;
}

export interface IRow {
  cellData: ICell[];
  hover?: boolean;
  striped?: boolean;
  color?: TableHeaderColor;
  rowIndex: number;
}

const DataRow = ({ ...props }: IRow & WithStyles<typeof tableStyle>) => {
  const { classes, hover, color, striped, rowIndex, cellData } = props;
  const rowClasses = classNames({
    [classes.tableRowHover]: hover,
    [classes.successRow]: color === "success",
    [classes.warningRow]: color === "warning",
    [classes.infoRow]: color === "info",
    [classes.dangerRow]: color === "danger",
    [classes.tableStripedRow]: striped && rowIndex % 2 === 0
  });
  return (
    <TableRow hover={hover} className={`${classes.tableRow} ${rowClasses}`}>
      {cellData.map((cell, idx) => (
        <TableCell
          className={`${classes.tableCell} ${cell.color &&
            classes[cell.color]} ${cell.customClass}`}
          key={idx}
        >
          {cell.content}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default withStyles(tableStyle)(DataRow);
