import jsPDF from "jspdf-yworks";
import moment from "moment";
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as svg2pdf from "svg2pdf.js";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryLabel,
  VictoryLegend,
  VictoryStack,
  VictoryTheme,
  VictoryTooltip
} from "victory";
import {
  orangeColor,
  primaryColor,
  roseColor,
  successColor
} from "../../assets/jss/material-dashboard-pro-react";
import { IProfile, IRiskScore } from "../../domain/models/profiles";

interface IProps {
  labels: string[];
  riskScores: IRiskScore[];
  selectedProfile: IProfile;
}

interface IRiRecord {
  date: string;
  score: number;
  label: string;
}

class RiskScoreChart extends React.Component<IProps> {
  private chart = React.createRef<VictoryContainer>();

  public render() {
    const { riskScores, labels } = this.props;
    if (riskScores.length === 0) {
      return <p>Geen resultaten gevonden</p>;
    }

    const data = this.formatRiskIndexData();

    return (
      <VictoryChart
        containerComponent={<VictoryContainer ref={this.chart} />}
        domainPadding={40}
        padding={{
          top: 85,
          bottom: 40,
          left: 35,
          right: 25
        }}
        theme={VictoryTheme.material}
        width={800}
        height={400}
        domain={{ y: [0, 8] }}
      >
        <VictoryAxis
          tickValues={labels}
          tickLabelComponent={<VictoryLabel angle={-30} />}
        />
        <VictoryAxis dependentAxis={true} />
        <VictoryLegend
          title="Legenda"
          titleComponent={<h4>Legenda</h4>}
          centerTitle={false}
          gutter={20}
          padding={{ left: 25, right: 25 }}
          style={{
            labels: { fontSize: 14 },
          }}
          itemsPerRow={2}
          colorScale={[
            primaryColor[0],
            orangeColor[0],
            successColor[0],
            roseColor[0]
          ]}
          data={[
            { name: "Medewerking ouders/kind" },
            { name: "Actieve cariës" },
            { name: "Zijn er elementen in doorbraak?" },
            {
              name:
                "Cariës op occlusale vlak van in doorbraak zijnde blijvend element?"
            }
          ]}
        />
        <VictoryStack
          colorScale={[
            primaryColor[0],
            orangeColor[0],
            successColor[0],
            roseColor[0]
          ]}
        >
          <VictoryBar
            data={data.ri1}
            x="date"
            y="score"
            labelComponent={<VictoryTooltip />}
          />
          <VictoryBar
            data={data.ri2}
            x="date"
            y="score"
            labelComponent={<VictoryTooltip />}
          />
          <VictoryBar
            data={data.ri3}
            x="date"
            y="score"
            labelComponent={<VictoryTooltip />}
          />
          <VictoryBar
            data={data.ri4}
            x="date"
            y="score"
            labelComponent={<VictoryTooltip />}
          />
        </VictoryStack>
      </VictoryChart>
    );
  }

  public renderPdf = () => {
    if (this.chart.current) {
      const chartNode = ReactDOM.findDOMNode(this.chart.current);
      if (chartNode && chartNode.firstChild) {
        const pdf = new jsPDF("l", "pt", "a4");
        svg2pdf(chartNode.firstChild, pdf, {
          xOffset: 0,
          yOffset: 0,
          scale: 5
        });

        pdf.save(
          `NOCTP-Score_${moment().format("DD-MM-YYYY")}_${
          this.props.selectedProfile.reference
          }.pdf`
        );
      }
    }
  };
  private formatRiskIndexData = () => {
    const { riskScores } = this.props;
    const ri: {
      ri1: IRiRecord[];
      ri2: IRiRecord[];
      ri3: IRiRecord[];
      ri4: IRiRecord[];
    } = {
      ri1: [],
      ri2: [],
      ri3: [],
      ri4: []
    };

    riskScores.forEach(element => {
      const date = moment(element.created).format("DD-MM-YYYY");
      if (ri.ri1.length > 0 && ri.ri1[ri.ri1.length - 1].date === date) {
        ri.ri1.splice(ri.ri1.length - 1, 1);
        ri.ri2.splice(ri.ri2.length - 1, 1);
        ri.ri3.splice(ri.ri3.length - 1, 1);
        ri.ri4.splice(ri.ri4.length - 1, 1);
      }
      ri.ri1.push({
        date,
        score: element.ri1,
        label: "Medewerking ouders/kind"
      });
      ri.ri2.push({
        date,
        score: element.ri2,
        label: "Actieve cariës"
      });
      ri.ri3.push({
        date,
        score: element.ri3,
        label: "Zijn er elementen in doorbraak"
      });
      ri.ri4.push({
        date,
        score: element.ri4,
        label:
          "Cariës op occlusale vlak van in doorbraak zijnde blijvend element?"
      });
    });

    return ri;
  };
}

export default RiskScoreChart;
