import { IDashboardUser } from "../../domain/models/user";
import {
  DASHBOARD_USERS_COUNT_FAILURE,
  DASHBOARD_USERS_COUNT_REQUEST,
  DASHBOARD_USERS_COUNT_SUCCESS,
  DASHBOARD_USERS_CREATE_FAILURE,
  DASHBOARD_USERS_CREATE_REQUEST,
  DASHBOARD_USERS_CREATE_SUCCESS,
  DASHBOARD_USERS_DELETE_FAILURE,
  DASHBOARD_USERS_DELETE_REQUEST,
  DASHBOARD_USERS_DELETE_SUCCESS,
  DASHBOARD_USERS_FETCH_FAILURE,
  DASHBOARD_USERS_FETCH_REQUEST,
  DASHBOARD_USERS_FETCH_SUCCESS,
  DASHBOARD_USERS_UPDATE_FAILURE,
  DASHBOARD_USERS_UPDATE_REQUEST,
  DASHBOARD_USERS_UPDATE_SUCCESS,
  DashboardUsersActions
} from "./actions";

export interface IDashboardUsersState {
  readonly users: IDashboardUser[];
  readonly isFetching: boolean;
  readonly createSuccess: boolean;
  readonly updateSuccess: boolean;
  readonly hasError: boolean;
  readonly isUpdating: boolean;
  readonly hasUpdateError: boolean;
  readonly isDeleting: boolean;
  readonly hasDeleteError: boolean;
  readonly count: number;
  readonly isCounting: boolean;
}

export const initialState: IDashboardUsersState = {
  users: [],
  isFetching: false,
  createSuccess: false,
  updateSuccess: false,
  hasError: false,
  isUpdating: false,
  hasUpdateError: false,
  isDeleting: false,
  hasDeleteError: false,
  count: 0,
  isCounting: false
};

const dashboardUsersReducer = (
  state: IDashboardUsersState = initialState,
  action: DashboardUsersActions
): IDashboardUsersState => {
  switch (action.type) {
    case DASHBOARD_USERS_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        createSuccess: false,
        updateSuccess: false
      };
    case DASHBOARD_USERS_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        users: action.payload.dashboardUsers
      };
    case DASHBOARD_USERS_FETCH_FAILURE:
      return { ...state, isFetching: false, hasError: true, users: [] };
    case DASHBOARD_USERS_CREATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        hasError: false
      };
    case DASHBOARD_USERS_CREATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        hasError: false,
        createSuccess: true
      };
    case DASHBOARD_USERS_CREATE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        hasError: true,
        createSuccess: false
      };
    case DASHBOARD_USERS_UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        hasError: false,
        updateSuccess: false
      };
    case DASHBOARD_USERS_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        hasUpdateError: false,
        updateSuccess: true
      };
    case DASHBOARD_USERS_UPDATE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        hasUpdateError: true,
        updateSuccess: false
      };
    case DASHBOARD_USERS_DELETE_REQUEST:
      return { ...state, isDeleting: true, hasDeleteError: false };
    case DASHBOARD_USERS_DELETE_SUCCESS:
      return { ...state, isDeleting: false, hasDeleteError: false };
    case DASHBOARD_USERS_DELETE_FAILURE:
      return { ...state, isDeleting: false, hasDeleteError: true };
    case DASHBOARD_USERS_COUNT_REQUEST:
      return { ...state, isCounting: true };
    case DASHBOARD_USERS_COUNT_SUCCESS:
      return { ...state, isCounting: false, count: action.payload.count };
    case DASHBOARD_USERS_COUNT_FAILURE:
      return { ...state, isCounting: false, count: 0, hasError: true };
    default:
      return state;
  }
};

export default dashboardUsersReducer;
