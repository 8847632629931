import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory, createMemoryHistory } from "history";
import {
  AnyAction,
  applyMiddleware,
  compose,
  createStore,
  Store,
  StoreEnhancer
} from "redux";
import { PersistConfig, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import rootReducer, { IRootReducer } from ".";
import { initialState as dashboardUsersState } from "./dashboardUsers/reducer";
import { initialState as notificationState } from "./notifications/reducer";
import { initialState as profilesState } from "./profiles/reducer";
import { initialState as snacksState } from './snacks/reducer';
import { initialState as uiState } from "./ui/reducer";
import { initialState as userState } from "./user/reducer";

// Make typescript happy about redux devtools
interface IReduxDevWindow extends Window {
  __PRELOADED_STATE__: IRootReducer;
  __REDUX_DEVTOOLS_EXTENSION__(options: any): () => void;
}

declare var window: IReduxDevWindow;

export const isServer = !(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export default (url: string = "/") => {
  const history = isServer
    ? createMemoryHistory({
      initialEntries: [url]
    })
    : createBrowserHistory();

  const enhancers = [];

  if (process.env.NODE_ENV === "development" && !isServer) {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (typeof devToolsExtension === "function") {
      enhancers.push(devToolsExtension({ trace: true, traceDepth: 25 }));
    }
  }

  const middleware = [thunk, routerMiddleware(history)];
  const composedEnhancers: StoreEnhancer<
    Store<IRootReducer, AnyAction>
  > = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  const initialState: IRootReducer = !isServer
    ? window.__PRELOADED_STATE__
    : {
      ui: uiState,
      user: userState,
      notifications: notificationState,
      profiles: profilesState,
      dashboardUsers: dashboardUsersState,
      snacks: snacksState,
      router: {
        location: history.location,
        action: "PUSH"
      }
    };

  if (!isServer) {
    delete window.__PRELOADED_STATE__;
  }

  const persistConfig: PersistConfig = {
    key: "root",
    storage
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer(history));
  const store: Store<IRootReducer> = createStore(
    persistedReducer,
    initialState,
    composedEnhancers
  );

  return {
    store,
    history,
    persitor: persistStore(store)
  };
};
