import { InjectedFormikProps, withFormik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import * as Yup from "yup";
import Widget from ".";
import { IRootReducer } from "../../store";
import {
  linkProfile,
  LinkProfileActions
} from "../../store/profiles/actions/linkProfile";
import Button from "../CustomButtons/Button";
import { IFormProps, IFormValues } from "../forms/LinkProfileWidgetForm";
import LinkProfileWidgetForm from "../forms/LinkProfileWidgetForm";

interface IState {
  isLinking: boolean;
  hasError: boolean;
  errorMessage?: string;
}

interface IDispatch {
  // searchProfile: (shortCode: string) => void;
  linkProfile: (shortCode: string, clientReference: string) => void;
}

class NewProfile extends React.Component<
  InjectedFormikProps<IFormProps, IFormValues> & IState & IDispatch
> {
  public render() {
    const { isLinking, hasError, ...rest } = this.props;
    return (
      <Widget
        headerColor="success"
        allowedRoles={["admin", "dentist"]}
        xs={12}
        sm={6}
        md={4}
        lg={3}
        title="Koppel cliënt"
        footer={
          <>
            <Button
              color="danger"
              type="reset"
              disabled={!this.props.dirty || this.props.isLinking}
            >
              Opnieuw
            </Button>
            <Button
              color="success"
              type="button"
              disabled={
                !this.props.dirty ||
                this.props.isLinking ||
                this.props.hasError ||
                !!Object.keys(this.props.errors).length
              }
              onClick={this.props.handleSubmit as any}
            >
              Zoeken
            </Button>
          </>
        }
      >
        <LinkProfileWidgetForm {...rest} />
      </Widget>
    );
  }
}

const EnhancedNewProfile = withFormik<
  IFormProps & IState & IDispatch,
  IFormValues
>({
  mapPropsToValues: () => ({
    linkProfileWidgetShortCode: "",
    linkProfileWidgetClientReference: ""
  }),
  validationSchema: Yup.object().shape({
    linkProfileWidgetShortCode: Yup.string()
      .min(7, "De code moet minimaal 7 tekens bevatten")
      .max(14, "De code mag maximaal 14 tekens bevatten")
      .required("U moet een code opgeven"),
    linkProfileWidgetClientReference: Yup.string()
      .min(2, "De referentie is te kort")
      .max(255, "De referentie is te lang")
      .required("U moet een referentie opgeven.")
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.linkProfile(
      values.linkProfileWidgetShortCode,
      values.linkProfileWidgetClientReference
    );
    setSubmitting(false);
  }
})(NewProfile);

const mapStateToProps = (state: IRootReducer): IState => ({
  isLinking: state.profiles.isLinking,
  hasError: state.profiles.hasLinkingError
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootReducer, void, LinkProfileActions>
): IDispatch => ({
  linkProfile: (shortcode: string, clientReference: string) =>
    dispatch(linkProfile(shortcode, clientReference))
});

export default connect<IState, IDispatch, {}, IRootReducer>(
  mapStateToProps,
  mapDispatchToProps
)(EnhancedNewProfile);
