import { SvgIconProps } from "@material-ui/core/SvgIcon";
import DashboardIcon from "@material-ui/icons/Dashboard";
import UsersIcon from "@material-ui/icons/Person";
import ClientsIcon from "@material-ui/icons/SupervisorAccount";
import { ComponentType } from "react";
import ClientsOverview from "./views/ClientsOverview/ClientsOverview";
import Dashboard from "./views/Dashboard";
import EditProfile from "./views/EditProfile/EditProfile";
import LoginPage from "./views/Pages/LoginPage";
import UsersOverview from "./views/UsersOverview/UsersOverview";

type RoleName = "admin" | "dentist";

export interface IDashboardRoute {
  path: string;
  name: string;
  layout: string;
  mini?: string;
  icon?: ComponentType<SvgIconProps>;
  component: ComponentType<any>;
  routeType?: RouteType;
  collapse?: boolean;
  views?: IDashboardRoute[];
  redirect?: string;
  allowedRoles?: RoleName[];
  notInMenu?: boolean;
  color?: "white" | "red" | "orange" | "green" | "blue" | "purple" | "rose";
}

export enum RouteType {
  AUTHENTICATED,
  UNAUTHENTICATED
}

export const adminRoutes: IDashboardRoute[] = [
  {
    path: "/dashboard",
    name: "Dashboard",
    layout: "/admin",
    icon: DashboardIcon,
    component: Dashboard,
    routeType: RouteType.AUTHENTICATED,
    allowedRoles: ["admin", "dentist"]
  },
  {
    path: "/users",
    name: "Gebruikers",
    layout: "/admin",
    icon: UsersIcon,
    component: UsersOverview,
    routeType: RouteType.AUTHENTICATED,
    allowedRoles: ["admin"],
    color: "rose"
  },
  {
    path: "/client/edit/:id",
    layout: "/admin",
    component: EditProfile,
    allowedRoles: ["admin", "dentist"],
    routeType: RouteType.AUTHENTICATED,
    name: "Clientgegevens",
    notInMenu: true
  },
  {
    path: "/clients",
    layout: "/admin",
    name: "Cliënten",
    icon: ClientsIcon,
    component: ClientsOverview,
    routeType: RouteType.AUTHENTICATED,
    allowedRoles: ["admin", "dentist"],
    color: "green"
  }
];

export const pageRoutes: IDashboardRoute[] = [
  {
    path: "/login",
    name: "Login",
    mini: "L",
    component: LoginPage,
    layout: "/page",
    routeType: RouteType.UNAUTHENTICATED
  }
];

export default {
  adminRoutes,
  pageRoutes
};
