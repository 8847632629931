import { WithStyles, withStyles } from "@material-ui/core";
import { ReactNode } from "react";
import * as React from "react";

import typographyStyle from "../../assets/jss/material-dashboard-pro-react/components/typographyStyle";

interface IProps extends WithStyles<typeof typographyStyle> {
  children: JSX.Element | JSX.Element[] | ReactNode;
}

function Success({ ...props }: IProps) {
  const { classes, children } = props;

  return (
    <div className={`${classes.defaultFontStyle} ${classes.successText}`}>
      {children}
    </div>
  );
}

export default withStyles(typographyStyle)(Success);
