import * as React from "react";

import { WithStyles, withStyles } from "@material-ui/core";
import classNames from "classnames";
import { connect } from "react-redux";
import sidebarStyle from "../../assets/jss/material-dashboard-pro-react/components/sidebarStyle";
import { IRootReducer } from "../../store";

interface IProps extends WithStyles<typeof sidebarStyle> {
  logo: string;
  logoText: string;
  bgColor?: "white" | "black" | "blue";
  miniActive: boolean;
}

function SidebarBrand({ ...props }: IProps) {
  const { classes, bgColor, miniActive, logo, logoText } = props;

  const logoClasses = classNames({
    [classes.logo]: true,
    [classes.whiteAfter]: bgColor === "white"
  });

  const logoNormal = classNames({
    [classes.logoNormal]: true,
    [classes.logoNormalSidebarMini]: miniActive
  });

  return (
    <div className={logoClasses}>
      <a href="/admin/dashboard" className={classes.logoMini}>
        <img src={logo} alt={logoText} className={classes.img} />
      </a>
      <a href="/admin/dashboard" className={logoNormal}>
        {logoText}
      </a>
    </div>
  );
}

const mapStateToProps = (state: IRootReducer) => ({
  miniActive: state.ui.miniActive
});

export default connect(mapStateToProps)(withStyles(sidebarStyle)(SidebarBrand));
