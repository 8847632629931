import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IRootReducer } from "../..";
import { IDashboardUser } from "../../../domain/models/user";
import request, { setFilter } from "../../../util/request";

/* #region  Constants */
export const DASHBOARD_USERS_FETCH_REQUEST = "@@dashboardUsers/FETCH_REQUEST";
export const DASHBOARD_USERS_FETCH_SUCCESS = "@@dashboardUsers/FETCH_SUCCESS";
export const DASHBOARD_USERS_FETCH_FAILURE = "@@dashboardUsers/FETCH_FAILURE";
/* #endregion */

/* #region  Action Types */
export interface IDashboarUsersFetchRequest extends Action {
  type: typeof DASHBOARD_USERS_FETCH_REQUEST;
}

export interface IDashboardUsersFetchSuccess extends Action {
  type: typeof DASHBOARD_USERS_FETCH_SUCCESS;
  payload: {
    dashboardUsers: IDashboardUser[];
  };
}

export interface IDashboardUsersFetchFailure extends Action {
  type: typeof DASHBOARD_USERS_FETCH_FAILURE;
}

export type DashboardUsersFetchActions =
  | IDashboarUsersFetchRequest
  | IDashboardUsersFetchSuccess
  | IDashboardUsersFetchFailure;
/* #endregion */

/* #region  ActionCreators */
export const fetch: ActionCreator<
  ThunkAction<void, IRootReducer, void, DashboardUsersFetchActions>
> = (page: number = 0, pageSize = 10) => async (dispatch, getState) => {
  dispatch(fetchRequest());
  try {
    const skip = page * pageSize;
    const response = await request(dispatch, getState).get(
      `/GlansjeUsers${setFilter({ skip, limit: pageSize, include: "roles" })}`
    );
    const users: IDashboardUser[] = response.body.map(
      (dashUser: IDashboardUser) => {
        return {
          ...dashUser,
          created: new Date(dashUser.created),
          modified: new Date(dashUser.modified),
          roles: dashUser.roles!.map(role => {
            return {
              ...role,
              created: new Date(role.created),
              modified: new Date(role.modified)
            };
          })
        };
      }
    );
    dispatch(fetchSuccess(users));
  } catch (error) {
    dispatch(fetchFailure());
  }
};

export const fetchRequest: ActionCreator<IDashboarUsersFetchRequest> = () => ({
  type: DASHBOARD_USERS_FETCH_REQUEST
});

export const fetchSuccess: ActionCreator<IDashboardUsersFetchSuccess> = (
  dashboardUsers: IDashboardUser[]
) => ({
  type: DASHBOARD_USERS_FETCH_SUCCESS,
  payload: {
    dashboardUsers
  }
});

export const fetchFailure: ActionCreator<IDashboardUsersFetchFailure> = () => ({
  type: DASHBOARD_USERS_FETCH_FAILURE
});
/* #endregion */
