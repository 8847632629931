import { WithStyles, withStyles } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";

import typographyStyle from "../../assets/jss/material-dashboard-pro-react/components/typographyStyle";

interface IProps extends WithStyles<typeof typographyStyle> {
  count: number;
  color?:
    | "info"
    | "primary"
    | "success"
    | "warning"
    | "danger"
    | "rose"
    | "orange";
}

function WidgetCounter({ ...props }: IProps) {
  const { classes, count, color } = props;
  const widgetCounterClasses = classNames({
    [classes.infoText]: !color || color === "info",
    [classes.primaryText]: color === "primary",
    [classes.warningText]: color === "warning",
    [classes.dangerText]: color === "danger",
    [classes.roseText]: color === "rose",
    [classes.successText]: color === "success",
    [classes.orangeText]: color === "orange"
  });
  return (
    <div
      className={`${classes.counterFontStyle} ${widgetCounterClasses} ${
        classes.defaultHeaderMargins
      }`}
    >
      {count}
    </div>
  );
}

export default withStyles(typographyStyle)(WidgetCounter);
